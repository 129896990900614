import React from 'react';
import { NavBar } from '../../../components/navbar'
import { getCliente, getEstacionamento, getUsuario, isAdministrador } from '../../../config/auth'
import { ComboBoxCollection, Loading } from '../../../components/common/commons'
import firebase from '../../../config/firebase';
import Moment from 'moment';
import { EmptyContent } from '../../../components/common/commons'
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { exportAndDownloadXLS, queryString, reloadWindow, reloadWithAlert, setPathWindow, toastError, toastWarning } from '../../../shared/utils';
import { sendScreenView } from '../../../shared/analytics';
import { fetchFunction } from '../../../shared/function-request';
import { updateDoc } from '../../../store/collections/collectionBaseWorker';
import { Collection } from '../../../shared/constants';

const RPS_CREATED = "RPS_CREATED"
const NOTE_GENERATED = "NOTE_GENERATED"
const RPS_CANCELED = "RPS_CANCELED"
const NOTE_CANCELED = "NOTE_CANCELED"
const NOTE_GENERATED_ERROR = "NOTE_GENERATED_ERROR"

class NoteList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            isLoading: true,
            isLoadingGenerateNote: false,
            statusGenerateNote: '',
            estacionamentoId: getEstacionamento()?.id,
            currentStatus: '',
            days: 8,
            type: '',
            notes: [],
            noteSelected: null,
            noteSelectedDocument: '',
            tipoDocumento: 'CPF',
            noteSelectedEmail: '',
            isShowingStatus: false,
            isShowLog: false,
            isShowGenerateNote: false,
            isShowingLoadingProccess: false,
            isShowGenerateManual: false,
            isShowExecuteProcessing: false,
            isShowCancelNote: false,
            random: Math.random()
        }
    }

    handleChange = (event) => {
        setPathWindow('/nota-fiscal/listar?past-days=' + event.target.value)
        this.setState({ days: event.target.value }, () => {
            this.find()
        })
    };

    componentDidMount = () => {
        var days = queryString('past-days')
        if (days !== null && parseInt(days) <= 360) {
            this.setState({ days: parseInt(days) }, () => {
                this.find()
            })
        } else {
            this.find()
        }
        const from = queryString("from")
        if (from === "email") {
            sendScreenView("Nota fiscal", "Ver todas | Por e-mail")
        } else {
            sendScreenView("Nota fiscal", "Ver todas")
        }
    }

    currentStatusChange = (event) => {
        this.setState({ currentStatus: event.target.value }, () => {
            this.find()
        })
    };

    typeChange = (event) => {
        this.setState({ type: event.target.value }, () => {
            this.find()
        })
    };

    retry = () => {
        this.updateStatus('NEW')
    }

    showCancelNote = (noteSelected) => {
        this.setState({ noteSelected: noteSelected })
        this.setState({ isShowCancelNote: true })
    }

    showLog = (log) => {
        var pretty = JSON.stringify(log, undefined, 4);
        this.setState({ log: pretty })
        this.setState({ isShowLog: true })
    }

    showStatus = (noteSelected) => {
        this.setState({ noteSelected: noteSelected })
        this.setState({ isShowingStatus: true })
    }

    hideCancelNote = () => {
        this.setState({ isShowCancelNote: false })
    }

    showGenerateManual = (noteSelected) => {
        this.setState({ noteSelected: noteSelected })
        this.setState({ isShowGenerateManual: true })
    }

    hideGenerateManual = () => {
        this.setState({ isShowGenerateManual: false })
    }

    showGenerateNote = (noteSelected) => {
        this.setState({ noteSelected: noteSelected })
        this.setState({ noteSelectedEmail: noteSelected.noteGenerate.email })
        this.setState({ noteSelectedDocument: noteSelected.noteGenerate.document })
        this.setState({ tipoDocumento: noteSelected.noteGenerate.document.replaceAll(".").replaceAll("/").replaceAll("-").length === 11 ? "CPF" : "CNPJ" })
        this.setState({ isShowGenerateNote: true })
    }

    closeGenerateNote = () => {
        this.setState({ isShowGenerateNote: false })
    }

    requestNote = async () => {
        const snapshot = await firebase.firestore().collection("notes").doc(this.state.noteSelected.id).get()
        if (snapshot.exists) {
            const data = snapshot.data()
            if (data.currentStatus === NOTE_GENERATED) {
                reloadWithAlert('Esse RPS já esta com uma NFS-e processada')
            } else {
                this.setState({ isLoadingGenerateNote: true })
                this.setState({ statusGenerateNote: "Salvando dados" })
                data.status.push({
                    status: RPS_CREATED,
                    createAt: new Date(),
                    createBy: {
                        nome: isAdministrador() ? "Automação" : getUsuario().nome
                    },
                    message: "Dados do RPS atualizados",
                    log: {
                        message: "Dados anteriores",
                        email: data.noteGenerate.email,
                        documento: data.noteGenerate.document,
                        status: data.currentStatus
                    }
                })
                data.currentStatus = RPS_CREATED
                data.noteGenerate.email = this.state.noteSelectedEmail
                data.noteGenerate.document = this.state.noteSelectedDocument
                await updateDoc({ collection: Collection.NOTES, id: this.state.noteSelected.id, data: data })
                setTimeout(() => {
                    reloadWindow()
                }, 1000);
            }
        }
    }

    requestCancelNote = () => {
        if (this.state.noteSelected?.currentStatus === RPS_CREATED) {
            this.updateStatus(RPS_CANCELED, "RPS cancelado")
        } else {
            this.updateStatus(NOTE_CANCELED, "Nota cancelada")
        }
    }

    updateStatus = (status, message) => {
        this.state.noteSelected.status.push({
            status: status,
            createBy: {
                nome: isAdministrador() ? "Automação" : getUsuario().nome
            },
            createAt: new Date(),
            message: message
        })
        firebase.firestore().collection('notes').doc(this.state.noteSelected.id).set({
            currentStatus: status,
            status: this.state.noteSelected.status
        }, { merge: true }).then(() => {
            reloadWindow()
        }).catch(function (error) {
            toastError(error.message)
        });
    }

    confirmExecuteProccess = async () => {
        const body = {
            estacionamentoId: this.state.estacionamentoId
        }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body
        };
        this.setState({ isShowingLoadingProccess: true })
        const result = await fetchFunction("/notes/generateByPark", options)
        var delayInMilliseconds = 2000;
        setTimeout(function () {
            if (result.success) {
                reloadWithAlert("Solicitação gerada com sucesso!")
            } else {
                toastWarning("Tente novamente")
            }
        }, delayInMilliseconds);
    }

    hideStatus = () => {
        this.setState({ isShowingStatus: false })
    }

    find = () => {
        this.setState({ isLoading: true });
        this.setState({ notFound: false });
        var date = new Date();
        date.setDate(date.getDate() - this.state.days);
        var query = firebase.firestore().collection('notes')
        query = query.where('createAt', '>=', date).where('estacionamento.id', '==', this.state.estacionamentoId).orderBy("createAt", "desc")
        if (this.state.currentStatus !== '') {
            query = query.where('currentStatus', '==', this.state.currentStatus)
        }
        if (this.state.type !== '') {
            query = query.where('type', '==', this.state.type)
        }
        query.get().then(async (results) => {
            this.setState({ isLoading: false });
            if (results.docs.length === 0) {
                this.setState({ notFound: true });
                return
            }
            var notes = []
            results.docs.forEach(doc => {
                notes.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ notes: notes })
        }).catch(error => {
            this.setState({ isLoading: false });
            this.setState({ notFound: false });
        })
    }

    exportRows = () => {
        const rows = this.state.notes.map(e => {
            function convertStatus(currentStatus) {
                switch (currentStatus) {
                    case RPS_CREATED: return "RPS criado"
                    case NOTE_GENERATED: return "NFS-e gerada"
                    case NOTE_GENERATED_ERROR: return "Falha na emissão"
                    case NOTE_CANCELED: return "Nota cancelada"
                    case RPS_CANCELED: return "RPS cancelado"
                }
            }
            return {
                RPS: `${e.rpsSerie.serie}.${e.rpsSerie.numero}`,
                NUMERO_NOTA: e.noteReceived?.ChaveNFe?.NumeroNFe ?? "",
                CODIGO_VERIFICACAO: e.noteReceived?.ChaveNFe?.CodigoVerificacao ?? "",
                CRIADO_EM: Moment(e.createAt.toDate() ?? e.createAt).format("DD/MM/YYYY [às] HH:mm"),
                DOCUMENTO: e.noteGenerate.document.padStart(11, "0").toString(),
                EMAIL: e.noteGenerate.email,
                VALOR: e.valorNota.toFixed(2),
                STATUS: convertStatus(e.currentStatus),
                CAMINHO_NOTA: (e.noteReceived?.ChaveNFe?.NumeroNFe !== undefined ? ('https://nfe.prefeitura.sp.gov.br/contribuinte/notaprint.aspx?ccm=' + e.noteReceived.ChaveNFe.InscricaoPrestador + '&nf=' + e.noteReceived.ChaveNFe.NumeroNFe + '&cod=' + e.noteReceived.ChaveNFe.CodigoVerificacao) : "")
            }
        })
        exportAndDownloadXLS(rows, `NOTAS_EMITIDAS_ULTIMOS_${this.state.days}_DIAS`)
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Selecione o seu filtro
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <label>Selecionar o período</label>
                                    <select className="form-select" value={this.state.days} onChange={this.handleChange}>
                                        <option value={1}>Hoje</option>
                                        <option value={2}>Ontem</option>
                                        <option value={3}>Período de 2 dias</option>
                                        <option value={8}>Período de 7 dias</option>
                                        <option value={16}>Período de 15 dias</option>
                                        <option value={31}>Período de 30 dias</option>
                                        <option value={60}>Período de 3 meses</option>
                                        <option value={180}>Período de 6 meses</option>
                                        <option value={270}>Período de 9 meses</option>
                                        <option value={360}>Período de 12 meses</option>
                                    </select>
                                </div>
                                <div className='col-lg-3'>
                                    <label>Tipo</label>
                                    <select className="form-select" value={this.state.type} onChange={this.typeChange}>
                                        <option value=''>Todos</option>
                                        <option value='ticket'>Pagamento de ticket</option>
                                        <option value='monthly'>Pagamento de mensalista</option>
                                        <option value='customizado'>Gerado manualmente</option>
                                    </select>
                                </div>
                                <div className='col-lg-2'>
                                    <label>Status</label>
                                    <select className="form-select" value={this.state.currentStatusChange} onChange={this.currentStatusChange}>
                                        <option value=''>Todos</option>
                                        <option value={RPS_CREATED}>RPS Criado</option>
                                        <option value={RPS_CANCELED}>RPS cancelado</option>
                                        <option value={NOTE_GENERATED}>NFS-e gerada</option>
                                        <option value={NOTE_CANCELED}>Nota cancelada</option>
                                        <option value={NOTE_GENERATED_ERROR}>Falha na emissão da nota</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.isLoading ?
                            <div className="card mb-4">
                                <div className="card-body py-3">
                                    <EmptyContent text="Carregando informações..." />
                                </div>
                            </div> :
                            this.state.notFound ?
                                <div className="card mb-4">
                                    <div className="card-body py-3">
                                        <EmptyContent text="Nenhum RPS ou nota foi localizada com esse filtro!" />
                                    </div>
                                </div> :
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <div className='btn-group'>
                                            <button onClick={(e) => this.setState({ isShowExecuteProcessing: true })} className="btn btn-sm btn-danger">
                                                <i className="fas fa-gear mx-2" />
                                                Executar processo
                                            </button>
                                            <button onClick={this.exportRows} className="btn btn-sm btn-success">
                                                <i className="fas fa-table mx-2" />
                                                Exportar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>RPS</strong></td>
                                                            <td className="d-none d-sm-table-cell"><strong>Data da solicitação</strong></td>
                                                            <td><strong>Documento</strong></td>
                                                            <td className="d-none d-sm-table-cell"><strong>E-mail</strong></td>
                                                            <td><strong>Valor</strong></td>
                                                            <td><strong>Status atual</strong></td>
                                                            <td width='80' />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.notes.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{item.rpsSerie.serie}.{item.rpsSerie.numero}</td>
                                                                    <td className="d-none d-sm-table-cell">{Moment(item.createAt.toDate() ?? item.createAt).format("DD/MM/YYYY HH:mm")}</td>
                                                                    <td>{item.noteGenerate?.document}</td>
                                                                    <td className="d-none d-sm-table-cell">{(item.noteGenerate?.email !== null && item.noteGenerate.email !== '') ? item.noteGenerate.email : "Não informado"}</td>
                                                                    <td><NumberFormat value={item.valorNota} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                                    <td>
                                                                        {item.currentStatus === RPS_CREATED && <h6><span className="badge text-bg-warning btn-block">RPS criado</span> </h6>}
                                                                        {item.currentStatus === NOTE_GENERATED && <h6><span className="badge text-bg-success btn-block">NFS-e gerada</span></h6>}
                                                                        {item.currentStatus === NOTE_GENERATED_ERROR && <h6><span className="badge text-bg-danger btn-block">Falha na emissão</span></h6>}
                                                                        {item.currentStatus === NOTE_CANCELED && <h6><span className="badge text-bg-danger btn-block">Nota cancelada</span></h6>}
                                                                        {item.currentStatus === RPS_CANCELED && <h6><span className="badge text-bg-danger btn-block">RPS cancelado</span></h6>}
                                                                    </td>
                                                                    <td>
                                                                        <div className='btn-group'>
                                                                            {
                                                                                item.type === 'ticket' &&
                                                                                <a href={'/buscar/ticket?id=' + item.ticket?.id} target='_blank' rel="noopener noreferrer" className="btn btn-warning text-white">
                                                                                    <i className="fas fa-ticket-alt" />
                                                                                </a>
                                                                            }
                                                                            {
                                                                                item.type === 'mensalista' &&
                                                                                <a href={'/visualizar/mensalista/' + item.monthly.id} target='_blank' rel="noopener noreferrer" className="btn btn-primary">
                                                                                    <i className="fas fa-eye" />
                                                                                </a>
                                                                            }
                                                                            {
                                                                                item.type === 'customizado' &&
                                                                                <button onClick={(e) => this.showGenerateManual(item)} className="btn btn-info">
                                                                                    <i className="fa-solid fa-file-circle-exclamation" />
                                                                                </button>
                                                                            }
                                                                            <button type="button" onClick={(e) => { this.showStatus(item) }} className="btn btn-secondary">
                                                                                <i className="fas fa-bars" />
                                                                            </button>
                                                                            {
                                                                                (item.currentStatus === RPS_CREATED || item.currentStatus === NOTE_GENERATED_ERROR) &&
                                                                                <button type="button" onClick={(e) => { this.showGenerateNote(item) }} className="btn btn-secondary">
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            }
                                                                            {
                                                                                (item.currentStatus === NOTE_GENERATED || item.currentStatus === NOTE_CANCELED) &&
                                                                                <a href={'https://nfe.prefeitura.sp.gov.br/contribuinte/notaprint.aspx?ccm=' + item.noteReceived.ChaveNFe.InscricaoPrestador + '&nf=' + item.noteReceived.ChaveNFe.NumeroNFe + '&cod=' + item.noteReceived.ChaveNFe.CodigoVerificacao} target='_blank' rel="noopener noreferrer" className="btn btn-success">
                                                                                    <i className="fa-solid fa-file-circle-check" />
                                                                                </a>
                                                                            }
                                                                            {
                                                                                (item.currentStatus === NOTE_GENERATED || item.currentStatus === RPS_CREATED) &&
                                                                                <button type="button" onClick={(e) => { this.showCancelNote(item) }} className="btn btn-danger">
                                                                                    <i className="fas fa-trash" />
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Dialog onClose={this.hideStatus} maxWidth={"lg"} open={this.state.isShowingStatus}>
                                        <MuiDialogTitle className='text-center'>
                                            Todos os status
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            {
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <table className="table table-striped">
                                                            <tr>
                                                                <th>Data e Horário</th>
                                                                <th>Operador</th>
                                                                <th>Mensagem</th>
                                                                <th></th>
                                                                <th></th>
                                                            </tr>
                                                            <tbody>
                                                                {this.state.noteSelected?.status.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {Moment(item.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm")}
                                                                        </td>
                                                                        <td>
                                                                            {item.createBy?.nome}
                                                                        </td>
                                                                        <td>
                                                                            {item.message}
                                                                        </td>
                                                                        <td>
                                                                            {item.status === RPS_CREATED && <h6><span className="badge text-bg-warning text-white">RPS criado</span></h6>}
                                                                            {item.status === NOTE_GENERATED && <h6><span className="badge text-bg-success">NFS-e gerada</span></h6>}
                                                                            {item.status === NOTE_GENERATED_ERROR && <h6><span className="badge text-bg-danger">Falha na emissão</span></h6>}
                                                                            {item.status === NOTE_CANCELED && <h6><span className="badge text-bg-danger">Nota cancelada</span></h6>}
                                                                            {item.status === RPS_CANCELED && <h6><span className="badge text-bg-danger">RPS cancelado</span></h6>}
                                                                        </td>
                                                                        <td width='10'>
                                                                            {
                                                                                item.log !== undefined &&
                                                                                <button onClick={(e) => this.showLog(item.log)} className="btn btn-secondary">
                                                                                    <i className="fas fa-clipboard-list" />
                                                                                </button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.noteSelected?.currentStatus === NOTE_GENERATED_ERROR &&
                                                <div>
                                                    <div className='alert alert-warning'>
                                                        <strong>Informação: </strong> É possível tentar emitir a nota novamente.
                                                    </div>
                                                </div>
                                            }
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                {
                                                    this.state.noteSelected?.currentStatus === NOTE_GENERATED_ERROR && <button onClick={this.retry} className='btn btn-danger'>Tentar novamente</button>
                                                }
                                                <button onClick={this.hideStatus} className='btn btn-secondary'>Fechar</button>
                                            </div>
                                        </MuiDialogActions>
                                    </Dialog>
                                    <Dialog onClose={this.hideCancelNote} maxWidth='sm' open={this.state.isShowCancelNote}>
                                        <MuiDialogTitle className='text-center'>
                                            {
                                                this.state.noteSelected?.currentStatus === NOTE_GENERATED ?
                                                    "Solicitar cancelamento de nota" :
                                                    "Solicitar cancelamento do RPS"
                                            }
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div>
                                                <div className='alert alert-danger'>
                                                    {
                                                        this.state.noteSelected?.currentStatus === NOTE_GENERATED &&
                                                        <>
                                                            <strong>Atenção: <p /> </strong> Você deve acessar o site de emissão de notas da prefeitura para solicitar o cancelamento, clicando no botão abaixo será apenas uma mudança de status no nosso sistema.<p />
                                                            Site da prefeitura de São Paulo: <p />
                                                            <a href='https://nfe.prefeitura.sp.gov.br/Loginicp.aspx' target='_blank'>https://nfe.prefeitura.sp.gov.br</a>
                                                        </>
                                                    }
                                                    {
                                                        this.state.noteSelected?.currentStatus === RPS_CREATED &&
                                                        <><strong>Atenção: <p /> </strong> Deseja mesmo solicitar o cancelamento do RPS com o valor de {<NumberFormat value={this.state.noteSelected?.valorNota} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}? <p /><strong>ESTA AÇÃO NÃO PODERÁ SER DESFEITA.</strong></>
                                                    }
                                                </div>
                                            </div>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={this.hideCancelNote} className='btn btn-secondary'>Fechar</button>
                                                {
                                                    this.state.noteSelected?.currentStatus === NOTE_GENERATED &&
                                                    <button onClick={this.requestCancelNote} className='btn btn-danger'>Confirmo que já cancelei a nota no site da prefeitura</button>
                                                }
                                                {
                                                    this.state.noteSelected?.currentStatus === RPS_CREATED &&
                                                    <button onClick={this.requestCancelNote} className='btn btn-warning text-white'>Confirmar o cancelamento do RPS</button>
                                                }
                                            </div>
                                        </MuiDialogActions>
                                    </Dialog>
                                    <Dialog onClose={this.hideGenerateManual} maxWidth='sm' open={this.state.isShowGenerateManual}>
                                        <MuiDialogContent>
                                            <h5 className='text-center'>Este RPS foi gerado manualmente por: <p /> {this.state.noteSelected?.createBy.nome}<p /> {this.state.noteSelected?.createBy.email}</h5>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <button onClick={this.hideGenerateManual} className='btn btn-info m-3'>Fechar</button>
                                        </MuiDialogActions>
                                    </Dialog>
                                    <Dialog onClose={this.closeGenerateNote} maxWidth='sm' open={this.state.isShowGenerateNote}>
                                        {
                                            !this.state.isLoadingGenerateNote &&
                                            <MuiDialogTitle className='text-center'>
                                                Detalhes do RPS
                                            </MuiDialogTitle>
                                        }
                                        <MuiDialogContent>
                                            {
                                                !this.state.isLoadingGenerateNote &&
                                                <>
                                                    <div>
                                                        <div className='alert alert-warning'>
                                                            <strong>Atenção: </strong> Confira se os dados para emissão da nota fiscal estão corretos.
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <label>Tipo documento</label>
                                                                <div className="input-group mb-3">
                                                                    <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                                                        <option value='CNPJ'>CNPJ</option>
                                                                        <option value='CPF'>CPF</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <label>Documento</label>
                                                                <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.noteSelectedDocument} onChange={(e) => this.setState({ noteSelectedDocument: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label>E-mail</label>
                                                                <input type="email" value={this.state.noteSelectedEmail && this.state.noteSelectedEmail} onChange={(e) => this.setState({ noteSelectedEmail: e.target.value })} className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='alert alert-info my-3'>
                                                            <strong>Informação: </strong> Todos os RPS's são processados imediatamente, caso o sistema da prefeitura esteja fora do ar, eles serão reprocessados as 9h da manhã do dia seguinte da emissão.
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.isLoadingGenerateNote &&
                                                <>
                                                    <div>
                                                        <Loading />
                                                        <div className='my-3 text-center'>
                                                            <h3>{this.state.statusGenerateNote}</h3>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </MuiDialogContent>
                                        {
                                            !this.state.isLoadingGenerateNote &&
                                            <MuiDialogActions>
                                                <div className='btn-group m-3'>
                                                    <button onClick={this.requestNote} className='btn btn-warning text-white'>Salvar dados do RPS</button>
                                                    <button onClick={this.closeGenerateNote} className='btn btn-secondary'>Cancelar</button>
                                                </div>
                                            </MuiDialogActions>
                                        }
                                    </Dialog>
                                    <Dialog maxWidth='sm' open={this.state.isShowExecuteProcessing}>
                                        {
                                            this.state.isShowingLoadingProccess ? <>
                                                <Loading />
                                                <EmptyContent text="Aguarde, estamos enviando seu pedido para a prefeitura." />
                                            </> :
                                                <>
                                                    <MuiDialogTitle className='text-center'>
                                                        Executar processo de emissão de nota
                                                    </MuiDialogTitle>
                                                    <MuiDialogContent>
                                                        <div>
                                                            <div className='alert alert-danger'>
                                                                <strong>Atenção: </strong> O processo é executado toda manhã até as 9 horas, deseja mesmo executar o processo agora?
                                                            </div>
                                                            <div className='alert alert-info'>
                                                                <strong>Informação: </strong> Apenas RPS na etapa "RPS Criado" sera enviado para processamento.
                                                            </div>
                                                        </div>
                                                    </MuiDialogContent>
                                                    <MuiDialogActions>
                                                        <div className='btn-group m-3'>
                                                            <button onClick={(e) => this.setState({ isShowExecuteProcessing: false })} className='btn btn-secondary text-white'>Cancelar</button>
                                                            <button onClick={this.confirmExecuteProccess} className='btn btn-danger'>Confirmar</button>
                                                        </div>
                                                    </MuiDialogActions>
                                                </>
                                        }
                                    </Dialog>
                                    <Dialog maxWidth="lg" open={this.state.isShowLog}>
                                        <MuiDialogTitle className='text-center'>
                                            Detalhes
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <textarea className="form-control" cols={80} rows={10} disabled>
                                                {this.state.log}
                                            </textarea>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <button onClick={(e) => this.setState({ isShowLog: false })} className='btn btn-secondary m-3'>Fechar</button>
                                        </MuiDialogActions>
                                    </Dialog>
                                </div>
                    }
                </NavBar>
            </>)
    }
}

export { NoteList };