import React from 'react'
import { EmptyContent, Loading } from '../../components/common/commons';
import 'firebase/auth';
import 'firebase/storage';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { sendClickButton } from '../../shared/analytics';
import '../dashboard/index.css'
import { getTicketsOpenByParkId, updateTicketById } from '../../store/collections/ticketWorker';
import { activeCashier, getAllActiveCashiers } from '../../store/collections/cashierWorker';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { cashierPermanenceFromNowDescription, dangerConfirm, goToWindow, isNull, isNullOrEmpty, ticketPermanenceFromNowDescription, toastDismissLoading, toastWarning, valueByMaximunChars } from '../../shared/utils';
import { TicketRegisterNewVehicleModal, TicketSearchAndRegisterPaymentModal } from '../ticket';
import Chart from "react-apexcharts";
import { getEstacionamento, getPerfil, getUsuarioCompact, isAdministrador } from '../../config/auth';
import { MensalistasPaymentFlow } from '../mensalista/clientes';
import { getParameterByParkId } from '../../store/collections/parameterWorker';
import { SingleSaleModal } from '../sales';
import { TicketStatus } from '../../shared/constants';
import { getURLFromURI } from '../../store/filesWorker';
import { PrinterTicketsPatioModal } from '../ticket/index'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

class TicketDashboardAll extends React.Component {

    constructor(props) {
        super(props)
        var series = []
        const items = props.tickets.map(e => parseInt(moment(e.createAt.seconds * 1000).format("HH")))
        const dates = [...new Set(items)].sort((a, b) => a - b)
        for (const date of dates) {
            series.push(items.filter(e => e === date).length)
        }
        this.state = {
            dates: dates,
            series: series,
            title: `${items.length} veículos lançados`
        }
    }

    render() {
        return (
            <TicketDashboardCards
                tickets={this.props.tickets}
                dates={this.state.dates}
                series={this.state.series}
                title={this.state.title}
                type="warning" />
        )
    }
}

class TicketDashboardParking extends React.Component {

    constructor(props) {
        super(props)
        var series = []
        const tickets = props.tickets.filter((e) => e.status === "Estacionado")
        const items = tickets.map(e => parseInt(moment(e.createAt.seconds * 1000).format("HH")))
        const dates = [...new Set(items)].sort((a, b) => a - b)
        for (const date of dates) {
            series.push(items.filter(e => e === date).length)
        }
        this.state = {
            tickets: tickets,
            dates: dates,
            series: series,
            title: `${items.length} estacionados`
        }
    }

    render() {
        return (
            <TicketDashboardCards
                tickets={this.state.tickets}
                dates={this.state.dates}
                series={this.state.series}
                title={this.state.title}
                type="primary" />
        )
    }
}

class TicketDashboardParkingAndPayed extends React.Component {

    constructor(props) {
        super(props)
        var series = []
        const tickets = props.tickets.filter((e) => e.status === "Pago e Estacionado")
        const items = tickets.map(e => parseInt(moment(e.createAt.seconds * 1000).format("HH")))
        const dates = [...new Set(items)].sort((a, b) => a - b)
        for (const date of dates) {
            series.push(items.filter(e => e === date).length)
        }
        this.state = {
            tickets: tickets,
            dates: dates,
            series: series,
            title: `${items.length} pagos e estacionados`
        }
    }

    render() {
        return (
            <TicketDashboardCards
                tickets={this.state.tickets}
                dates={this.state.dates}
                series={this.state.series}
                title={this.state.title}
                type="info" />
        )
    }
}

class TicketDashboardParkingAndDeliverd extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tickets: [],
            dates: [],
            series: [],
            title: `0 pagos e entregues`
        }
        this.loadTickets(props)
    }

    loadTickets = async (props) => {
        var series = []
        var tickets = []
        var items = []
        const estacionamentoId = getEstacionamento()?.id
        const parameters = await getParameterByParkId({ estacionamentoId: estacionamentoId })
        if (parameters.typeCashierOpened === "user") {
            const user = getUsuarioCompact()
            tickets = props.tickets.filter((e) => e.status === "Pago e Entregue" && e.closedAt && e.paymentsLogged.map(e => e.user.id).includes(user.id))
            items = tickets.map(e => parseInt(moment(e.closedAt.seconds * 1000).format("HH")))
        } else {
            tickets = props.tickets.filter((e) => e.status === "Pago e Entregue" && e.closedAt)
            items = tickets.map(e => parseInt(moment(e.closedAt.seconds * 1000).format("HH")))
        }
        const dates = [...new Set(items)].sort((a, b) => a - b)
        for (const date of dates) {
            series.push(items.filter(e => e === date).length)
        }
        this.setState({
            tickets: tickets,
            dates: dates,
            series: series,
            title: `${items.length} pagos e entregues`,
            random: Math.random()
        })
    }

    render() {
        return (
            <TicketDashboardCards
                key={this.state.random}
                tickets={this.state.tickets}
                dates={this.state.dates}
                series={this.state.series}
                title={this.state.title}
                type="success" />
        )
    }
}

class TicketDashboardCards extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isAdministrador: isAdministrador(),
            isShowingModal: false,
            tickets: props.tickets,
            auxTickets: props.tickets,
            title: props.title,
            total: props.series.length,
            totalMonthlies: props.tickets.filter(e => e.monthly).length,
            series: [{
                data: props.series,
                color: "#ffffff",
            }],
            options: {
                chart: {
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value
                        },
                        style: {
                            colors: ["#ffffff"],
                        },
                    },
                },
                xaxis: {
                    labels: {
                        show: false,
                        formatter: function (value) {
                            return `${value}:00`;
                        }
                    }
                },
                tooltip: {
                    theme: 'dark',
                    enabled: true
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: [4],
                    curve: 'smooth',
                    colors: ["#ffffff"]
                },
                legend: {
                    show: false
                },
                labels: props.dates.map(e => String(e).padStart("2", "0")),
                grid: {
                    borderColor: this.colorFromGrid(),
                },
                fill: {
                    colors: this.colorFromGrid()
                }
            }
        }
    }

    colorFromGrid = () => {
        if (this.props.type === "primary") {
            return "0d6efd"
        }
        if (this.props.type === "warning") {
            return "0d6efd"
        }
        return "0d6efd"
    }

    classFromCard = () => {
        if (this.props.type === "primary") {
            return "card text-white text-center bg-primary"
        }
        if (this.props.type === "warning") {
            return "card text-white text-center bg-warning"
        }
        if (this.props.type === "info") {
            return "card text-white text-center bg-info"
        }
        if (this.props.type === "success") {
            return "card text-white text-center bg-success"
        }
        return "card text-white text-center bg-primary"
    }

    alertFromCard = (item) => {
        if (item.status === TicketStatus.CANCELED) {
            return `alert alert-danger`
        }
        return `alert alert-${this.props.type}`
    }

    tagFromCard = (item) => {
        if (item.status === TicketStatus.CANCELED) {
            return `badge text-bg-danger text-white`
        }
        return `badge text-bg-${this.props.type} text-white`
    }

    buttonFromCard = (item) => {
        if (item?.status === TicketStatus.CANCELED) {
            return `btn btn-danger text-white mt-2`
        }
        return `btn btn-${this.props.type} text-white mt-2`
    }

    showModal = () => {
        if (this.state.total > 0) {
            this.setState({ isShowingModal: true })
            sendClickButton("Dashboard", `Visualizou modal de ${this.state.title}`)
        }
    }

    confirmFinishTicket = async (item) => {
        const confirm = await dangerConfirm({ message: "Deseja remover o ticket da listagem?" })
        if (confirm) {
            toastWarning("Removendo...")
            item.caixaFechado = true
            await updateTicketById({ id: item.id, data: item })
            const newTickets = this.state.tickets.filter(e => e.id !== item.id)
            this.setState({ tickets: newTickets })
            toastDismissLoading()
            if (isNullOrEmpty(this.state.search)) {
                this.setState({ title: `${newTickets.length} tickets encontrados` })
            } else {
                this.setState({ title: `${newTickets.length} tickets encontrados` })
            }
        }
    }

    orderBy = (order) => {
        this.setState({ isMenuOpen: false })
        var tickets = []
        switch (order) {
            case "todos":
                tickets = this.state.auxTickets
                break
            case "interrompidos":
                tickets = this.state.auxTickets.filter(e => e.interrupted == true)
                break
            case "credenciados":
                tickets = this.state.auxTickets.filter(e => e.accredited)
                break
            case "mensalistas":
                tickets = this.state.auxTickets.filter(e => e.monthly)
                break
            case "avulsos":
                tickets = this.state.auxTickets.filter(e => (!e.monthly && !e.accredited))
                break
            case "passou":
                tickets = this.state.auxTickets.filter(e => e.gate)
                break
        }
        this.setState({ tickets: tickets })
        this.setState({ title: `${tickets.length} tickets encontrados` })
    }

    handleCloseOrder = () => {
        this.setState({ isMenuOrderOpen: false })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        this.setState({ search: value })
        if (!isNullOrEmpty(value)) {
            if (e.target.value === ">") {
                const tickets = this.state.tickets.filter(e => e.paymentsLogged?.length > 1)
                this.setState({ tickets: tickets })
            } else {
                const tickets = this.state.auxTickets.filter(e =>
                    moment(e.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm").includes(value) ||
                    e.ticket?.includes(value) ||
                    e.observation?.toLowerCase()?.includes(value.toLowerCase()) ||
                    e.placa?.toUpperCase().includes(value.toUpperCase()) ||
                    e.monthly?.nome?.toUpperCase().includes(value.toUpperCase()) ||
                    e.accredited?.nome?.toUpperCase().includes(value.toUpperCase())
                )
                this.setState({ tickets: tickets })
                this.setState({ title: `${tickets.length} tickets encontrados` })
            }
        }
        else {
            this.setState({ title: this.props.title })
            this.setState({ tickets: this.state.auxTickets })
        }
    }


    handleOpenMenuParks = (event) => {
        this.anchorEl = event.currentTarget
        this.setState({ isMenuOpen: true })
    }

    handleCloseMenuParks = () => {
        this.setState({ isMenuOpen: false })
    }

    render() {
        return (
            <>
                <div className={this.classFromCard()} role="button" onClick={(e) => this.showModal()}>
                    <div className='card-header'>
                        {this.state.title} {this.state.totalMonthlies > 0 && ` | ${this.state.totalMonthlies} mensalistas`}
                    </div>
                    {
                        this.state.total > 0 ? <>
                            <Chart options={this.state.options} series={this.state.series} height={150} />
                        </> : <div className='card-body'>
                            <h5 className="card-text p-3">Nenhum veículo com este status</h5>
                        </div>
                    }
                </div>
                <Dialog onClose={(e) => this.setState({ isShowingModal: false })} maxWidth='sm' fullWidth open={this.state.isShowingModal}>
                    <MuiDialogTitle className='text-center'>
                        {this.state.title}
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='col-lg-12 mb-3'>
                            <div className='input-group'>
                                <input type="text" onChange={this.search} placeholder="Busque por placa ou número do ticket..." className="form-control form-control-lg text-center" />
                                <Button
                                    anchorEl={el => (this.anchorEl = el)}
                                    aria-controls={this.state.isMenuOpen ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    className='btn btn-secondary'
                                    aria-expanded={this.state.isMenuOpen}
                                    onClick={this.handleOpenMenuParks}>
                                    <i className="fa-solid fa-filter" />
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={this.anchorEl}
                                    onClose={this.handleCloseMenuParks}
                                    open={this.state.isMenuOpen}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}>
                                    <MenuItem onClick={() => this.orderBy("todos")}>
                                        <ListItemIcon>
                                            <i className="fas fa-bars" />
                                        </ListItemIcon>
                                        Todos ({this.state.auxTickets.length})
                                    </MenuItem>
                                    <MenuItem onClick={() => this.orderBy("avulsos")}>
                                        <ListItemIcon>
                                            <i className="fas fa-ticket-alt" />
                                        </ListItemIcon>
                                        Avulsos ({this.state.auxTickets.filter(e => (!e.monthly && !e.accredited)).length})
                                    </MenuItem>
                                    <MenuItem onClick={() => this.orderBy("mensalistas")}>
                                        <ListItemIcon>
                                            <i className="fas fa-calendar" />
                                        </ListItemIcon>
                                        Mensalistas ({this.state.auxTickets.filter(e => e.monthly).length})
                                    </MenuItem>
                                    <MenuItem onClick={() => this.orderBy("credenciados")}>
                                        <ListItemIcon>
                                            <i className="fas fa-circle-user" />
                                        </ListItemIcon>
                                        Credenciados ({this.state.auxTickets.filter(e => e.accredited).length})
                                    </MenuItem>
                                    {
                                        this.state.isAdministrador && <>
                                            <MenuItem onClick={() => this.orderBy("interrompidos")}>
                                                <ListItemIcon>
                                                    <i className="fas fa-minus-circle"></i>
                                                </ListItemIcon>
                                                Interrompidos ({this.state.auxTickets.filter(e => e.interrupted == true).length})
                                            </MenuItem>
                                            <MenuItem onClick={() => this.orderBy("passou")}>
                                                <ListItemIcon>
                                                    <i className="fas fa-arrow-circle-up"></i>
                                                </ListItemIcon>
                                                Passou na cancela ({this.state.auxTickets.filter(e => e.gate).length})
                                            </MenuItem>
                                        </>
                                    }
                                </Menu>
                            </div>
                        </div>
                        {
                            isNullOrEmpty(this.state.tickets) ? <EmptyContent text="Nenhum ticket encontrado." /> :
                                <>
                                    {
                                        this.state.tickets.map((item) =>
                                            <div className={this.alertFromCard(item)}>
                                                <div className='mb-2'>
                                                    {item.status === TicketStatus.PAYED_AND_WAITING_BACK && <div><span className="badge text-bg-success">Este veículo esta pago e aguardando volta </span> <br /> </div>}
                                                    {
                                                        item.status !== TicketStatus.PAYED_AND_DELIVERED && item.limitPermanenceEnabled == true && item.limitPermanence && <>
                                                            {
                                                                moment(item.limitPermanence.seconds * 1000).isBefore(moment()) ?
                                                                    <span className="badge text-bg-danger text-white">Saída expirada: {moment(item.limitPermanence.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</span> :
                                                                    <span className={this.tagFromCard(item)}>Previsão de saída: {moment(item.limitPermanence.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</span>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                {
                                                    item.type === "SALES" ? <div>
                                                        <div><strong>Controle:</strong> {item.ticket}</div>
                                                        <div><strong>Data:</strong> {moment(item.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm")}</div>
                                                        <div className='btn-group'>
                                                            <button type="button" className={this.buttonFromCard()}>
                                                                <i className="fas fa-utensils" />
                                                                <span className='mx-2'>Venda avulsa {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                            </button>
                                                            {
                                                                this.state.isAdministrador &&
                                                                <button type="button" className="btn btn-danger mt-2" onClick={(e) => this.confirmFinishTicket(item)}>
                                                                    <i className="fas fa-store-alt-slash" />
                                                                </button>
                                                            }
                                                        </div>
                                                    </div> :
                                                        <div className='row'>
                                                            {
                                                                !isNullOrEmpty(item.urlVehicle) &&
                                                                <div className='col-lg-5'>
                                                                    <TicketDashboardImage ticket={item} />
                                                                </div>
                                                            }
                                                            <div className={isNullOrEmpty(item.urlVehicle) ? 'col-lg-9' : 'col-lg-7'}>
                                                                <div><strong>Ticket:</strong> {item.ticket}</div>
                                                                {
                                                                    !isNullOrEmpty(item.placa) &&
                                                                    <div><strong>Placa:</strong> {item.placa}</div>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(item.vehicler?.modelo) &&
                                                                    <div><strong>Modelo:</strong> {item.vehicler?.modelo}</div>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(item.createAt) &&
                                                                    <>
                                                                        <div><strong>Entrada:</strong> {moment(item.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm")}</div>
                                                                        <div><strong>Permanência:</strong> {ticketPermanenceFromNowDescription(item)}</div>
                                                                    </>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(item.setor) &&
                                                                    <div><strong>Setor:</strong> {valueByMaximunChars(item.setor, 100)}</div>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(item.observation) &&
                                                                    <div><strong>Observação:</strong><br /> {valueByMaximunChars(item.observation, 100)}</div>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(item.monthly) &&
                                                                    <div><strong>Mensalista:</strong> {valueByMaximunChars(item.monthly.nome, 100)}</div>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(item.accredited) &&
                                                                    <div><strong>Credenciado:</strong> {valueByMaximunChars(item.accredited.nome, 100)}</div>
                                                                }
                                                                <div className='btn-group'>
                                                                    {
                                                                        !isNullOrEmpty(item.monthly) &&
                                                                        <a href={`/buscar/ticket?id=${item.id}`} className={this.buttonFromCard(item)}>
                                                                            <i className="fas fa-calendar" />
                                                                            <span className='mx-2'>Mensalista {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                        </a>
                                                                    }
                                                                    {
                                                                        !isNullOrEmpty(item.accredited) &&
                                                                        <a href={`/buscar/ticket?id=${item.id}`} className={this.buttonFromCard(item)}>
                                                                            <i className="fas fa-circle-user" />
                                                                            <span className='mx-2'>Credenciado {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                        </a>
                                                                    }
                                                                    {
                                                                        !item.monthly && !item.accredited &&
                                                                        <a href={`/buscar/ticket?id=${item.id}`} className={this.buttonFromCard(item)}>
                                                                            <i className="fas fa-ticket-alt" />
                                                                            <span className='mx-2'>Avulso {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                        </a>
                                                                    }
                                                                    {
                                                                        this.state.isAdministrador &&
                                                                        <button type="button" className='btn btn-danger mt-2' onClick={(e) => this.confirmFinishTicket(item)}>
                                                                            <i className="fas fa-store-alt-slash" />
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        )
                                    }
                                </>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={(e) => this.setState({ isShowingModal: false })} className='btn btn-secondary m-3'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class TicketDashboardImage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: isNullOrEmpty(props.ticket.urlVehicle) ? "empty" : "loading"
        }
        this.loadImage()
    }

    loadImage() {
        const urlVehicle = this.props.ticket.urlVehicle
        if (isNullOrEmpty(urlVehicle)) {
            return
        }
        getURLFromURI(urlVehicle).then(url => {
            this.setState({ state: "loaded", url: url })
        })
    }

    render() {
        return (
            <>
                {
                    this.state.state === "loading" &&
                    <div style={{ backgroundColor: "#ececec", borderRadius: "5px", width: "220px", height: "100%" }} />
                }
                {
                    this.state.state === "loaded" &&
                    <img src={this.state.url} alt="Veículo" style={{ backgroundColor: "#ececec", borderRadius: "5px", width: "200px", height: "100%" }} />
                }
            </>
        )
    }
}

class ManagerDashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            isOpenNewTicketModal: false,
            isOpenPaymentTicketModal: false,
            isOpenCashiers: false,
            allCashiers: []
        }
    }

    componentDidMount = () => {
        this.findCashiersOpen()
        this.findParameters()
    }

    findCashiersOpen = async () => {
        const perfil = getPerfil()
        if (perfil?.habilitarVisualizacaoDeCaixaAbertoPorOutroOperador === true || isAdministrador()) {
            const allCashiers = await getAllActiveCashiers({ estacionamentoId: this.state.estacionamentoId })
            this.setState({ allCashiers: allCashiers })
        } else {
            const cashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
            if (cashier) {
                this.setState({ allCashiers: [cashier] })
            }
        }
    }

    findParameters = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameters: parameters })
    }

    onCloseSearchAndPaymentModal = () => {
        this.setState({ isOpenPaymentTicketModal: false })
    }

    onCloseNewVehicleModal = () => {
        this.setState({ isOpenNewTicketModal: false })
    }

    onClosePaymentMonthlyModal = () => {
        this.setState({ isOpenPaymentMonthlyModal: false })
    }

    onCloseSingleSaleModal = () => {
        this.setState({ isOpenSingleSaleModal: false })
    }

    handleIsOpenCashiers = () => {
        sendClickButton("Dashboard", `Visualizou modal de caixas abertos.`)
        this.setState({ isOpenCashiers: true })
    };

    handlePaymentTicket = () => {
        sendClickButton("Dashboard", `Visualizou modal de busca de pagamento de ticket.`)
        this.setState({ isOpenPaymentTicketModal: true })
    }

    handleSingleSale = () => {
        sendClickButton("Dashboard", `Visualizou modal de venda avulsa.`)
        this.setState({ isOpenSingleSaleModal: true })
    }

    handlePaymentMonthly = () => {
        sendClickButton("Dashboard", `Visualizou modal de pagar mensalista.`)
        this.setState({ isOpenPaymentMonthlyModal: true })
    };

    handleNewTicket = () => {
        sendClickButton("Dashboard", `Visualizou modal de novo veículo.`)
        this.setState({ isOpenNewTicketModal: true })
    };

    openCashierSelected = (item) => {
        console.log(item);
        sendClickButton("Dashboard", `Visualizou o caixa ${item.sequence} - ${item.id}`)
        window.open(`/caixas/${item.id}`,
            '_blank'
        );
    };

    handleIsCloseCashiers = () => {
        this.setState({ isOpenCashiers: false })
    };

    render() {
        return (
            <>
                {
                    this.state.allCashiers.length > 0 &&
                    <div className="">
                        <div className='btn-group-vertical w-100'>
                            {
                                this.state.allCashiers.length > 1 ?
                                    <Tooltip title="Visualizar caixas" placement="right">
                                        <button type="button" onClick={this.handleIsOpenCashiers} className="btn btn-secondary">
                                            <i className="fas fa-eye" /> <br />
                                            Caixas ({this.state.allCashiers.length})
                                        </button>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Visualizar o caixa" placement="right">
                                        <button type="button" className="btn btn-secondary" onClick={(e) => this.openCashierSelected(this.state.allCashiers[0])}>
                                            <i className="fas fa-eye" /> <br />
                                            Caixa
                                        </button>
                                    </Tooltip>
                            }
                            <Tooltip title="Registrar novo veículo" placement="right">
                                <button type="button" onClick={this.handleNewTicket} className="btn btn-secondary">
                                    <i className="fas fa-car" /> <br />
                                    Novo
                                </button>
                            </Tooltip>
                            <Tooltip title="Entregar veículo" placement="right">
                                <button type="button" onClick={this.handlePaymentTicket} className="btn btn-secondary">
                                    <i className="fas fa-money-bill-wave" /> <br />
                                    Entregar
                                </button>
                            </Tooltip>
                            {
                                this.state.parameters?.habilitarVendaAvulsa &&
                                <Tooltip title="Fazer uma venda" placement="right">
                                    <button type="button" onClick={this.handleSingleSale} className="btn btn-secondary">
                                        <i className="fas fa-utensils" /> <br />
                                        Vender
                                    </button>
                                </Tooltip>
                            }
                            <Tooltip title="Pagar mensalidade" placement="right">
                                <button type="button" onClick={this.handlePaymentMonthly} className="btn btn-secondary">
                                    <i className="fas fa-calendar" /> <br />
                                    Mensalista
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                }
                <Dialog onClose={this.handleIsCloseCashiers} maxWidth='xs' fullWidth open={this.state.isOpenCashiers}>
                    <MuiDialogTitle className='text-center'>
                        Todos os caixas abertos
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.allCashiers.map((item) =>
                                <a onClick={(e) => this.openCashierSelected(item)}>
                                    <div className='col-lg-12 alert alert-primary' role='button'>
                                        <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                            <div><strong>Sequência:</strong> {item.sequence}</div>
                                            <div><strong>Reponsável:</strong> {item.openBy?.nome}</div>
                                            <div><strong>Aberto:</strong> {moment(item.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</div>
                                            <div><strong>Tempo:</strong> {cashierPermanenceFromNowDescription(item)}</div>
                                            <button type="button" className="btn btn-primary my-2">
                                                <i className="fas fa-dollar-sign" />
                                                <span className='mx-2'>Detalhes</span>
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            )
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={this.handleIsCloseCashiers} className='btn btn-secondary m-3'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
                {
                    this.state.isOpenPaymentTicketModal && <TicketSearchAndRegisterPaymentModal title="Selecione o veículo para registrar o pagamento" onClose={this.onCloseSearchAndPaymentModal} />
                }
                {
                    this.state.isOpenNewTicketModal && <TicketRegisterNewVehicleModal onClose={this.onCloseNewVehicleModal} />
                }
                {
                    this.state.isOpenSingleSaleModal && <SingleSaleModal onClose={this.onCloseSingleSaleModal} />
                }
                {
                    this.state.isOpenPaymentMonthlyModal &&
                    <MensalistasPaymentFlow onClose={this.onClosePaymentMonthlyModal} />
                }
            </>
        )
    }
}

class TicketDashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            hasTickets: false,
            tickets: [],
            allTickets: [],
            state: "load-data",
            isShowingPrintPatioModal: false,
            impressaoEstacionados: true,
            impressaoPagosEstacionados: false,
            impressaoPagosEntregues: false,
        }
    }

    handleIsOpenEstacionados = () => {
        this.setState({ isOpenEstacionados: true })
    };

    handleIsCloseEstacionados = () => {
        this.setState({ isOpenEstacionados: false })
    };

    handleIsOpenPagosEEstacionados = () => {
        this.setState({ isOpenPagosEEstacionados: true })
    };

    handleIsClosePagosEEstacionados = () => {
        this.setState({ isOpenPagosEEstacionados: false })
    };

    handleIsClosePagosEEntregues = () => {
        this.setState({ isOpenPagosEEntregues: false })
    };

    handleIsOpenPagosEEntregues = () => {
        this.setState({ isOpenPagosEEntregues: true })
    };

    findTickets = async () => {
        if (this.state.estacionamentoId !== null) {
            this.setState({ state: "loading" })
            const list = await getTicketsOpenByParkId(this.state.estacionamentoId)
            if (list.length > 0) {
                this.setState({ allTickets: list })
                this.setState({ tickets: list })
                this.setState({ random: Math.random() })
                this.setState({ state: "data" })
            } else {
                this.setState({ state: "empty" })
            }
        }
    }

    showPrintPatioModal = () => {
        this.setState({ isShowingPrintPatioModal: true })
    }

    onClosePrintPatioModal = () => {
        this.setState({ isShowingPrintPatioModal: false })
    }

    render() {
        return (
            <>
                {
                    this.state.state === "loading" && <Loading />
                }
                {
                    this.state.state === "empty" && <div className='col-lg-12 text-center my-5'>
                        <img alt='Icone produtos vazios' width='100' height='100' src={require('../../files/icons/icon_ticket.png')} />
                        <h4 className='item-h4'>Nenhum ticket lançado no caixa até o momento.</h4>
                    </div>
                }
                {
                    this.state.state === "load-data" && <div className='col-lg-12 text-center my-5'>
                        <img alt='Icone produtos vazios' width='100' height='100' src={require('../../files/icons/icon_ticket.png')} />
                        <h4 className='item-h4'>Toque para carregar os tickets do estabelecimento.</h4>
                        <button className='btn btn-success' onClick={this.findTickets}>Carregar tickets</button>
                    </div>
                }
                {
                    this.state.state === "data" &&
                    <div className='col-lg-12'>
                        <div className="card-group">
                            <TicketDashboardAll
                                key={this.state.random}
                                tickets={this.state.tickets}
                            />
                            <TicketDashboardParking
                                key={this.state.random}
                                tickets={this.state.tickets}
                            />
                            <TicketDashboardParkingAndPayed
                                key={this.state.random}
                                tickets={this.state.tickets}
                            />
                            <TicketDashboardParkingAndDeliverd
                                key={this.state.random}
                                tickets={this.state.tickets}
                            />
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <button onClick={this.showPrintPatioModal} className="btn btn-sm btn-secondary">
                                <i className="fas fa-print mx-2" />
                                Imprimir
                            </button>
                        </div>
                    </div>
                }
                {
                    this.state.isShowingPrintPatioModal && <PrinterTicketsPatioModal allTickets={this.state.allTickets} onClose={this.onClosePrintPatioModal} />
                }
            </>
        )
    }
}
export { TicketDashboard, ManagerDashboard, TicketDashboardImage }