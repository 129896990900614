import React from 'react'
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent, ClientButton } from '../../../components/common/commons.js'
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getRevenda, getUsuarioCompact } from '../../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { deleteNewNoteById, getAllChargeNotesByRessaleId, updateNewNoteById } from '../../../store/collections/newNotesWorker';
import { fetchFunction } from '../../../shared/function-request';
import { sendLog, sendLogByUser } from '../../../shared/analytics';
import { dangerConfirm, documentMask, isValidCNPJ, isValidDocument, reloadWindow, reloadWithAlert, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning, valueByMaximunChars } from '../../../shared/utils';
import { searchCEP } from '../../../shared/cepWorker';
import { AlertDanger } from '../../../shared/alert-manager.js';

const NOTE_PROCESSING = "NOTE_PROCESSING"
const NOTE_GENERATED = "NOTE_GENERATED"
const NOTE_CANCELED = "NOTE_CANCELED"
const NOTE_ERROR = "NOTE_ERROR"
const NOTE_CONTIGENCY = "NOTE_CONTIGENCY"

class ListarNotasRevendas extends React.Component {

    render() {
        return (
            <NavBar>
                <ListarNotasRevendasBody />
            </NavBar>
        )
    }
}

class ListarNotasRevendasBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            revendaId: getRevenda()?.id,
            items: [],
            numero: "",
            isLoading: true,
            isShowingStatus: false,
            processNoteLoading: false,
        }
    }

    componentDidMount = async () => {
        await this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        var items = await getAllChargeNotesByRessaleId({ ressaleId: this.state.revendaId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    showStatus = (item) => {
        this.setState({ currentItem: item })
        this.setState({ isShowingStatus: true })
    }

    hideStatus = () => {
        this.setState({ isShowingStatus: false })
    }

    showLog = (item) => {
        this.setState({ currentItemLog: JSON.stringify(item.log) })
        this.setState({ isShowingLog: true })
    }

    hideLog = () => {
        this.setState({ isShowingLog: false })
    }

    showProcessNote = (item) => {
        this.setState({ isNoteProcessOpen: true })
        this.setState({ currentItem: item })
    }

    confirmProcessNote = async () => {
        const item = this.state.currentItem
        const option = {
            method: 'POST',
            body: {
                id: item.id
            }
        }
        toastSuccess("Nota enviada com sucesso para processamento.")
        this.setState({ isNoteProcessOpen: false })
        const result = await fetchFunction(`/newnote/process`, option)
        if (result.success) {
            this.load()
        } else {
            toastWarning(result.message)
        }
    }

    editNote = (item) => {
        this.setState({ name: item.name })
        this.setState({ document: item.document })
        this.setState({ issuer: item.document })
        this.setState({ valor: item.valor })
        this.setState({ currentStatus: item.currentStatus })
        this.setState({ xml: item.xml })
        this.setState({ pdf_nfse: item.pdf_nfse })
        if (item.cep) {
            this.setState({ cep: item.cep })
            this.setState({ numero: item.numero })
            this.setState({ logradouro: item.logradouro })
            this.setState({ complemento: item.complemento })
            this.setState({ bairro: item.bairro })
            this.setState({ cidade: item.localidade })
            this.setState({ uf: item.uf }, () => {
                this.updateAddress()
            })
        }
        this.setState({ currentItem: item })
        this.setState({ isNoteEditOpen: true })
    }

    removeRegister = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover este registro?` })
        if (confirm) {
            const message = `Apagou um registro de nota com o status ${item.currentStatus}.`
            sendLogByUser("Nota fiscal", message, true, item)
            await deleteNewNoteById({ id: item.id })
            reloadWithAlert("Registro apagado com sucesso!")
        }
    }

    confirmEditedNewNote = async () => {
        const item = this.state.currentItem
        item.issuer = this.state.issuer
        if (this.state.issuer) {
            if (!isValidDocument(this.state.document)) {
                toastWarning("Informe um documento válido.")
                return
            }
            if (!this.state.name || this.state.name === "") {
                toastWarning("Informe o nome ou razão social.")
                return
            }
            if (isValidCNPJ(this.state.document) && (!this.state.cep || !this.state.numero)) {
                toastWarning("Informe o CEP e número do endereço.")
                return
            }
            item.document = this.state.document
            item.name = this.state.name
        } else {
            item.document = null
            item.name = null
        }
        toastLoading("Salvando...")
        this.setState({ isLoadingNewNote: true })
        item.currentStatus = this.state.currentStatus
        if (!item.status) {
            item.status = []
        }
        item.status.push({
            createdBy: getUsuarioCompact(),
            createdAt: new Date(),
            status: this.state.currentStatus
        })
        item.xml = this.state.xml
        item.pdf_nfse = this.state.pdf_nfse
        if (this.state.cep) {
            item.cep = this.state.cep
            item.numero = this.state.numero
            item.logradouro = valueByMaximunChars(this.state.logradouro, 20)
            item.complemento = this.state.complemento
            item.bairro = valueByMaximunChars(this.state.bairro, 20)
            item.cidade = valueByMaximunChars(this.state.cidade, 20)
            item.uf = this.state.uf
        }
        await updateNewNoteById({ id: item.id, data: item })
        if (this.state.currentStatus !== "NOTE_GENERATED") {
            const option = {
                method: 'POST',
                body: {
                    id: item.id
                }
            }
            await fetchFunction(`/newnote/process`, option)
        }
        toastDismissLoading()
        toastSuccess("Dados alterados com sucesso!")
        this.setState({ isNoteEditOpen: false })
        this.setState({ isLoadingNewNote: false })
        this.load()
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ uf: result.uf }, () => {
                this.updateAddress()
            })
        }
    }

    updateNumber = (e) => {
        this.setState({ numero: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateLogradouro = (e) => {
        this.setState({ logradouro: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateBairro = (e) => {
        this.setState({ bairro: e.target.value }, () => {
            this.updateAddress()
        })
    }

    updateAddress = () => {
        this.setState({ allAddress: `${this.state.logradouro}, ${this.state.numero} - ${this.state.bairro}, ${this.state.uf}` })
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Notas emitidas
                </div>
                {
                    this.state.items.length === 0 && !this.state.isLoading ?
                        <EmptyContent text='Nenhuma nota encontrada' />
                        :
                        !this.state.isLoading ?
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td width={10} align='center'><strong>Status</strong></td>
                                                    <td className="d-none d-sm-table-cell" align='left'><strong>Nome</strong></td>
                                                    <td align='center'><strong>Documento</strong></td>
                                                    <td className="d-none d-sm-table-cell"><strong>Data da solicitação</strong></td>
                                                    <td align='right'><strong>Valor</strong></td>
                                                    <td width='80' />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td width={10}>
                                                                <Tooltip title={item.id} placement="top">
                                                                    <span>
                                                                        {item.currentStatus === NOTE_PROCESSING && <h6><span className="badge text-bg-warning text-white w-100">Aguardando</span> </h6>}
                                                                        {item.currentStatus === NOTE_GENERATED && <h6><span className="badge text-bg-success text-white w-100">Nota gerada</span></h6>}
                                                                        {item.currentStatus === NOTE_CANCELED && <h6><span className="badge text-bg-danger text-white w-100">Cancelada</span></h6>}
                                                                        {item.currentStatus === NOTE_ERROR && <h6><span className="badge text-bg-danger text-white w-100">Erro</span></h6>}
                                                                        {item.currentStatus === NOTE_CONTIGENCY && <h6><span className="badge text-bg-danger text-white w-100">Contigência</span></h6>}
                                                                    </span>
                                                                </Tooltip>
                                                            </td>
                                                            <td align='left' className="d-none d-sm-table-cell">{item.name !== "" ? (item.name ?? "Não informado") : "Sem tomador"}</td>
                                                            <td align='center'>{item.name !== "" ? (documentMask(item.document) ?? "Não informado") : "-"}</td>
                                                            <td width={300} className="d-none d-sm-table-cell">
                                                                {
                                                                    item.createdAt && <span>
                                                                        {Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td align='right'><NumberFormat value={item.valor} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                            <td align='right'>
                                                                <div className='btn-group'>
                                                                    {
                                                                        item.currentStatus === NOTE_GENERATED && (item.newNote?.xml ?? item.xml) &&
                                                                        <Tooltip title="Baixar XML" placement="top">
                                                                            <a href={(item.newNote?.xml ?? item.xml)} download target='_blank' className="btn btn-secondary">
                                                                                <i className="fas fa-file-code" />
                                                                            </a>
                                                                        </Tooltip>
                                                                    }
                                                                    {
                                                                        item.currentStatus === NOTE_GENERATED && (item.newNote?.pdf_nfse ?? item.pdf_nfse) &&
                                                                        <Tooltip title="Visualizar PDF" placement="top">
                                                                            <a href={(item.newNote?.pdf_nfse ?? item.pdf_nfse)} target='_blank' className="btn btn-success">
                                                                                <i className="fas fa-file-pdf" />
                                                                            </a>
                                                                        </Tooltip>
                                                                    }
                                                                    {
                                                                        (item.currentStatus === NOTE_ERROR || item.currentStatus === NOTE_PROCESSING) &&
                                                                        <>
                                                                            <Tooltip title="Enviar novamente" placement="top">
                                                                                <button className="btn btn-success" onClick={(e) => this.showProcessNote(item)}>
                                                                                    <i className="far fa-paper-plane" />
                                                                                </button>
                                                                            </Tooltip>
                                                                            <Tooltip title="Apagar registro" placement="top">
                                                                                <button className="btn btn-danger" onClick={(e) => this.removeRegister(item)}>
                                                                                    <i className="fas fa-trash-alt" />
                                                                                </button>
                                                                            </Tooltip>
                                                                            <Tooltip title="Editar dados" placement="top">
                                                                                <button className="btn btn-warning text-white" onClick={(e) => this.editNote(item)}>
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </>
                                                                    }
                                                                    <Tooltip title="Visualizar status" placement="top">
                                                                        <button type="button" onClick={(e) => { this.showStatus(item) }} className="btn btn-secondary">
                                                                            <i className="fas fa-bars" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <ClientButton item={item} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Dialog ullWidth maxWidth={"xs"} open={this.state.isNoteProcessOpen}>
                                    {
                                        this.state.processNoteLoading ? <Loading /> :
                                            <>
                                                <MuiDialogTitle className='text-center'>
                                                    Emissão de nota
                                                </MuiDialogTitle>
                                                <MuiDialogContent>
                                                    <h5 className='text-center'>Deseja mesmo enviar este registro para que a prefeitura faça a emissão da nota?</h5>
                                                </MuiDialogContent>
                                                <MuiDialogActions>
                                                    <div className='btn-group m-3'>
                                                        <button onClick={(e) => this.setState({ isNoteProcessOpen: false })} className='btn btn-secondary'>Fechar</button>
                                                        <button onClick={() => this.confirmProcessNote()} className='btn btn-success'>Confirmar</button>
                                                    </div>
                                                </MuiDialogActions>
                                            </>
                                    }
                                </Dialog>

                                <Dialog onClose={this.hideLog} fullWidth maxWidth={"md"} open={this.state.isShowingLog}>
                                    <MuiDialogTitle className='text-center'>
                                        Log do evento
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        <textarea rows={10} disabled className='form-control'>{this.state.currentItemLog}</textarea>
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={this.hideLog} className='btn btn-secondary'>Fechar</button>
                                        </div>
                                    </MuiDialogActions>
                                </Dialog>
                                <Dialog onClose={(e) => this.setState({ isNoteEditOpen: false })} fullWidth maxWidth={"xs"} open={this.state.isNoteEditOpen}>
                                    {
                                        this.state.isLoadingNewNote && <Loading />
                                    }
                                    {
                                        !this.state.isLoadingNewNote && <>
                                            <MuiDialogTitle className='text-center'>
                                                Editar dados
                                            </MuiDialogTitle>
                                            <MuiDialogContent>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarRetiradaDeCaixaAcima" checked={!this.state.issuer} onChange={(e) => this.setState({ issuer: !e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarRetiradaDeCaixaAcima">
                                                        Não informar os dados do cliente
                                                    </label>
                                                </div>
                                                {
                                                    this.state.issuer && <>
                                                        <div className="col-lg-12">
                                                            <label>Nome</label>
                                                            <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>Documento</label>
                                                            <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" />
                                                        </div>
                                                    </>
                                                }
                                                <label>Status</label>
                                                <select className="form-select" value={this.state.currentStatus} onChange={(e) => this.setState({ currentStatus: e.target.value })}>
                                                    <option value="">Selecionar</option>
                                                    <option value="NOTE_GENERATED">Nota gerada</option>
                                                    <option value="NOTE_PROCESSING">Processando</option>
                                                    <option value="NOTE_SENT">Na prefeitura</option>
                                                    <option value="NOTE_CANCELED">Cancelada</option>
                                                    <option value="NOTE_CONTIGENCY">Contigência</option>
                                                    <option value="NOTE_ERROR">Erro</option>
                                                </select>
                                                {
                                                    this.state.currentStatus === "NOTE_GENERATED" && <div className='col-lg-12'>
                                                        <label>XML</label>
                                                        <input type="text" value={this.state.xml} onChange={(e) => { this.setState({ xml: e.target.value }) }} className="form-control" />
                                                        <label>PDF</label>
                                                        <input type="text" value={this.state.pdf_nfse} onChange={(e) => { this.setState({ pdf_nfse: e.target.value }) }} className="form-control" />
                                                    </div>
                                                }
                                                {
                                                    this.state.issuer && <>
                                                        <div className='alert alert-warning mt-3'>
                                                            <div className='row'>
                                                                <div className="col-lg-8">
                                                                    <label>CEP</label>
                                                                    <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <label>Número</label>
                                                                    <input type="text" onChange={(e) => this.updateNumber(e)} value={this.state.numero} className="form-control" />
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <label>Logradouro</label>
                                                                    <input type="text" onChange={(e) => this.updateLogradouro(e)} value={this.state.logradouro} className="form-control" />
                                                                </div>


                                                                <div className="col-lg-6">
                                                                    <label>Bairro</label>
                                                                    <input type="text" onChange={(e) => this.updateBairro(e)} value={this.state.bairro} className="form-control" />
                                                                </div>

                                                            </div>
                                                            <div className="col-lg-12 mt-2">
                                                                <h6>{this.state.allAddress}</h6>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-lg-12">
                                                    <label>Valor</label>
                                                    <h4><NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></h4>
                                                </div>
                                                {
                                                    this.state.currentStatus !== NOTE_GENERATED &&
                                                    <AlertDanger message="Ao confirmar a edição o sistema automaticamente tentará gerar a nota." />
                                                }
                                            </MuiDialogContent>
                                            <MuiDialogActions>
                                                <div className='btn-group m-3'>
                                                    <button onClick={(e) => this.setState({ isNoteEditOpen: false })} className='btn btn-secondary'>Fechar</button>
                                                    <button onClick={() => this.confirmEditedNewNote()} className='btn btn-success'>Confirmar</button>
                                                </div>
                                            </MuiDialogActions>
                                        </>
                                    }
                                </Dialog>
                                <Dialog onClose={this.hideStatus} maxWidth={"sm"} fullWidth open={this.state.isShowingStatus}>
                                    <MuiDialogTitle className='text-center'>
                                        Todos os status
                                    </MuiDialogTitle>
                                    <MuiDialogContent>
                                        {
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <table className="table table-striped">
                                                        <tr>
                                                            <th width={10}></th>
                                                            <th align='center'>Data e Hora</th>
                                                            <th>Operador</th>
                                                            <th width={10}></th>
                                                        </tr>
                                                        <tbody>
                                                            {this.state.currentItem?.status.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td width={10}>
                                                                        {item.status === NOTE_PROCESSING && <h6><span className="badge text-bg-warning text-white w-100">Aguardando</span> </h6>}
                                                                        {item.status === NOTE_GENERATED && <h6><span className="badge text-bg-success w-100">Nota gerada</span></h6>}
                                                                        {item.status === NOTE_CANCELED && <h6><span className="badge text-bg-danger w-100">Cancelada</span></h6>}
                                                                        {item.status === NOTE_ERROR && <h6><span className="badge text-bg-danger w-100">Erro</span></h6>}
                                                                        {item.status === NOTE_CONTIGENCY && <h6><span className="badge text-bg-danger w-100">Contigência</span></h6>}
                                                                    </td>
                                                                    <td align='center'>
                                                                        {Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY HH:mm")}
                                                                    </td>
                                                                    <td>
                                                                        {item.createdBy?.nome}
                                                                    </td>
                                                                    <td width={10}>
                                                                        {
                                                                            item.log &&
                                                                            <Tooltip title="Visualizar log" placement="top">
                                                                                <button className="btn btn-secondary" onClick={(e) => this.showLog(item)}>
                                                                                    <i className="fas fa-code" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                    </MuiDialogContent>
                                    <MuiDialogActions>
                                        <div className='btn-group m-3'>
                                            <button onClick={this.hideStatus} className='btn btn-secondary'>Fechar</button>
                                        </div>
                                    </MuiDialogActions>
                                </Dialog>
                            </div>
                            :
                            <Loading />
                }
            </div>
        )
    }
}
export { ListarNotasRevendas }