import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { hoursToMin, isNullOrEmpty, isValidatePlate, minutesFromNow, onlyNumbers, secondsToDescription, ticketPermanenceFromNowDescription, toastSuccess, toastWarning } from '../../shared/utils';
import { getCliente, getEstacionamento, getUsuario, getUsuarioCompact, isAdministrador } from '../../config/auth';
import { getAllRelationshipsByParkId } from '../../store/collections/relationshipWorker';
import { getTicketsOpenByPlateAndClienteId, getTicketOpenByNumberAndClienteId, updateTicket } from '../../store/collections/ticketWorker';
import { Loading } from '../../components/common/commons';
import moment from 'moment';
import { sendLogByUser } from '../../shared/analytics';
import { AlertSuccess, AlertWarning } from '../../shared/alert-manager';

class OperationRelationshipModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clienteId: getCliente()?.id,
            estacionamentoId: getEstacionamento()?.id,
            relationshipSelected: '',
            relationship: null,
            relationships: [],
            hasRelationship: true,
            isLoading: true,
            isAllowed: true,
            value: ""
        }
        this._loadRelationships()
    }

    _loadRelationships = async () => {
        var list = await getAllRelationshipsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ hasRelationship: list.length > 0 })
        if (!isAdministrador()) {
            const user = getUsuario()
            if (user.relationships?.length > 0) {
                var items = []
                for (const relationship of user.relationships) {
                    const relationshipsFound = list.filter(e => e.id === relationship.value)
                    if (relationshipsFound.length > 0) {
                        items.push(relationshipsFound[0])
                    }
                }
                if (items.length === 0) {
                    toastWarning("Você não tem nenhum convênio associado a este estabelecimento.")
                    return
                }
                this.setState({ relationships: items }, () => {
                    // if (items.length === 1) {
                    //     this.calculateRelationship(items[0].id)
                    // }
                })
            } else {
                this.setState({ relationships: list }, () => {
                    // if (list.length === 1) {
                    //     this.calculateRelationship(list[0].id)
                    // }
                })
            }
        } else {
            this.setState({ relationships: list }, () => {
                // if (list.length === 1) {
                //     this.calculateRelationship(list[0].id)
                // }
            })
        }
        this.setState({ isLoading: false })
    }

    onConfirm = async () => {
        if (this.state.relationshipSelected === "") {
            toastWarning("Selecione um convênio.")
            return
        }
        this.setState({ isLoading: true })
        const ticket = this.state.ticket
        var relationship = this.state.relationship
        relationship.appliedBy = getUsuarioCompact()
        relationship.appliedAt = new Date()
        ticket.relationshipApplied = relationship
        await updateTicket(ticket.id, ticket)
        sendLogByUser("Operação", `Convênio ${this.state.relationship.nome} aplicado com sucesso no ticket: ${ticket.ticket}`, ticket)
        toastSuccess("Convênio aplicado com sucesso")
        this.setState({ value: "" })
        this.setState({ ticket: null })
        this.setState({ isLoading: false })
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    onChangeValue = async (e) => {
        const value = e.target.value
        this.setState({ value: value })
        this.setState({ ticket: null })
        const isPlate = isValidatePlate(value)
        if (isPlate) {
            this.setState({ isLoading: true })
            const ticket = await getTicketsOpenByPlateAndClienteId({ plate: value, clienteId: this.state.clienteId })
            this.selectTicket(ticket)
        } else if (onlyNumbers(value).length >= 6) {
            this.setState({ isLoading: true })
            const ticket = await getTicketOpenByNumberAndClienteId({ number: value, clienteId: this.state.clienteId })
            this.selectTicket(ticket)
        }
        this.setState({ isLoading: false })
    }

    selectTicket = (ticket) => {
        if (!ticket) {
            toastWarning("Nenhum ticket encontrado no pátio.")
        } else if (ticket?.status === "Pago e Entregue") {
            toastWarning("Este ticket já foi pago.")
        } else if (ticket?.status === "Pago e Estacionado" || ticket.status === "Pago e Aguardando") {
            toastWarning("Só é possível adicionar convênio em tickets nunca pagos.")
        } else if (ticket?.status === "Cancelado") {
            toastWarning("Este ticket esta cancelado.")
        } else if (ticket?.relationshipApplied) {
            this.setState({ message: "Este ticket já esta com um convênio aplicado, mas você pode alterar." })
            this.setState({ ticket: ticket })
            this.setState({ relationship: ticket.relationshipApplied })
            this.setState({ relationshipSelected: ticket.relationshipApplied?.id })
        } else {
            if (ticket.monthly || ticket.accredited) {
                this.setState({ message: "Não é possível adicionar convênio em um ticket de mensalista ou credenciado." })
            } else {
                this.setState({ ticket: ticket })
            }
        }
    }

    statusClassName = () => {
        switch (this.state.ticket.status) {
            case 'Cancelado': return "badge text-bg-danger btn-block w-100"
            case 'Estacionado': return "badge text-bg-primary btn-block w-100"
            case 'Pago e Estacionado': return "badge text-bg-info btn-block w-100"
            case 'Pago e Entregue': return "badge text-bg-success btn-block w-100"
            case 'Pago e Aguardando': return "badge text-bg-warning btn-block w-100"
        }
    }

    colorCard = () => {
        switch (this.state.ticket.status) {
            case 'Cancelado': return "danger"
            case 'Estacionado': return "primary"
            case 'Pago e Estacionado': return "info"
            case 'Pago e Entregue': return "success"
            case 'Pago e Aguardando': return "warning"
        }
    }

    calculateRelationship = (relationshipId) => {
        var relationship = this.state.relationships.filter(e => e.id === relationshipId)[0]
        this.setState({ relationshipSelected: relationshipId })
        if (relationship) {
            this.setState({ relationship: relationship })
            if (relationship.manterDesconto === "Não") {
                const minutesPermanence = minutesFromNow(this.state.ticket.createAt)
                const minutesTolerance = hoursToMin(relationship.tolerancia)
                if (minutesPermanence > minutesTolerance) {
                    this.setState({ isAllowed: false })
                    this.setState({ message: `A tolerância máxima deste convênio é de ${relationship.tolerancia} este ticket já esta no pátio há ${secondsToDescription(minutesPermanence * 60)}.` })
                    return
                }
            }
        } else {
            this.setState({ relationship: null })
        }
        this.setState({ isAllowed: true })
        this.setState({ message: null })
    }

    onChangeRelationship = async (e) => {
        const value = e.target.value
        this.calculateRelationship(value)
    }

    render() {
        return (
            this.state.isLoading ? <Loading /> :
                <>
                    <MuiDialogTitle className='text-center'>
                        Aplicar convênio
                    </MuiDialogTitle>
                    {
                        this.state.hasRelationship ? <>
                            <MuiDialogContent>
                                <div className="row">
                                    <AlertSuccess tag="Informação" message="Você pode aplicar um convênio a qualquer ticket que tenha sido lançado no pátio." />
                                    {
                                        this.state.message &&
                                        <div className='col-lg-12'>
                                            <AlertWarning message={this.state.message} />
                                        </div>
                                    }
                                    <div className='col-lg-12'>
                                        <label>Número do ticket ou placa</label>
                                        <input type="text" value={this.state.value} onChange={this.onChangeValue} className="form-control form-control-lg" />
                                    </div>
                                    {
                                        this.state.ticket && <>
                                            <div className="col-lg-12 mt-3">
                                                <div className={`alert alert-${this.colorCard()}`}>
                                                    <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                                        <h6><span className={`badge text-bg-${this.colorCard()}`}>{this.state.ticket.status}</span></h6>
                                                        <div><strong>Ticket:</strong> {this.state.ticket.ticket}</div>
                                                        {
                                                            !isNullOrEmpty(this.state.ticket.placa) &&
                                                            <div><strong>Placa:</strong> {this.state.ticket.placa}</div>
                                                        }
                                                        {
                                                            !isNullOrEmpty(this.state.ticket.vehicler?.modelo) &&
                                                            <div><strong>Modelo:</strong> {this.state.ticket.vehicler?.modelo}</div>
                                                        }
                                                        {
                                                            !isNullOrEmpty(this.state.ticket.setor) &&
                                                            <div><strong>Setor:</strong> {this.state.ticket.setor}</div>
                                                        }
                                                        <div><strong>Entrada:</strong> {moment(this.state.ticket.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</div>
                                                        <div><strong>Permanência:</strong> {ticketPermanenceFromNowDescription(this.state.ticket)}</div>
                                                    </div>
                                                    <div className='col-lg-12 mt-2'>
                                                        <select className="form-select" value={this.state.relationshipSelected} onChange={this.onChangeRelationship}>
                                                            <option value="">Selecionar</option>
                                                            {
                                                                this.state.relationships.map(e => <option value={e.id}>{e.nome}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                    {
                                                        this.state.relationship &&
                                                        <div className='mt-2'>
                                                            {
                                                                this.state.relationship.tipo === "Porcentagem" ?
                                                                    <div><strong>Porcentagem de desconto:</strong> {this.state.relationship.porcentagem}%</div> :
                                                                    <div><strong>Valor de desconto:</strong> R${this.state.relationship.valor}</div>
                                                            }
                                                            <div><strong>Tolerância máxima:</strong> {this.state.relationship.tolerancia}</div>
                                                            <div><strong>Manter desconto pós tolerância:</strong> {this.state.relationship.manterDesconto}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className="btn-group m-3">
                                    <button onClick={(e) => this.onClose()} className='btn btn-secondary'>Fechar</button>
                                    {
                                        this.state.relationship && this.state.isAllowed &&
                                        <button onClick={(e) => this.onConfirm()} className='btn btn-success'>Confirmar</button>
                                    }
                                </div>
                            </MuiDialogActions>
                        </> :
                            <>
                                <MuiDialogContent>
                                    <div className='alert alert-warning'>
                                        <span className="badge text-bg-warning text-white">Novidade</span> <br />
                                        Agora é possível aplicar convênios de forma online a um ticket direto no administrativo. <br />
                                        <a href='/cadastrar/convenio' className='btn btn-warning text-white mt-2'>Cadastrar convênios</a>
                                    </div>
                                </MuiDialogContent>
                            </>
                    }
                </>
        )
    }
}

export { OperationRelationshipModal }