
import React from 'react'
import 'firebase/auth';
import 'firebase/storage';
import Moment from 'moment';
import Chart from "react-apexcharts";
import moment from 'moment';
import { minutesFromNow, minutesToHoursDescription, secondsToDescription } from '../../shared/utils';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import NumberFormat from 'react-number-format';
import { TotalsConvenios, TotalsCredenciados, TotalsPermanenciaMedia, TotalsTicketsCanceled, TotalsValorCobrado } from '../buscas/totalsSearch';

class HeatMapWeakTimesStartTickets extends React.Component {
    constructor(props) {
        super(props);
        var series = []
        var weaks = [1, 2, 3, 4, 5, 6, 7]
        var weakAdded = []
        var times = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]
        for (const time of times) {
            var data = []
            for (const weak of weaks) {
                const totalFromWeeak = props.tickets.filter(e => Moment(e.createAt.toDate()).isoWeekday() === weak).length
                const weakTimes = props.tickets.filter(e => Moment(e.createAt.toDate()).isoWeekday() === weak && Moment(e.createAt.toDate()).format("HH:[00]") === time)
                if (weakTimes.length > 0) {
                    const name = this.getNameOfWeak(weak)
                    data.push({
                        x: name,
                        y: weakTimes.length,
                        total: totalFromWeeak
                    })
                    weakAdded.push(weak)
                }
            }
            if (data.length > 0) {
                series.push({
                    name: time,
                    data: data
                })
            }
        }
        const itemsWeak = Array.from(new Set(weakAdded)).sort((a, b) => a - b)
        var options = {
            series: series,
            options: {
                chart: {
                    height: 30 * series.length,
                    type: 'heatmap',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    y: {
                        formatter: function (value, opts) {
                            return `Veículos lançados: ${value}`
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (value, opts) {
                        const totalInWeek = series[opts.seriesIndex].data[opts.dataPointIndex].total
                        if (parseInt(value) > 0) {
                            const percent = `${(parseInt(value) / totalInWeek * 100).toFixed(2)}%`
                            return `${value} - ${percent}`
                        }
                        return ""
                    },
                    style: {
                        colors: ["#000"]
                    }
                },
                xaxis: {
                    categories: itemsWeak.map(e => this.getNameOfWeak(e)),
                },
                colors: ["#0D6EFD"],
                title: {
                    text: 'Veículos que entraram no pátio'
                },
                legend: {
                    show: true
                }
            }
        };
        this.state = options
    }

    getNameOfWeak = (number) => {
        switch (number) {
            case 1: return "Seg.";
            case 2: return "Ter.";
            case 3: return "Qua.";
            case 4: return "Qui.";
            case 5: return "Sex.";
            case 6: return "Sab.";
            case 7: return "Dom.";
        }
    }

    render() {
        return (
            <div className='col-lg-6'>
                <Chart options={this.state.options} series={this.state.series} type="heatmap" />
            </div>
        );
    }
}
class HeatMapWeakTimesEndTickets extends React.Component {
    constructor(props) {
        super(props);
        var series = []
        var weaks = [1, 2, 3, 4, 5, 6, 7]
        var weakAdded = []
        var times = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]
        for (const time of times) {
            var data = []
            for (const weak of weaks) {
                const totalFromWeeak = props.tickets.filter(e => e.closedAt && Moment(e.closedAt.toDate()).isoWeekday() === weak).length
                const weakTimes = props.tickets.filter(e => e.closedAt && Moment(e.closedAt.toDate()).isoWeekday() === weak && Moment(e.closedAt.toDate()).format("HH:[00]") === time)
                if (weakTimes.length > 0) {
                    const name = this.getNameOfWeak(weak)
                    data.push({
                        x: name,
                        y: weakTimes.length,
                        total: totalFromWeeak
                    })
                    weakAdded.push(weak)
                }
            }
            if (data.length > 0) {
                series.push({
                    name: time,
                    data: data
                })
            }
        }
        const itemsWeak = Array.from(new Set(weakAdded)).sort((a, b) => a - b)
        var options = {
            series: series,
            options: {
                chart: {
                    height: 30 * series.length,
                    type: 'heatmap',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    y: {
                        formatter: function (value, opts) {
                            const totalInWeek = series[opts.seriesIndex].data[opts.dataPointIndex].total
                            return `Veículos lançados: ${value}`
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (value, opts) {
                        const totalInWeek = series[opts.seriesIndex].data[opts.dataPointIndex].total
                        if (parseInt(value) > 0) {
                            const percent = `${(parseInt(value) / totalInWeek * 100).toFixed(2)}%`
                            return `${value} - ${percent}`
                        }
                        return ""
                    },
                    style: {
                        colors: ["#000"]
                    }
                },
                xaxis: {
                    categories: itemsWeak.map(e => this.getNameOfWeak(e)),
                },
                title: {
                    text: 'Veículos que sairam do pátio'
                },
                colors: ["#FF0000"],
                legend: {
                    show: true
                }
            }
        };
        this.state = options
    }

    getNameOfWeak = (number) => {
        switch (number) {
            case 1: return "Seg.";
            case 2: return "Ter.";
            case 3: return "Qua.";
            case 4: return "Qui.";
            case 5: return "Sex.";
            case 6: return "Sab.";
            case 7: return "Dom.";
        }
    }

    render() {
        return (
            <div className='col-lg-6'>
                <Chart options={this.state.options} series={this.state.series} type="heatmap" />
            </div>
        );
    }
}

class TreeMapSectorsCharts extends React.Component {
    constructor(props) {
        super(props);
        const sectores = Array.from(new Set(props.tickets.filter(e => e.setorId).map(e => e.setorId))).sort((a, b) => a - b)
        const data = sectores.map(e => props.tickets.filter(f => f.setorId === e).length)
        const titles = sectores.map(sectorId => {
            const value = props.tickets.filter(e => e.setorId === sectorId)[0]?.setor ?? ""
            const fromSector = props.tickets.filter(e => e.setorId === sectorId).length
            const percent = `${(parseInt(fromSector) / props.tickets.filter(e => e.setorId).length * 100).toFixed(2)}%`
            return `${fromSector} - ${percent} - ${value}`
        })
        this.state = {
            hasSectors: sectores.length > 0,
            series: data,
            options: {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'Setores acessados'
                },
                labels: titles,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 380
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        };
    }

    render() {
        return (
            <>
                {
                    this.state.hasSectors &&
                    <div className='col-lg-6'>
                        <Chart options={this.state.options} series={this.state.series} type="pie" height={350} />
                    </div>
                }
            </>
        );
    }
}

class TreeMapAccreditedsCharts extends React.Component {
    constructor(props) {
        super(props);
        const accreditedsTypes = props.tickets.filter(e => e.accredited?.nome).map(e => e.accredited?.tipos).flatMap(e => e)
        const types = Array.from(new Set(accreditedsTypes)).sort((a, b) => a - b)
        const data = types.map(e => accreditedsTypes.filter(f => f === e).length)
        const titles = types.map(value => {
            const from = accreditedsTypes.filter(f => f === value).length
            const percent = `${(parseInt(from) / accreditedsTypes.length * 100).toFixed(2)}%`
            return `${from} - ${percent} - ${value}`
        })
        this.state = {
            hasItems: types.length > 0,
            series: data,
            options: {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: 'Tipos de credenciados'
                },
                labels: titles,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 380
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        };
    }

    render() {
        return (
            <>
                {
                    this.state.hasItems &&
                    <div className='col-lg-6'>
                        <Chart options={this.state.options} series={this.state.series} type="pie" height={350} />
                    </div>
                }
            </>
        );
    }
}

class TreeMapPaymentTableCharts extends React.Component {
    constructor(props) {
        super(props);
        const paymentTables = props.tickets.filter(e => e.status !== "Cancelado").flatMap(e => e.paymentsLogged).filter(e => e).filter(e => e.canceled !== true).filter(e => e.resumo?.tabelaPreco)
        const tables = Array.from(new Set(paymentTables.map(e => e.resumo?.tabelaPreco?.id)))
        const names = tables.map(e => paymentTables.filter(f => f.resumo?.tabelaPreco?.id === e)[0]?.resumo?.tabelaPreco.nome ?? "")
        const quantities = tables.map(e => paymentTables.filter(f => f.resumo?.tabelaPreco?.id === e).length)
        var colors = ["#8de697", "#415577", "#54d1df", "#dcd587", "#6b8f78", "#09940e", "#9e6a6d", "#bbda18", "#4eed8f", "#6b1488", "#e9cacd"]
        this.state = {
            hasItems: quantities.length > 0,
            options: {
                series: [{
                    name: 'Vezes usada',
                    data: quantities
                }],
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark'
                },
                colors: colors,
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                },
                title: {
                    text: 'Tabelas de preço utilizadas'
                },
                dataLabels: {
                    dataLabels: {
                        enabled: true
                    },
                    formatter: function (value) {
                        return value
                    },
                    style: {
                        colors: ["#000"],
                        fontWeight: 'bold'
                    }
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: names,
                    labels: {
                        style: {
                            colors: colors,
                            fontSize: '12px',
                            fontFace: 'bold'
                        }
                    }
                }
            }
        };
    }

    render() {
        return (
            <>
                {
                    this.state.hasItems &&
                    <div className='col-lg-6'>
                        <Chart options={this.state.options} series={this.state.options.series} type="bar" height={350} />
                    </div>
                }
            </>
        );
    }
}

class DistributedColumnsOperatorsCharts extends React.Component {
    constructor(props) {
        super(props);
        const paymentTables = props.tickets.filter(e => e.status !== "Cancelado").flatMap(e => e.paymentsLogged).filter(e => e).filter(e => e.canceled !== true).filter(e => e.user)
        const names = Array.from(new Set(paymentTables.map(e => e.user.nome))).sort((a, b) => a - b)
        const data = names.map(nome => {
            return paymentTables.filter(e => e.user.nome === nome).map(f => f.resumo?.valorCobrado ?? 0).reduce((a, b) => a + b, 0).toFixed(2)
        })
        var colors = ["#8de697", "#415577", "#54d1df", "#dcd587", "#6b8f78", "#09940e", "#9e6a6d", "#bbda18", "#4eed8f", "#6b1488", "#e9cacd"]
        this.state = {
            options: {
                series: [{
                    name: 'Valor',
                    data: data
                }],
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark'
                },
                colors: colors,
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true,
                    }
                },
                title: {
                    text: 'Pagamentos por operador'
                },
                dataLabels: {
                    dataLabels: {
                        enabled: true
                    },
                    formatter: function (value) {
                        return `R$${parseFloat(value).toFixed(2)}`
                    },
                    style: {
                        colors: ["#000"],
                        fontWeight: 'bold'
                    }
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: names,
                    labels: {
                        style: {
                            colors: colors,
                            fontSize: '12px',
                            fontFace: 'bold'
                        }
                    }
                }
            }
        };
    }

    render() {
        return (
            <div className='col-lg-6'>
                <Chart options={this.state.options} series={this.state.options.series} type="bar" height={350} />
            </div>
        );
    }
}

class StackedColumnsTicketAccessCharts extends React.Component {
    constructor(props) {
        super(props);
        const entradas = props.tickets.map(e => moment(e.createAt.seconds * 1000).format("H[h]"))
        const saidas = props.tickets.filter(e => e.closedAt).map(e => moment(e.closedAt.seconds * 1000).format("H[h]"))
        var seriesEntradas = []
        var seriesSaidas = []
        const categories = Array(24).fill(1).map((_, i) => { return `${i * 1}h` })
        for (const categorie of categories) {
            seriesEntradas.push(entradas.filter(e => e === categorie).length)
            seriesSaidas.push(saidas.filter(e => e === categorie).length)
        }
        this.state = {
            series: [{
                name: 'Entradas',
                data: seriesEntradas
            }, {
                name: 'Saídas',
                data: seriesSaidas
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                title: {
                    text: 'Movimentações de veículos'
                },
                colors: ["#0D6EFD", "#FF0000"],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                },
                xaxis: {
                    categories: categories,
                },
                legend: {
                    position: 'bottom'
                },
                fill: {
                    opacity: 1
                }
            },
        };
    }

    render() {
        return (
            <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
        );
    }
}

class AverageTimeTicketAccessCharts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            random: Math.random(),
            period: 12,
            aggruped: 60,
            tickets: props.tickets
        }
        this.loadCharts()
    }

    loadCharts = () => {
        const tickets = this.state.tickets.filter(e => e.closedAt)
        var allTickets = []
        const times = Array(parseInt(this.state.period)).fill(1).map((_, i) => { return (i + 1) * parseFloat(this.state.aggruped) })
        times.forEach((time, i) => {
            const quantity = tickets.filter(e => {
                const minutes = minutesFromNow(e.createAt, e.closedAt)
                return (minutes >= (times[i - 1] ?? 0)) && minutes <= time
            })
            allTickets.push({
                time: time,
                quantity: quantity,
                percent: ((quantity.length / tickets.length) * 100).toFixed(2)
            })
        })
        this.setState({
            series: [{
                name: 'Acessos',
                data: allTickets.map(e => e.quantity.length)
            }]
        })
        this.setState({
            series: [{
                name: 'Acessos',
                data: allTickets.map(e => e.quantity.length)
            }]
        })
        const showSelectedTickets = this.showSelectedTickets
        this.state = {
            random: Math.random(),
            period: this.state.period,
            aggruped: this.state.aggruped,
            tickets: this.state.tickets,
            ticketsSelecteds: [],
            series: [{
                name: 'Acessos',
                data: allTickets.map(e => e.quantity.length)
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                    events: {
                        click: function (event, chartContext, opts) {
                            if (opts.dataPointIndex !== -1) {
                                console.log(opts);
                                const title = opts.config.xaxis.categories[opts.dataPointIndex]
                                const tickets = allTickets[opts.dataPointIndex]?.quantity
                                showSelectedTickets(tickets, title)
                            }
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: allTickets.map(e => {
                        const time = minutesToHoursDescription(e.time)
                        if ((e.time / 60) >= 12) {
                            return `Acima de ${time}`
                        } else {
                            return `Até ${time}`
                        }
                    }),
                },
                title: {
                    text: 'Veículos por tempo de permanência'
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " veículos"
                        }
                    }
                },
                colors: ["#0D6EFD"],
                legend: {
                    position: 'bottom'
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                style: {
                                    fontSize: '13px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },
                }
            },
        }
    }

    changePeriod = (e) => {
        this.setState({ period: e.target.value }, () => {
            this.loadCharts()
        })
    }

    changeAggruped = (e) => {
        this.setState({ aggruped: e.target.value }, () => {
            this.loadCharts()
        })
    }

    showSelectedTickets = (tickets, title) => {
        this.setState({ ticketsSelecteds: tickets })
        this.setState({ currentTitle: `${tickets.length} registros com permanência ${title.toLowerCase()}` })
        this.setState({ isShowTicketFromPeriodModal: true })
    }

    statusTicket = (item) => {
        if (item.status === "Pago e Estacionado") {
            return "btn btn-info w-100"
        }
        if (item.status === "Pago e Entregue") {
            return "btn btn-success w-100"
        }
        if (item.status === "Pago e Aguardando") {
            return "btn btn-warning text-white w-100"
        }
        if (item.status === "Cancelado") {
            return "btn btn-danger w-100"
        }
        return "btn btn-primary w-100"
    }

    tiposTicket = (type) => {
        if (type === "SALES") {
            return "Venda avulsa"
        }
        if (type === "MONTHLY") {
            return "Mensalista"
        }
        if (type == "ACCREDITED") {
            return "Credenciado"
        }
        return "Avulso"
    }

    render() {
        return (
            <>
                <div className='row mt-3'>
                    {/* <div className='col-3'>
                        <label>Períodos</label>
                        <select className='form-select' value={this.state.period} onChange={this.changePeriod}>
                            <option value="6">6 horas</option>
                            <option value="12">12 horas</option>
                            <option value="24">24 horas</option>
                            <option value="36">36 horas</option>
                            <option value="48">48 horas</option>
                            <option value="60">60 horas</option>
                            <option value="72">72 horas</option>
                            <option value="84">84 horas</option>
                        </select>
                    </div>
                    <div className='col-3'>
                        <label>Agrupador</label>
                        <select className='form-select' value={this.state.aggruped} onChange={this.changeAggruped}>
                            <option value="60">1 hora</option>
                            <option value="120">2 horas</option>
                            <option value="180">3 horas</option>
                            <option value="240">4 horas</option>
                            <option value="300">5 horas</option>
                            <option value="360">6 horas</option>
                            <option value="420">7 horas</option>
                            <option value="480">8 horas</option>
                            <option value="540">8 horas</option>
                            <option value="600">10 horas</option>
                            <option value="660">11 horas</option>
                            <option value="720">12 horas</option>
                        </select>
                    </div> */}
                    <Chart options={this.state.options} key={this.state.random} series={this.state.series} type="bar" height={350} />
                </div>

                <Dialog onClose={(e) => this.setState({ isShowTicketFromPeriodModal: false })} fullWidth={true} maxWidth={"lg"} open={this.state.isShowTicketFromPeriodModal}>
                    <MuiDialogTitle className='text-center'>
                        {this.state.currentTitle}
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='col-lg-12'>
                            <div className='table-responsive my-2'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td width={170}></td>
                                            <td align='center'><strong>Ticket</strong></td>
                                            <td align='center'><strong>Placa</strong></td>
                                            <td align='center'><strong>Entrada</strong></td>
                                            <td align='center'><strong>Saída</strong></td>
                                            <td align='center'><strong>Permanência</strong></td>
                                            <td align='center'><strong>Valor cobrado</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.ticketsSelecteds.map((item, index) =>
                                                <tr key={index}>
                                                    <td>
                                                        <a href={'/buscar/ticket?id=' + item.id} target="_blank">
                                                            <button type="button" className={this.statusTicket(item)}>
                                                                <i className="fas fa-eye" />
                                                                {
                                                                    <span className='mx-2'>{this.tiposTicket(item.internalType)}</span>
                                                                }
                                                            </button>
                                                        </a>
                                                    </td>
                                                    <td align='center'>
                                                        <Tooltip title="Clique para buscar este ticket" placement="top">
                                                            <a href={'/buscar/ticket?id=' + item.id} target="_blank">
                                                                <span>{item.ticket}</span>
                                                                <i className="fas fa-eye mx-2" />
                                                            </a>
                                                        </Tooltip>
                                                    </td>
                                                    <td align='center'>
                                                        {
                                                            item.placa?.toUpperCase() !== '' ?
                                                                <Tooltip title="Clique para buscar esta placa" placement="top">
                                                                    <a href={'/buscar/ticket?placa=' + item.placa?.toUpperCase()} target="_blank">
                                                                        {item.placa?.toUpperCase() ?? "-"}
                                                                        <i className="fas fa-eye mx-2" />
                                                                    </a>
                                                                </Tooltip> : <span>-</span>
                                                        }
                                                    </td>
                                                    <td align='center'>
                                                        {Moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                    </td>
                                                    {
                                                        item.closedAt ?
                                                            <td align='center'>
                                                                {Moment(item.closedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                            </td>
                                                            :
                                                            <td align='center'>
                                                                -
                                                            </td>
                                                    }
                                                    {
                                                        item.closedAt ?
                                                            <td align='center'>
                                                                {secondsToDescription(((item.closedAt.seconds - item.createAt.seconds)))}
                                                            </td>
                                                            : <td align='center'>-</td>
                                                    }
                                                    {
                                                        item.paymentsLogged ?
                                                            <td align='center'>
                                                                {
                                                                    item.paymentsLogged.filter(e => e.canceled !== true).length === 1 ? <>
                                                                        {
                                                                            item.canceled !== true ?
                                                                                <strong className='text-success'>
                                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.paymentsLogged.filter(e => e.canceled !== true).map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                </strong> :
                                                                                <strong className='text-danger text-decoration-line-through'>
                                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.paymentsLogged.filter(e => e.canceled !== true).map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                </strong>
                                                                        }
                                                                    </> :
                                                                        <Tooltip title={`A soma de todos os ${item.paymentsLogged.filter(e => e.canceled !== true).length} pagamentos`} placement="top">
                                                                            <strong className='text-success'>
                                                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.paymentsLogged.filter(e => e.canceled !== true).map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                            </strong>
                                                                        </Tooltip>
                                                                }
                                                            </td>
                                                            : <td align='center'>-</td>
                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className='row'>
                                <TotalsValorCobrado tickets={this.state.ticketsSelecteds} />
                                <TotalsTicketsCanceled tickets={this.state.ticketsSelecteds} />
                                <TotalsPermanenciaMedia tickets={this.state.ticketsSelecteds} />
                                <TotalsConvenios tickets={this.state.ticketsSelecteds} />
                                <TotalsCredenciados tickets={this.state.ticketsSelecteds} />
                            </div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowTicketFromPeriodModal: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        );
    }
}

export {
    HeatMapWeakTimesStartTickets,
    HeatMapWeakTimesEndTickets,
    TreeMapSectorsCharts,
    TreeMapPaymentTableCharts,
    DistributedColumnsOperatorsCharts,
    TreeMapAccreditedsCharts,
    StackedColumnsTicketAccessCharts,
    AverageTimeTicketAccessCharts
}