import React from 'react'
import { NavBar } from '../../components/navbar'
import firebase from '../../config/firebase';
import NumberFormat from 'react-number-format';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isTermsUpdated } from '../../config/auth'
import { Loading, EmptyContent, HelpButton, DateRangePickerDialog } from '../../components/common/commons'
import { sendClickButton, sendLogByUser } from '../../shared/analytics'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Moment from 'moment';
import { getAllCredentialsByParkId, addCredential, updateCredential, deleteCredentialById } from '../../store/collections/credenciadoWorker';
import { searchCEP } from '../../shared/cepWorker';
import Tooltip from '@mui/material/Tooltip';
import { getAllSectoresByClientId, getAllSectoresByParkId } from '../../store/collections/setoresWorker'
import { getAccreditedTypeByName, getAllAccreditedTypes, removeAccreditedType } from '../../store/collections/accreditedTypesWorker'
import * as XLSX from 'xlsx';
import { TicketAnalyticsDialog } from '../buscas/ticketSearch';
import { getAllTicketsByAccreditedIdAndDates, ticketsByAccreditedId } from '../../store/collections/ticketWorker';
import Select from 'react-select';
import { saveAccreditedType } from '../../store/collections/accreditedTypesWorker';
import { dangerConfirm, isNullOrEmpty, isValidatePlate, normalizedField, queryString, reloadWindow, reloadWithAlert, setPathWindow, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning } from '../../shared/utils';
import { RegisterAccessCardModal } from '../mensalista/clientes/modal';
import { AlertInfo, AlertSecondary, AlertSuccess, AlertWarning } from '../../shared/alert-manager';
import { getParameterByParkId } from '../../store/collections/parameterWorker';
import { getAllAccessCardsOfAccreditedByParkId } from '../../store/collections/accessCardWorker';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { docsToItems } from '../../store/transform.docs';
import { addDoc, deleteDoc } from '../../store/collections/collectionBaseWorker';
import { Collection } from '../../shared/constants';
import { getAccreditedById, getAllAccreditedByParkId } from '../../store/collections/accreditedWorker';

const initialState = {
    id: '',
    status: 'Ativo',
    nome: '',
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    userEmail: getUsuario()?.email,
    documento: undefined,
    tipoDocumento: 'CPF',
    email: undefined,
    celular: undefined,
    mostrarCamposOpcionais: false,
    placa: '',
    telefone: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    horarioInicio: '00:00',
    horarioFim: '00:00',
    allDays: true,
    segunda: true,
    terca: true,
    quarta: true,
    tipo: true,
    quinta: true,
    sexta: true,
    sabado: true,
    domingo: true,
    endereco: {},
    allCredentials: [],
    veiculos: [],
    tickets: [],
    allVagas: [],
    showModalAccessHistory: false,
    random: 0,
    errorImport: [],
    importingStatus: 'await',
    importingMessage: 'Importar credenciados',
    importModalIsOpen: false,
    clientsToImport: [],
    currentImporting: null,
    allSetores: [],
    setores: [],
    types: [],
    allTypes: [],
    maxWidth: "md",
    startDate: Moment(),
    endDate: Moment(),
    isRangeDateOpen: false
};

class CriarCredenciado extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount = () => {
        this.load()
    }

    load = async () => {
        this.loadQntVagas()
        await this._loadEditAccreditedIfNeed()
        await this._loadAccrediteds()
        await this._loadSectores()
        await this._loadAccreditedTypes()
        await this._loadParameters()
    }

    _loadAccrediteds = async () => {
        const credentials = await getAllCredentialsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allCredentials: credentials })
    }

    _loadEditAccreditedIfNeed = async () => {
        const id = queryString("id")
        if (id) {
            this.setState({ isLoading: true })
            const accredited = await getAccreditedById({ id: id })
            if (accredited) {
                this.onEdit(accredited)
            } else {
                toastWarning("Credênciado não encontrado.")
            }
            this.setState({ isLoading: false })
        }
    }

    loadQntVagas = () => {
        var vagas = []
        for (var i = 1; i <= 20; i++) {
            vagas.push(i)
        }
        this.setState({ allVagas: vagas })
    }

    _loadSectores = async () => {
        var allSetores = await getAllSectoresByParkId({ estacionamentoId: this.state.estacionamentoId })
        var mapped = allSetores.map(e => { return { value: e.id, label: e.nome } })
        this.setState({ allSetores: mapped })
    }

    _loadAccreditedTypes = async () => {
        var allTypes = await getAllAccreditedTypes()
        var mapped = allTypes.map(e => { return { value: e.id, label: e.name } })
        this.setState({ allTypes: mapped })
    }

    _loadParameters = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ habilitarCartaoParaAcesso: parameters.habilitarCartaoParaAcesso })

        if (parameters.habilitarCartaoParaAcesso) {
            this.loadAccessCardsIfNeed()
        }
    }

    loadAccessCardsIfNeed = async () => {
        const allAccessCards = await getAllAccessCardsOfAccreditedByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allAccessCards: allAccessCards })
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ showModalAccessHistory: false })
    }

    reset = () => {
        setPathWindow("/cadastrar/credenciado")
        this.setState({ ...initialState })
        this.setState({ random: Math.random() })
        this.setState({ setores: [] })
        this.setState({ documento: '' })
        this.setState({ veiculos: [] })
    }

    addVehicleReset = () => {
        this.setState({ placa: '' })
        this.setState({ modelo: '' })
        this.setState({ cor: '' })
        this.setState({ cartao: '' })
    }

    getData = () => {
        return {
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            documento: String(this.state.documento ?? ""),
            status: this.state.status,
            nome: String(this.state.nome),
            tipoDocumento: this.state.tipoDocumento,
            email: this.state.email,
            celular: String(this.state.celular ?? ""),
            telefone: String(this.state.telefone ?? ""),
            veiculos: this.state.veiculos,
            setores: this.state.setores.map(e => e.label),
            tipos: this.state.types.map(e => e.label),
            vagas: isNaN(parseInt(this.state.vagas)) ? 1 : parseInt(this.state.vagas),
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            acesso: {
                horarioInicio: this.state.horarioInicio,
                horarioFim: this.state.horarioFim,
                segunda: this.state.segunda,
                terca: this.state.terca,
                quarta: this.state.quarta,
                quinta: this.state.quinta,
                sexta: this.state.sexta,
                sabado: this.state.sabado,
                domingo: this.state.domingo
            },
            createAt: new Date(),
            createBy: this.state.userEmail
        }
    }

    addOrUpdate = async () => {
        if (!this.checkFields()) { return }
        const data = this.getData()
        if (this.state.id === '') {
            toastLoading("Cadastrando credenciado...")
            await addCredential({ data: data })
            toastDismissLoading()
            toastSuccess("Credenciado cadastrado com sucesso!")
            sendLogByUser("Credenciados", `Cadastrou o credenciado ${data.nome}`, data)
        } else {
            toastLoading("Atualizando credenciado...")
            await updateCredential({ id: this.state.id, data: data })
            toastDismissLoading()
            toastSuccess("Credenciado atualizado com sucesso!")
            sendLogByUser("Credenciados", `Atualizou o credenciado ${data.nome}`, data)
        }
        await saveLastUpdate()
        this.reset()
        this.load()
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
        }
    }

    onEdit = (item) => {
        setPathWindow(`/cadastrar/credenciado/?id=${item.id}`)
        this.setState({ ...item })
        this.setState({ id: item.id })
        this.setState({ mostrarCamposOpcionais: true })
        this.setState({ cep: item.endereco?.cep })
        this.setState({ logradouro: item.endereco?.logradouro })
        this.setState({ numero: item.endereco?.numero })
        this.setState({ bairro: item.endereco?.bairro })
        this.setState({ cidade: item.endereco?.cidade })
        this.setState({ estado: item.endereco?.estado })
        this.setState({ complemento: item.endereco?.complemento })
        this.setState({ horarioInicio: item.acesso.horarioInicio })
        this.setState({ horarioFim: item.acesso.horarioFim })
        this.setState({ segunda: item.acesso.segunda })
        this.setState({ terca: item.acesso.terca })
        this.setState({ quarta: item.acesso.quarta })
        this.setState({ quinta: item.acesso.quinta })
        this.setState({ sexta: item.acesso.sexta })
        this.setState({ sabado: item.acesso.sabado })
        this.setState({ domingo: item.acesso.domingo })
        this.setState({ vagas: isNaN(parseInt(item.vagas)) ? 1 : parseInt(item.vagas) })
        this.setState({ random: Math.random() })
        this.setState({ setores: (item.setores ?? []).map(e => { return { value: e, label: e } }) })
        this.setState({ types: (item.tipos ?? []).map(e => { return { value: e, label: e } }) })
        const allDays = item.acesso.segunda && item.acesso.terca && item.acesso.quarta && item.acesso.quinta && item.acesso.sexta && item.acesso.sabado && item.acesso.domingo
        this.setState({ allDays: allDays })
        this._loadParameters()
    }

    onDuplicate = (item) => {
        toastWarning("Os veículos não são duplicados para não conflitarem com o outro credenciado já cadastrado.")
        this.onEdit(item)
        this.setState({ veiculos: [] })
        this.setState({ id: '' })
    }

    handlerAllDays = (e) => {
        this.setState({ allDays: e.target.checked })
        if (e.target.checked) {
            this.setState({ segunda: true })
            this.setState({ terca: true })
            this.setState({ quarta: true })
            this.setState({ quinta: true })
            this.setState({ sexta: true })
            this.setState({ sabado: true })
            this.setState({ domingo: true })
        }
    }

    handleSetores = (selected) => {
        this.setState({ setores: selected })
    };

    handleTypes = (selected) => {
        this.setState({ types: selected })
    };


    onShowHistoryRangeModal = (item) => {
        this.setState({ currentAccredited: item })
        this.setState({ isRangeDateOpen: true })
    }

    hideHistoryRangeModal = () => {
        this.setState({ isRangeDateOpen: false })
    }

    onHistory = async ({ startDate, endDate }) => {
        this.setState({
            startDate: startDate,
            endDate: endDate
        })
        toastLoading("Aguarde...")
        const tickets = await getAllTicketsByAccreditedIdAndDates({
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            accreditedId: this.state.currentAccredited.id
        })
        this.setState({ tickets: tickets })
        this.setState({ showModalAccessHistory: true })
        toastDismissLoading()
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ showModalAccessHistory: false })
    }

    onRemoveAccredited = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover o credenciado ${item.nome}?` });
        if (confirm) {
            toastLoading("Removendo credenciado...")
            await deleteCredentialById({ id: item.id })
            saveLastUpdate()
            toastDismissLoading()
            reloadWithAlert("Credenciado removido com sucesso!")
        }
    }

    checkFields = () => {
        if (this.state.nome === '') {
            toastWarning("Preencha o nome do credenciado.")
            return false
        }
        if (this.state.veiculos.length === 0) {
            toastWarning("Informe ao menos 1 veículo para este credenciado.")
            return false
        }
        return true
    }

    addVehicle = async () => {
        if (isValidatePlate(this.state.placa) && (await this.verifyIfIsAddedVehicleExist()) && this.verifyIfVehicleExist()) {
            var veiculos = this.state.veiculos
            veiculos.push({
                placa: this.state.placa.toUpperCase(),
                modelo: this.state.modelo,
                cor: this.state.cor,
                cartao: this.state.cartao
            })
            this.setState({ veiculos: veiculos })
            this.addVehicleReset()
        } else {
            toastWarning("Informe uma placa válida:\n\nPlaca Antiga: FGR4747\nPlaca Nova: AAA2A22")
        }
    }

    onRemove = (item) => {
        var veiculos = this.state.veiculos.filter(e => e.placa !== item.placa)
        this.setState({ veiculos: veiculos })
    }

    verifyIfIsAddedVehicleExist = async () => {
        const exist = await this.verifyIfVehicleInMonthlyExist()
        if (exist) {
            return false
        }
        return !this.state.veiculos?.map(e => e.placa.replace('-', '').toUpperCase()).includes(this.state.placa.toUpperCase().replace('-', ''))
    }

    verifyIfVehicleInMonthlyExist = async () => {
        const result = await firebase.firestore().collection('veiculos').where('estacionamentoId', '==', this.state.estacionamentoId).where('placa', '==', this.state.placa.toUpperCase().replace('-', '')).get()
        if (!result.empty && result.docs[0].data().mensalistaId !== undefined) {
            toastWarning('Já existe um veiculo com esta placa cadastrado para este estabelecimento em um credenciado')
            return true
        }
        return false
    }

    verifyIfVehicleExist = () => {
        const contains = this.state.allCredentials?.veiculos?.map(e => e.placa.replace('-', '').toUpperCase()).includes(this.state.placa.toUpperCase().replace('-', ''))
        if (contains) {
            toastWarning('Já existe um veiculo com esta placa cadastrado para um credenciado deste estabelecimento.')
            return false
        }
        if (this.state.habilitarCartaoParaAcesso) {
            const contains = this.state.allCredentials?.veiculos?.map(e => e.cartao === this.state.cartao)
            if (contains) {
                toastWarning('Já existe um veiculo com este cartão de acesso cadastrado para um credenciado deste estabelecimento.')
                return false
            }
        }
        return true
    }

    resetImport = () => {
        this.setState({ importModalIsOpen: false })
        sendClickButton("Credenciado", "Fechou modal de importação de credenciados por excel.")
    }

    handleImportFileChange = (e) => {
        this.setState({ importingStatus: 'validating' })
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        const prepareF = this.prepare
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            const json = XLSX.utils.sheet_to_json(ws, { header: 2, cellDates: true });
            prepareF(json)
        };
        reader.readAsBinaryString(f)
    }
    prepare = async (json) => {
        if (json.length !== 0) {
            const firstRow = json[0]
            if (firstRow.STATUS === undefined ||
                firstRow.NOME_CREDENCIADO === undefined ||
                firstRow.HORARIO_INICIO === undefined ||
                firstRow.HORARIO_FIM === undefined ||
                firstRow.SEGUNDA === undefined ||
                firstRow.TERCA === undefined ||
                firstRow.QUARTA === undefined ||
                firstRow.QUINTA === undefined ||
                firstRow.SEXTA === undefined ||
                firstRow.SABADO === undefined ||
                firstRow.DOMINGO === undefined ||
                firstRow.PLACAS === undefined) {
                this.setState({ importingMessage: "Informe uma planilha válida com todos os dados obrigatórios preenchidos." })
                this.setState({ importingStatus: "empty" })
            } else {
                await this.validateJSONToImport(json)
            }
        } else {
            this.setState({ importingMessage: "Nenhum registro encontrado no arquivo selecionado." })
            this.setState({ importingStatus: "empty" })
        }
    }

    validateJSONToImport = async (json) => {
        var errosToImport = []
        this.setState({ importingMessage: "Verificando arquivo..." })
        await this.validateAccrediteds(json, errosToImport)
        this.setState({ errorImport: errosToImport })
        if (errosToImport.length > 0) {
            if (errosToImport.length === 1) {
                this.setState({ importingMessage: `Existe um alerta na planilha selecionada.` })
            } else {
                this.setState({ importingMessage: `Existem ${errosToImport.length} clientes com alertas na planilha selecionada.` })
            }
        }
    }

    validateAccrediteds = async (json, errosToImport) => {
        const result = await firebase.firestore().collection('credenciados').where('estacionamentoId', '==', this.state.estacionamentoId).get()
        const resultVehicles = await firebase.firestore().collection('veiculos').where('estacionamentoId', '==', this.state.estacionamentoId).get()
        const sectores = await getAllSectoresByClientId({ clienteId: this.state.clienteId })
        const types = await getAllAccreditedTypes()
        const credenciados = docsToItems(result)
        const veiculosMensalistas = docsToItems(resultVehicles).filter(e => e.mensalistaId !== undefined)
        var vehicles = credenciados.map(e => e.veiculos)
        vehicles.push(veiculosMensalistas)
        vehicles = vehicles.flatMap(e => e)

        var clientValidate = []
        for (const [index, item] of json.entries()) {
            var messages = []
            messages.push(this.verifyNames(item))
            messages.push(this.verifySectors(item, sectores))
            messages.push(this.verifyTypes(item, types))
            messages.push(await this.verifyPlates(item, vehicles))
            messages.push(await this.verifyAddress(item))
            const resultMessages = messages.filter(e => e !== '')
            if (resultMessages.length > 0) {
                errosToImport.push({
                    line: (index + 1),
                    client: item,
                    message: `${resultMessages.join(", ")}.`
                })
            } else {
                clientValidate.push({
                    ...item
                })
            }
        }
        this.setState({ clientsToImport: clientValidate })
        if (errosToImport.length > 0) {
            this.setState({ importingStatus: 'validating' })
            this.setState({ maxWidth: 'md' })
        } else {
            this.setState({ importingStatus: 'no-errors' })
            this.setState({ maxWidth: 'xl' })
            if (clientValidate.length === 1) {
                this.setState({ importingMessage: `Encontramos 1 credenciado para importação.` })
            } else {
                this.setState({ importingMessage: `Encontramos ${clientValidate.length} credenciados para importação.` })
            }
        }
    }

    verifyNames = (item) => {
        if (item.NOME_CREDENCIADO === undefined ||
            item.NOME_CREDENCIADO === "") {
            return "Informe o nome do credenciado"
        }
        return ''
    }

    verifyPlates = async (item, vehicles) => {
        const placas = item.PLACAS.split(",")
        for (const placa of placas) {
            if (!isValidatePlate(placa.replaceAll(" ", ""))) {
                return `A placa ${placa} esta inválida`
            } else if (vehicles.filter(f => f.placa.toUpperCase() === placa.toUpperCase()).length !== 0) {
                return `A placa ${placa} já esta associada a outro credenciado ou mensalista`
            }
        }
        return ''
    }

    verifySectors = (item, sectores) => {
        const setores = (item.SETORES_OPCIONAL ?? "").split(",")
        for (const setor of setores.filter(e => e !== "")) {
            const exist = sectores.filter(e => e.nome === setor).length > 0
            if (!exist) {
                return `O setor '${setor}' não existe, confira se esta escrito exatamente como cadastrado`
            }
        }
        return ''
    }

    verifyTypes = (item, types) => {
        const items = (item.TIPOS_OPCIONAL ?? "").split(",")
        for (const type of items.filter(e => e !== "")) {
            const exist = types.filter(e => e.name === type).length > 0
            if (!exist) {
                return `O tipo '${type}' não existe, confira se esta escrito exatamente como cadastrado`
            }
        }
        return ''
    }

    verifyAddress = async (item) => {
        const cep = String(item.CEP_OPCIONAL)?.replaceAll(" ", "").replaceAll("-", "").padStart("8", "0")
        if (cep !== '' && cep.length !== 8) {
            return `Informe um CEP válido quando preenchido. ${cep}`
        }
        return ''
    }

    importAnyway = async () => {
        this.setState({ importingStatus: 'no-errors' })
        this.setState({ maxWidth: 'xl' })
        const clientValidate = this.state.clientsToImport
        if (clientValidate.length === 1) {
            this.setState({ importingMessage: `1 credenciado para importação.` })
        } else {
            this.setState({ importingMessage: `${clientValidate.length} credenciados para importação.` })
        }
    }

    forceImport = async () => {
        this.setState({ maxWidth: 'md' })
        this.setState({ importingStatus: "importing" })
        const clients = this.state.clientsToImport
        sendLogByUser("Credenciados", `Importando ${clients.length} credenciados`)
        const messages = []
        var quantityImported = 0
        for (const [index, item] of clients.entries()) {
            this.setState({ importingMessage: `Importando ${index + 1} de ${clients.length} credenciados` })
            this.setState({ currentImporting: item.NOME_CREDENCIADO })
            const result = await this.addImported({ item: item })
            if (result.success) {
                quantityImported++
            } else {
                messages.push(result.message)
            }
        }
        if (messages.length === 0) {
            await saveLastUpdate()
            this.setState({ importingMessage: `${quantityImported} importados com sucesso` })
            this.setState({ importingStatus: 'success' })
            sendLogByUser("Credenciados", `Importou ${quantityImported} credenciados com sucesso`)
        } else {
            this.setState({ importingStatus: 'warnings' })
            this.setState({ importingWarnings: messages.join(", ") })
            sendLogByUser("Credenciados", `Alguns credenciados não importados: ${messages.join(", ")}`, messages)
        }
    }

    addImported = async ({ item }) => {
        try {
            var address = null
            if (item.CEP_OPCIONAL !== undefined) {
                address = await searchCEP(String(item.CEP_OPCIONAL).padStart("8", "0"))
                if (address.erro !== true) {
                    if (item.LOGRADOURO_OPCIONAL !== undefined) {
                        address.logradouro = item.LOGRADOURO_OPCIONAL
                    }
                    if (item.BAIRRO_OPCIONAL !== undefined) {
                        address.bairro = item.BAIRRO_OPCIONAL
                    }
                    if (item.CIDADE_OPCIONAL !== undefined) {
                        address.cidade = item.CIDADE_OPCIONAL
                    }
                    if (item.NUMERO_OPCIONAL !== undefined) {
                        address.numero = item.NUMERO_OPCIONAL
                    }
                    if (item.ESTADO_OPCIONAL !== undefined) {
                        address.estado = item.ESTADO_OPCIONAL
                    }
                    if (item.COMPLEMENTO_OPCIONAL !== undefined) {
                        address.complemento = item.COMPLEMENTO_OPCIONAL
                    }
                } else {
                    address = null
                }
            }
            var veiculos = []
            for (const [index, placa] of item.PLACAS.split(",").entries()) {

                function getItemOrBlank(index, item) {
                    try {
                        return item.split(",")[index]
                    } catch (error) {
                        return ""
                    }
                }

                try {
                    veiculos.push({
                        placa: placa.replaceAll("-", "").replaceAll(" ", "").toUpperCase(),
                        modelo: getItemOrBlank(item.MODELOS_OPCIONAL, index),
                        cor: getItemOrBlank(item.CORES_OPCIONAL, index),
                    })
                } catch (error) {

                }
            }

            var setores = ((item.SETORES_OPCIONAL ?? "").split(",") ?? [])
            setores = setores.filter(e => e !== '')

            var tipos = ((item.TIPOS_OPCIONAL ?? "").split(",") ?? [])
            tipos = tipos.filter(e => e !== '')
            const data = {
                estacionamentoId: this.state.estacionamentoId,
                revendaId: getRevendaIdByPark(),
                clienteId: this.state.clienteId,
                documento: String(item.DOCUMENTO_OPCIONAL ?? ""),
                status: String(item.STATUS),
                nome: String(item.NOME_CREDENCIADO),
                tipoDocumento: String(item.DOCUMENTO_OPCIONAL ?? "").replaceAll(".", '').replaceAll("-", '').replaceAll("/", '').length === 11 ? "CPF" : "CNPJ",
                email: item.EMAIL_OPCIONAL,
                celular: String(item.CELULAR_OPCIONAL ?? ""),
                telefone: String(item.TELEFONE_OPCIONAL ?? ""),
                veiculos: veiculos,
                endereco: address,
                setores: setores,
                tipos: tipos,
                vagas: parseInt(item.VAGAS ?? "1"),
                acesso: {
                    horarioInicio: Moment(item.HORARIO_INICIO)?.format("HH:mm") ?? String(item.HORARIO_INICIO),
                    horarioFim: Moment(item.HORARIO_FIM)?.format("HH:mm") ?? String(item.HORARIO_FIM),
                    segunda: Boolean(item.SEGUNDA),
                    terca: Boolean(item.TERCA),
                    quarta: Boolean(item.QUARTA),
                    quinta: Boolean(item.QUINTA),
                    sexta: Boolean(item.SEXTA),
                    sabado: Boolean(item.SABADO),
                    domingo: Boolean(item.DOMINGO)
                },
                createAt: new Date(),
                createBy: this.state.userEmail,
                isDeleted: false,
            }
            await addDoc({ collection: Collection.ACCREDITEDS, data })
            return { success: true }
        } catch (error) {
            return { success: false, message: `credenciado não cadastrado: ${item.NOME_CLIENTE}` }
        }
    }

    importAgain = () => {
        this.setState({ importingMessage: "Importar credenciado" })
        this.setState({ importingStatus: 'await' })
        this.setState({ maxWidth: 'md' })
    }

    closeImportModal = () => {
        reloadWindow()
    }

    onChangeAccreditedTypes = async () => {
        await this._loadAccreditedTypes()
    }

    hideAddAccreditedModal = () => {
        this.setState({ accreditedModalIsOpen: false })
    }

    showAddAccreditedModal = () => {
        this.setState({ accreditedModalIsOpen: true })
    }

    openAccessCardOpenModal = () => {
        this.setState({ isAccessCardOpenModal: true })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Cadastrar credenciado
                            <div className='btn-group'>
                                {
                                    this.state.id && this.state.habilitarCartaoParaAcesso &&
                                    <button type="button" onClick={this.openAccessCardOpenModal} className="btn btn-sm btn-secondary">
                                        <i className="fas fa-id-card mx-2" />
                                        Cartões de acesso
                                    </button>
                                }
                                <button type="button" onClick={(e) => this.setState({ importModalIsOpen: true })} className="btn btn-sm btn-primary">
                                    <i className="fas fa-file-import mx-2" />
                                    Importar
                                </button>
                                <HelpButton />
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                this.state.isLoading ? <Loading /> :
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <h6>
                                                1 - Dados do credenciado
                                            </h6>
                                            <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>Status</label>
                                                    {
                                                        <select className="form-select" value={this.state.status} onChange={e => this.setState({ status: e.target.value })} >
                                                            <option value='Ativo'>Ativo</option>
                                                            <option value='Inativo'>Inativo</option>
                                                            <option value='pending'>Pendente</option>
                                                        </select>
                                                    }
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Vagas</label>
                                                    {
                                                        <select className="form-select" value={this.state.vagas} onChange={e => this.setState({ vagas: e.target.value })} >
                                                            <option value='1'>1</option>
                                                            {
                                                                this.state.allVagas.filter(e => e !== 1).map(vagas => <option key={vagas} value={vagas}>{vagas}</option>)
                                                            }
                                                        </select>
                                                    }
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Nome</label>
                                                    <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-lg-4">
                                                    <label>Tipo documento</label>
                                                    <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} >
                                                        <option value='CPF'>CPF</option>
                                                        <option value='CNPJ'>CNPJ</option>
                                                        <option value='ESTRANGEIRO'>Estrangeiro</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-8">
                                                    <label>Número documento</label>
                                                    {
                                                        this.state.tipoDocumento === "ESTRANGEIRO" ?
                                                            <input type="text" className="form-control" value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} /> :
                                                            <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.mostrarCamposOpcionais === false &&
                                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                                    <div className='mt-3'>
                                                        <button type="button" onClick={(e) => { this.setState({ mostrarCamposOpcionais: true }) }} className="btn btn-secondary btn-sm w-100">
                                                            Mostrar mais campos
                                                            <i className="fas fa-plus mx-3" />
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.mostrarCamposOpcionais === true &&
                                                <>
                                                    <div className='row'>
                                                        <div className='form-group'>
                                                            <h5 className='my-3'>Contatos</h5>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>WhatsApp/Celular</label>
                                                            <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Telefone/Comercial</label>
                                                            <NumberFormat className="form-control" value={this.state.telefone} mask='_' onChange={(e) => this.setState({ telefone: e.target.value })} format="(##)#####-####" />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>E-mail</label>
                                                            <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='form-group'>
                                                            <h5 className='my-3'>Endereço</h5>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <label>CEP</label>
                                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>Logradouro</label>
                                                            <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <label>Número</label>
                                                            <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>Bairro</label>
                                                            <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <label>Cidade</label>
                                                            <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <label>Estado</label>
                                                            <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>Complemento</label>
                                                            <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='col-lg-3'>
                                            <h6>
                                                2 - Disponibilidade de acesso
                                            </h6>
                                            <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>Horário início</label>
                                                    <NumberFormat className="form-control" value={this.state.horarioInicio} mask='_' onChange={(e) => this.setState({ horarioInicio: e.target.value })} format="##:##" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Horário fim</label>
                                                    <NumberFormat className="form-control" value={this.state.horarioFim} mask='_' onChange={(e) => this.setState({ horarioFim: e.target.value })} format="##:##" />
                                                </div>
                                                <div className='row m-1'>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" id="diatodo" checked={this.state.allDays} onChange={(e) => this.handlerAllDays(e)} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="diatodo">
                                                            Todos os dias da semana
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.allDays === false &&
                                                        <>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="segunda" checked={this.state.segunda} onChange={(e) => this.setState({ segunda: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="segunda">
                                                                    Segunda-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="terca" checked={this.state.terca} onChange={(e) => this.setState({ terca: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="terca">
                                                                    Terça-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="quarta" checked={this.state.quarta} onChange={(e) => this.setState({ quarta: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="quarta">
                                                                    Quarta-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="quinta" checked={this.state.quinta} onChange={(e) => this.setState({ quinta: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="quinta">
                                                                    Quinta-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="sexta" checked={this.state.sexta} onChange={(e) => this.setState({ sexta: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="sexta">
                                                                    Sexta-Feira
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="sabado" checked={this.state.sabado} onChange={(e) => this.setState({ sabado: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="sabado">
                                                                    Sábado
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" id="domingo" checked={this.state.domingo} onChange={(e) => this.setState({ domingo: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="domingo">
                                                                    Domingo
                                                                </label>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Setores ( opcional )</label>
                                                    <Select
                                                        key={this.state.random}
                                                        isMulti
                                                        name="setores"
                                                        defaultValue={this.state.setores}
                                                        options={this.state.allSetores}
                                                        onChange={this.handleSetores}
                                                        className="basic-multi-select"
                                                        classNamePrefix="Selecionar os setores"
                                                    />
                                                    <div className='mt-3'>
                                                        <AlertSecondary tag="Informação" message="Selecione os setores que o credenciado terá acesso ( deixe vazio caso seja todos os setores. )" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Tipos ( opcional )</label>
                                                    <div className='row'>
                                                        <div className='col-lg-9'>
                                                            <Select
                                                                key={this.state.random}
                                                                isMulti
                                                                name="tipos"
                                                                defaultValue={this.state.types}
                                                                options={this.state.allTypes}
                                                                onChange={this.handleTypes}
                                                                className="basic-multi-select"
                                                                classNamePrefix="Selecionar os tipos"
                                                            />
                                                        </div>
                                                        <div className='col-lg-3'>
                                                            <button type="button" onClick={this.showAddAccreditedModal} className="btn btn-primary w-100">
                                                                <i className="fas fa-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5'>
                                            <h6>3 - Cadastrar veículos</h6>
                                            <div className='row'>
                                                <div className="col-lg-12">
                                                    <AlertWarning message="A placa informada sera utilizada para encontrar o credenciado no aplicativo." />
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <label>Placa (*)</label>
                                                            <input type="text" maxLength='7' onChange={(e) => this.setState({ placa: e.target.value })} value={this.state.placa.toUpperCase()} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <label>Modelo (*)</label>
                                                            <input type="text" onChange={(e) => this.setState({ modelo: e.target.value })} value={this.state.modelo} className="form-control" />
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <label>Cor</label>
                                                            <select className='form-select' onChange={(e) => this.setState({ cor: e.target.value })} value={this.state.cor}>
                                                                <option value=''>Selecione</option>
                                                                <option value='Branco'>Branco</option>
                                                                <option value='Prata'>Prata</option>
                                                                <option value='Preto'>Preto</option>
                                                                <option value='Cinza'>Cinza</option>
                                                                <option value='Vermelho'>Vermelho</option>
                                                                <option value='Azul'>Azul</option>
                                                                <option value='Amarelo'>Amarelo</option>
                                                                <option value='Verde'>Verde</option>
                                                                <option value='Vinho'>Vinho</option>
                                                                <option value='Marrom'>Marrom</option>
                                                                <option value='Bege'>Bege</option>
                                                                <option value='Laranja'>Laranja</option>
                                                                <option value='Outros'>Outros</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            this.state.habilitarCartaoParaAcesso &&
                                                            <div className="col-lg-3">
                                                                <label>Associar cartão</label>
                                                                <select className='form-select' onChange={(e) => this.setState({ cartao: e.target.value })} value={this.state.cartao}>
                                                                    <option value="">Selecionar</option>
                                                                    {
                                                                        this.state.allAccessCards?.map(item => { return <option value={item.cardNumber}>{item.cardNumber}</option> })
                                                                    }
                                                                </select>
                                                            </div>
                                                        }
                                                        <div className='col-lg-3 mt-3'>
                                                            <button type="button" onClick={this.addVehicle} className="btn btn-primary w-100">
                                                                <i className="fas fa-plus mx-2" /> Adicionar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.veiculos.length > 0 &&
                                                    <div className='col-lg-12'>
                                                        <div className='table-responsive'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <td><strong>Placa</strong></td>
                                                                        <td align='center'><strong>Modelo</strong></td>
                                                                        <td align='center' className="d-none d-sm-table-cell"><strong>Cor</strong></td>
                                                                        {
                                                                            this.state.habilitarCartaoParaAcesso &&
                                                                            <td align='center' className="d-none d-sm-table-cell"><strong>Cartão</strong></td>
                                                                        }
                                                                        <td width="10" align='right'></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.veiculos.map((item, index) =>
                                                                            <tr key={index}>
                                                                                <td>{item.placa}</td>
                                                                                <td align='center' >{item.modelo ?? '-'}</td>
                                                                                <td align='center' className="d-none d-sm-table-cell">{item.cor ?? '-'}</td>
                                                                                {
                                                                                    this.state.habilitarCartaoParaAcesso &&
                                                                                    <td align='center' className="d-none d-sm-table-cell">{item.cartao ?? '-'}</td>
                                                                                }
                                                                                <td align='right'>
                                                                                    <div className="btn-group">
                                                                                        <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                                            <i className="fas fa-trash" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className="card-footer">
                            <div className="btn-group">
                                {
                                    this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                                }
                                <button type="button" onClick={this.addOrUpdate} className="btn btn-primary">{this.state.id ? 'Atualizar dados' : 'Cadastrar'}</button>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.showModalAccessHistory && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                    }
                    <Dialog maxWidth={this.state.maxWidth} fullWidth onClose={this.resetImport} open={this.state.importModalIsOpen}>
                        <MuiDialogTitle className='text-center'>
                            {this.state.importingMessage}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.importingStatus === "importing" &&
                                <>
                                    <h4 className='text-center'>{this.state.currentImporting}...</h4>
                                    <h6 className='text-center my-3'>Isso pode levar alguns segundos.</h6>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="button" onClick={this.closeImportModal} className="btn btn-danger btn-sm my-3">
                                            Cancelar
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                this.state.importingStatus === "success" &&
                                <>
                                    <h4 className='text-center'>Todos os credenciados foram importados com sucesso!</h4>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="button" onClick={this.closeImportModal} className="btn btn-success btn-sm my-3">
                                            Fechar
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                this.state.importingStatus === "warning" &&
                                <>
                                    <h6>Alguns credenciados não foram importados motivo: <p /></h6>
                                    <span>{this.state.resultMessages}</span>
                                </>
                            }
                            {
                                this.state.importingStatus === "no-errors" &&
                                <div className='row'>
                                    <table className="table table-responsive table-striped">
                                        <tr>
                                            <td><strong>Nome</strong></td>
                                            <td align='center'><strong>Documento</strong></td>
                                            <td align='center'><strong>Celular</strong></td>
                                            <td align='center'><strong>Telefone</strong></td>
                                            <td align='center'><strong>CEP</strong></td>
                                            <td align='center'><strong>E-mail</strong></td>
                                            <td align='center'><strong>Placas</strong></td>
                                            <td align='center'><strong>Setores</strong></td>
                                            <td align='center'><strong>Tipos</strong></td>
                                        </tr>
                                        <tbody className='table-striped'>
                                            {
                                                this.state.clientsToImport.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{item.NOME_CREDENCIADO ?? "-"}</td>
                                                        <td align='center'>{item.DOCUMENTO_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.CELULAR_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.TELEFONE_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.CEP_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.EMAIL_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.PLACAS ?? "-"}</td>
                                                        <td align='center'>{item.SETORES_OPCIONAL ?? "-"}</td>
                                                        <td align='center'>{item.TIPOS_OPCIONAL ?? "-"}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div className="btn-group">
                                            <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                                <i className="fas fa-long-arrow-left mx-2" />
                                                Voltar
                                            </button>
                                            {
                                                this.state.clientsToImport.length > 0 && <>
                                                    <button type="button" onClick={this.forceImport} className="btn btn-sm btn-warning text-white">
                                                        <i className="fas fa-file-import mx-2" />
                                                        {
                                                            this.state.clientsToImport.length === 1 && <span>Importar 1 credenciado</span>
                                                        }
                                                        {
                                                            this.state.clientsToImport.length > 1 && <span>Importar os {this.state.clientsToImport.length} credenciados</span>
                                                        }
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.importingStatus === "validating" &&
                                <>
                                    {
                                        this.state.errorImport.length === 0 && <Loading />
                                    }
                                    {
                                        this.state.errorImport.length > 0 &&
                                        <>
                                            <table className="table table-responsive">
                                                <tbody className='table-striped'>
                                                    <tr>
                                                        <td align='center'><strong>Linha</strong></td>
                                                        <td><strong>Credenciado</strong></td>
                                                        <td width={400}><strong>Mensagem</strong></td>
                                                    </tr>
                                                    {
                                                        this.state.errorImport.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='center'>{item.line}</td>
                                                                <td>{(item.client.NOME_CREDENCIADO === undefined || item.client.NOME_CREDENCIADO === "") ? "-" : item.client.NOME_CREDENCIADO}</td>
                                                                <td>{item.message}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            <div className='row'>
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <div className="btn-group">
                                                        <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                                            <i className="fas fa-long-arrow-left mx-2" />
                                                            Voltar
                                                        </button>
                                                        {
                                                            this.state.clientsToImport.length > 0 && <>
                                                                <button type="button" onClick={this.importAnyway} className="btn btn-sm btn-warning text-white">
                                                                    <i className="fas fa-file-import mx-2" />
                                                                    {
                                                                        this.state.clientsToImport.length === 1 && <span>Importar mesmo assim 1 credenciado correto</span>
                                                                    }
                                                                    {
                                                                        this.state.clientsToImport.length > 1 && <span>Importar mesmo assim os {this.state.clientsToImport.length} credenciados corretos</span>
                                                                    }
                                                                </button>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            {
                                this.state.importingStatus === "empty" &&
                                <>
                                    <div className="alert alert-secondary text-center" role="alert">
                                        {this.state.importingMessage}
                                    </div>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button type="button" onClick={this.importAgain} className="btn btn-sm btn-secondary">
                                            <i className="fas fa-long-arrow-left mx-2" />
                                            Voltar
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                this.state.importingStatus === 'await' &&
                                <div>
                                    <div className="alert alert-secondary" role="alert">
                                        Antes de começarmos você deve baixar a nossa planilha de exemplo e <br /> preencher com os dados dos seus credenciados. <p />
                                        <strong><a href="https://firebasestorage.googleapis.com/v0/b/selfparking-prod-26925.appspot.com/o/files%2Fothers%2Fimportar_credenciado_exemplo.xlsx?alt=media&token=6007e86d-0b93-45fe-813f-d28738421c71" target="_blank" rel="noopener noreferrer" download>Baixar planilha de exemplo</a></strong>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="formFile" className="form-label">Selecionar arquivo</label>
                                        <div>
                                            <input type="file" accept=".xlsx" onChange={this.handleImportFileChange} id="formFile" />
                                        </div>
                                    </div>
                                    {/* <FileUploader handleChange={this.handleDataFaturatDateChange} name="file" types={["xlsx"]} /> */}
                                </div>
                            }
                        </MuiDialogContent>
                    </Dialog>
                    <BuscarCredenciados key={this.state.random} onEdit={this.onEdit} onRemove={this.onRemoveAccredited} onHistory={this.onShowHistoryRangeModal} onDuplicate={this.onDuplicate} showDetail={true} />
                    <Dialog maxWidth={"xs"} fullWidth onClose={this.hideAddAccreditedModal} open={this.state.accreditedModalIsOpen}>
                        <MuiDialogContent>
                            <CriarCredenciadoTypesModal onChange={this.onChangeAccreditedTypes} />
                        </MuiDialogContent>
                    </Dialog>
                    {
                        this.state.isRangeDateOpen &&
                        <DateRangePickerDialog
                            title="Selecionar período de acesso do credenciado"
                            onClose={this.hideHistoryRangeModal}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onConfirm={this.onHistory} />
                    }
                </NavBar>
                {
                    this.state.isAccessCardOpenModal && <RegisterAccessCardModal
                        referenceId={this.state.id}
                        onCardUpdated={(e) => reloadWithAlert("Cartão de acesso cadastrado com sucesso.")}
                        onClose={e => this.setState({ isAccessCardOpenModal: false })}
                        type="accredited" />
                }
            </>
        )
    }
}

class CriarCredenciadoTypesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            estacionamentoId: getEstacionamento()?.id,
            items: [],
            isHidden: true,
            isLoading: true
        }
    }

    showAll = async () => {
        this.setState({ isHidden: false })
        await this.loadItems()
    }

    reset = () => {
        this.setState({ name: "" })
    }

    loadItems = async () => {
        this.setState({ isLoading: true });
        const items = await getAllAccreditedTypes()
        this.setState({ items: items })
        this.setState({ isLoading: false });
    }

    confirm = async () => {
        if (this.state.name === "" || this.state.estacionamentoId === undefined) {
            return
        }
        const typeFound = await getAccreditedTypeByName(this.state.name)
        if (typeFound === null) {
            await saveAccreditedType(this.state.name)
            toastSuccess("Tipo de credenciado salvo com sucesso!")
            this.reset()
            if (!this.state.isHidden) {
                this.loadItems()
            }
            this.props.onChange()
        } else {
            toastWarning("Já existe um tipo de credenciado cadastrado com este nome.")
        }
    }

    onRemove = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover tipo ${item.name}?\nOs credenciados já cadatrados com este tipo continuarão.` });
        if (confirm) {
            await removeAccreditedType(item.id)
            this.loadItems()
            this.props.onChange()
        }
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className="col-lg-12">
                        <h5 className='text-center'>Cadastrar tipo de credenciado</h5>
                    </div>
                    <div className="col-lg-12">
                        <label>Nome</label>
                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name && this.state.name} className="form-control" required />
                    </div>
                    <div className="col-lg-12 mt-3">
                        <AlertInfo message="Por exemplo: funcionários, político etc." />
                    </div>
                    <div className="col-lg-12 mb-3">
                        <div className="btn-group w-100">
                            <button onClick={this.confirm} className='btn btn-primary'>Confirmar</button>
                            {
                                this.state.isHidden &&
                                <button onClick={this.showAll} className='btn btn-secondary btn-block'>Listar todos</button>
                            }
                        </div>
                    </div>
                </div>
                {

                    !this.state.isHidden && <>
                        <div className="col-12">
                            {
                                this.state.items.length === 0 && this.state.isLoading === false ?
                                    <EmptyContent text={`Nenhuma tipo de credenciado cadastrado até o momento`} />
                                    :
                                    this.state.isLoading === false ?
                                        <>
                                            <h6>Todos os tipos de credenciados cadastrados</h6>
                                            <div className='row'>
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"><strong>Nome</strong></th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.items.map(item =>
                                                                    <tr key={item.id}>
                                                                        <td>{item.name}</td>
                                                                        <td width='10'>
                                                                            <button type="button" onClick={(e) => this.onRemove(item)} className="btn btn-danger">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <Loading />
                            }
                        </div>
                    </>
                }
            </>)
    }
}

class BuscarCredenciados extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            list: [],
            auxList: [],
            isLoading: true
        }
    }

    componentDidMount = () => {
        this._loadParameters()
        this.load()
    }

    _loadParameters = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ habilitarCartaoParaAcesso: parameters.habilitarCartaoParaAcesso })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ list: this.state.auxList })
        } else {
            const result = this.state.auxList.filter(e => e.nome.toUpperCase().includes(value) ||
                e.documento?.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").includes(value) ||
                e.celular?.toUpperCase().replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").includes(value) ||
                `${e.endereco?.cep?.replaceAll("-", "").toUpperCase() ?? ""}, ${e.endereco?.logradouro?.toUpperCase() ?? ""}, ${e.endereco?.numero?.toUpperCase() ?? ""}`.includes(value) ||
                e.status?.toUpperCase().includes(value) ||
                e.veiculos.map(e => e.placa.toUpperCase().replace("-", "")).join(", ").includes(value) ||
                e.tipos.map(e => e?.toUpperCase()).join(", ").includes(value) ||
                e.setores.map(e => e?.toUpperCase().replaceAll("-", "")).join(", ").includes(value))
            this.setState({ list: result })
        }
    }

    load = async () => {
        this.setState({ isLoading: true })
        const credentials = await getAllCredentialsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ list: credentials.sort((a, b) => String(a.nome ?? "").localeCompare(String(b.nome ?? ""))) })
        this.setState({ auxList: credentials.sort((a, b) => String(a.nome ?? "").localeCompare(String(b.nome ?? ""))) })
        this.setState({ isLoading: false })
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    onHistory = (item) => {
        this.props.onHistory(item)
    }

    render() {
        return (
            <div className="col-lg-12 card my-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todos os credenciados
                </div>
                <div className='card-body'>
                    <div className='col-lg-12'>
                        <input type="text" onChange={this.search} placeholder="Faça uma busca por nome, celular, placa, setores ou tipos..." className="form-control" />
                    </div>
                </div>
                {
                    this.state.list.length === 0 && !this.state.isLoading ?
                        <div className="card-body">
                            <EmptyContent text="Todos os credenciados" />
                        </div>
                        :
                        !this.state.isLoading ?
                            <>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td width={10} align='center'><strong>Status</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center' width={10} ><strong>Veículos</strong></td>
                                            <td><strong>Nome</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Celular</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Documento</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Endereço</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Disponibilidade</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Setores</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Tipos</strong></td>
                                            <td width={10}></td>
                                        </tr>
                                    </thead>
                                    <tbody className='table-responsive'>
                                        {
                                            this.state.list.map((item, index) =>
                                                <tr key={index}>
                                                    <Tooltip title={item.id} placement="top">
                                                        <td align='center'>
                                                            {
                                                                item.status === "Ativo" && <span className="badge text-bg-success w-100">Ativo</span>
                                                            }
                                                            {
                                                                item.status === "Inativo" && <span className="badge text-bg-danger w-100">Inativo</span>
                                                            }
                                                            {
                                                                item.status === "pending" && <span className="badge text-bg-warning text-white w-100">Pendente</span>
                                                            }
                                                        </td>
                                                    </Tooltip>
                                                    {
                                                        item.veiculos.length > 1 && <td align='center'><span className="badge text-bg-info text-white w-100">{item.veiculos.length} veículos</span></td>
                                                    }
                                                    {
                                                        item.veiculos.length === 1 && <td align='center'><span className="badge text-bg-info text-white w-100">{item.veiculos.map(e => e.placa).join(",")}</span></td>
                                                    }
                                                    <td>{item.nome}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{(item.celular && item.celular !== "undefined") ? item.celular : "-"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{(item.documento && item.documento !== "undefined") ? item.documento : "-"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{(item.endereco?.cep === null || item.endereco?.cep === undefined) ? "-" : `${item.endereco?.cep ?? ""}, ${item.endereco?.logradouro ?? ""}, ${item.endereco?.numero ?? ""}`}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{(item.acesso.horarioInicio === "00:00" && item.acesso.horarioFim === "00:00") ? "24 horas" : `Das ${item.acesso.horarioInicio} às ${item.acesso.horarioFim}`}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{(item.setores && item.setores?.length !== 0) ? item.setores?.join(", ") : "Todos"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{(item.tipos && item.tipos?.length !== 0) ? item.tipos?.join(", ") : "-"}</td>
                                                    <td>
                                                        <div className="btn-group">

                                                            <Tooltip title="Histórico de acessos" placement="top">
                                                                <button className='btn btn-warning text-white' onClick={(e) => this.onHistory(item)}>
                                                                    <QueryStatsIcon />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip role="button" title="Duplicar" placement="top">
                                                                <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                    <i className="fas fa-copy" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip role="button" title="Editar" placement="top">
                                                                <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                    <i className="fas fa-edit" />
                                                                </button>
                                                            </Tooltip>
                                                            <Tooltip role="button" title="Remover" placement="top">
                                                                <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </Tooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </>
                            :
                            <div className="card-body">
                                <Loading />
                            </div>
                }
            </div>
        )
    }
}

class CredenciadosListagemModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            estacionamentoId: getEstacionamento()?.id,
            hasAccrediteds: false
        }
        this.loadData()
    }

    loadData = async () => {
        var accrediteds = await getAllAccreditedByParkId(this.state.estacionamentoId)
        if (accrediteds.length !== 0) {
            this.setState({ hasAccrediteds: true })
            this.setState({ list: accrediteds.sort((a, b) => a.nome.localeCompare(b.nome)) })
            this.setState({ auxList: accrediteds.sort((a, b) => a.nome.localeCompare(b.nome)) })
        } else {
            this.setState({ hasAccrediteds: false })
            this.setState({ list: [] })
            this.setState({ auxList: [] })
            this.setState({ message: "Nenhum credenciado cadastrado" });
        }
        this.setState({ isLoading: false })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ list: this.state.auxList })
        } else {
            const result = this.state.auxList.filter(
                e => normalizedField(e.nome).replaceAll(" ", "").toUpperCase().includes(value) ||
                    normalizedField(e.documento).replaceAll(" ", "").toUpperCase().includes(value) ||
                    normalizedField(e.celular).replaceAll(" ", "").toUpperCase().includes(value)
            )
            this.setState({ list: result })
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    onConfirm = async (item, vehicle) => {
        item.vehicles = undefined
        item.accessCards = undefined
        this.props.onConfirm(item, vehicle)
        this.onClose()
    }

    render() {
        return (
            this.state.isLoading ?
                <Dialog maxWidth={"xs"} fullWidth onClose={this.onClose} open={true}>
                    <MuiDialogContent>
                        <Loading />
                    </MuiDialogContent>
                </Dialog> :
                <>
                    {
                        this.state.hasAccrediteds ?
                            <Dialog maxWidth={"xs"} fullWidth onClose={this.onClose} open={true}>
                                <MuiDialogTitle className='text-center'>
                                    Credenciados
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='card-body'>
                                        <input type="text" onChange={this.search} className="form-control form-control-lg" placeholder='Busque por nome ou placa...' />
                                    </div>
                                    {
                                        this.state.list.length === 0 ?
                                            <EmptyContent text="Nenhum credenciado encontrado com esta busca" />
                                            :
                                            <div className='mt-3'>
                                                {
                                                    this.state.list.map((item) =>
                                                        <AlertSuccess key={item.id} tag={(item.vagas ?? 0) > 1 ? `${item.vagas} vagas` : "1 vaga"}>
                                                            <div className='mt-2'>
                                                                <strong>Nome: </strong>{takeIfIsNotNullOrEmpty(item.nome) ?? "-"}<br />
                                                            </div>
                                                            <div className='btn-group mt-2'>
                                                                {
                                                                    !isNullOrEmpty(item.veiculos) && <>
                                                                        {
                                                                            item.veiculos.map((vehicle, index) =>
                                                                                <Tooltip key={index} title="Associar este veículo" placement="top">
                                                                                    <button type="button" className="btn btn-success" onClick={(e) => this.onConfirm(item, vehicle)}>
                                                                                        <span className='mx-2'>{vehicle.placa}</span>
                                                                                    </button>
                                                                                </Tooltip>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                                {
                                                                    this.props.showDetail &&
                                                                    <Tooltip title="Ver detalhes" placement="top">
                                                                        <a href={`/cadastrar/credenciado/?id=${item.id}`} target='_blank' className='btn btn-secondary' rel="noopener noreferrer">
                                                                            {
                                                                                isNullOrEmpty(item.veiculos) ? <>
                                                                                    <span>Credenciado sem veículos</span>
                                                                                    <i className="fas fa-eye mx-2" />
                                                                                </> : <i className="fas fa-eye" />
                                                                            }
                                                                        </a>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </AlertSuccess>
                                                    )
                                                }
                                            </div>
                                    }
                                </MuiDialogContent>
                            </Dialog>
                            :
                            <Dialog maxWidth={"xs"} fullWidth onClose={this.onClose} open={true}>
                                <MuiDialogContent>
                                    <div className="card-body">
                                        <div className='alert alert-warning'>
                                            <span className="badge text-bg-warning text-white">Atenção</span> <br />
                                            Você não tem nenhum credenciado cadastrado<br />
                                            <a href='/cadastrar/credenciado' className='btn btn-warning text-white mt-2'>Cadastrar Credenciado</a>
                                        </div>
                                    </div>
                                </MuiDialogContent>
                            </Dialog>
                    }
                </>
        )
    }
}

export { CriarCredenciado, CredenciadosListagemModal };