import React from 'react'
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent, ClientButton } from '../../../components/common/commons'
import { getRevenda, setMinimumVersions } from '../../../config/auth'
import firebase from '../../../config/firebase';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Moment from 'moment';
import TextField from '@mui/material/TextField';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import 'firebase/auth';
import 'firebase/storage';
import { reloadWithAlert, takeIfIsNotNullOrEmpty, toStringFromDate } from '../../../shared/utils';
import { Collection } from '../../../shared/constants';
import { updateDoc } from '../../../store/collections/collectionBaseWorker';
import { getAllDevicesByRevendaId } from '../../../store/collections/deviceWorker';

const initializeState = {
    id: null,
    revendaId: getRevenda()?.id,
    devices: [],
    versions: [],
    newsMessage: "",
    showModalNews: false
}

class DeveloperMinimumVersion extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
        this.load()
        this._loadDevices()
    }

    load = async () => {
        this.setState({ loading: true });
        const results = await firebase.firestore().collection('remote-config').where("key", "==", "NEW_MINIMUM_VERSION").get()
        var versions = JSON.parse(results.docs[0].data()["value"])
        this.setState({ versions: versions })
        this.setState({ id: results.docs[0].id })
        this.setState({ loading: false });
    }

    _loadDevices = async () => {
        this.setState({ loadingDevices: true })
        const devices = await getAllDevicesByRevendaId({ revendaId: this.state.revendaId })
        this.setState({ devices: devices })
        this.setState({ loadingDevices: false })
    }

    handlerLimitDate = (value, index) => {
        const minimum = Moment(value).format("DD/MM/yyyy")
        this.state.versions[index].limited_date = minimum
        this.setState({ versions: this.state.versions })
    }

    handlerMinimumVersion = (value, index) => {
        this.state.versions[index].minimum = value
        this.setState({ versions: this.state.versions })
    }

    handlerActualVersion = (value, index) => {
        this.state.versions[index].actual = value
        this.setState({ versions: this.state.versions })
    }

    handlerNews = (value, index) => {
        this.state.versions[index].newsMessage = value
        this.setState({ newsMessage: this.state.newsMessage })
    }

    openModalWhatIsNews = (value, index) => {
        this.setState({ newsMessage: value })
        this.setState({ currentIndex: index })
        this.setState({ showModalNews: true })
    }

    saveWhatIsNews = () => {
        const index = this.state.currentIndex
        const newsMessage = this.state.newsMessage
        this.state.versions[index].newsMessage = newsMessage
        this.setState({ showModalNews: false })
    }

    saveWhatIsNewsAll = () => {
        for (let index = 0; index < this.state.versions.length; index++) {
            const newsMessage = this.state.newsMessage
            this.state.versions[index].newsMessage = newsMessage
            this.setState({ showModalNews: false })
        }
    }

    update = async () => {
        setMinimumVersions(this.state.versions)
        const data = {
            value: JSON.stringify(this.state.versions)
        }
        await updateDoc({ collection: Collection.REMOTE_CONFIG, id: this.state.id, data: data })
        reloadWithAlert("Versão miníma alterada com sucesso!")
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Versões minímas
                    </div>
                    {
                        this.state.versions.length === 0 && this.state.loading === false ?
                            <EmptyContent text='Nenhuma versão mínima cadastrada' />
                            :
                            this.state.loading === false ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plataforma</th>
                                                <th scope="col">Versão atual</th>
                                                <th scope="col">Versão miníma</th>
                                                <th scope="col">Data limite</th>
                                                <th scope="col" width={10}>Novidade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.versions.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            {item.store?.platform?.includes("pagSeguro") && 'PagSeguro'}
                                                            {item.store?.platform?.includes("cielo") && 'Cielo'}
                                                            {item.store?.platform?.includes("getnet") && 'GetNet'}
                                                            {item.store?.platform?.includes("Stone") && 'Stone'}
                                                            {item.store?.platform?.includes("safra") && 'Safra'}
                                                            {item.store?.platform?.includes("store") && 'Google Play'}
                                                            {item.store?.platform?.includes("rede") && 'Rede'}
                                                            {item.store?.platform?.includes("site") && 'Site'}
                                                        </td>
                                                        <td>
                                                            <input type="text" onChange={(e) => this.handlerActualVersion(e.target.value, index)} value={item.actual} className="form-control" />
                                                        </td>
                                                        <td>
                                                            <input type="text" onChange={(e) => this.handlerMinimumVersion(e.target.value, index)} value={item.minimum} className="form-control" />
                                                        </td>
                                                        <td>
                                                            <MobileDatePicker
                                                                label="Data limite"
                                                                inputFormat="DD/MM/yyyy"
                                                                value={Moment(item.limited_date, "DD/MM/yyyy")}
                                                                onChange={(e) => { this.handlerLimitDate(e, index) }}
                                                                renderInput={(params) => <TextField {...params} size="small" className='btn-block' />}
                                                            />
                                                        </td>
                                                        <td width={10}>
                                                            <button type="button" onClick={(e) => this.openModalWhatIsNews(item.newsMessage, index)} className="btn btn-success">Alterar</button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <div className="card-footer">
                                        <button type="button" onClick={this.update} className="btn btn-primary">Salvar alterações</button>
                                    </div>
                                    <Dialog maxWidth={"lg"} onClose={e => this.setState({ showModalNews: false })} open={this.state.showModalNews}>
                                        <MuiDialogTitle className='text-center'>
                                            Novidades da versão
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div className="alert alert-secondary" role="alert">
                                                Informe aos clientes as novidades que estarão nesta versão.
                                            </div>
                                            <textarea className='form-control' rows='10' cols={10} value={this.state.newsMessage} onChange={(e) => this.setState({ newsMessage: e.target.value })} />
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className="btn-group m-3">
                                                <button onClick={this.saveWhatIsNewsAll} className='btn btn-warning text-white'>Confirmar para todos</button>
                                                <button onClick={this.saveWhatIsNews} className='btn btn-primary'>Confirmar</button>
                                            </div>
                                        </MuiDialogActions>
                                    </Dialog>
                                </div>
                                :
                                <Loading />
                    }
                </div>
                <div className='card mb-4'>
                    <div className='card-header d-flex justify-content-between align-items-center'>
                        Dispositivos
                    </div>
                    {
                        this.state.devices.length === 0 && !this.state.loadingDevices ?
                            <EmptyContent text='Nenhum dispositivo cadastrado' />
                            :
                            this.state.loadingDevices === false ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Modelo</strong></td>
                                                        <td><strong>Usuário logado</strong></td>
                                                        <td><strong>Bateria</strong></td>
                                                        <td><strong>TEF</strong></td>
                                                        <td><strong>Modo de uso</strong></td>
                                                        <td align='center'><strong>Atualizado</strong></td>
                                                        <td align='right' />
                                                        <td align='center' width='10' />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.devices.map((item, index) =>
                                                            <tr key={index}>

                                                                <td>{item.model ?? "Não informado"}</td>
                                                                <td>{item.currentUser?.nome ?? "Não informado"}</td>
                                                                <td>{item.batery != "-" ? <span>{item.batery}%</span> : "Não disponível"}</td>
                                                                <td>{takeIfIsNotNullOrEmpty(item.tefName) ?? "Padrão aparelho"}</td>
                                                                <td>
                                                                    <span>{!item.useMode && "Estacionamento"}</span>
                                                                    <span>{item.useMode === "SALES" && "Vendas"}</span>
                                                                    <span>{item.useMode === "PARK" && "Estacionamento"}</span>
                                                                    <span>{item.useMode === "NOTES" && "Emissor de notas"}</span>
                                                                    <span>{item.useMode === "TOTEM" && "Auto atendimento"}</span>
                                                                    <span>{item.useMode === "AUTHENTICATOR" && "Autenticador"}</span>
                                                                </td>
                                                                <td align='center'>{toStringFromDate({ date: item.updatedAt, format: "DD/MM/YYYY [às] HH:mm" })}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        <ClientButton item={item} />
                                                                    </div>
                                                                </td>
                                                                <td className="d-none d-sm-table-cell" align='left' width={10}>
                                                                    <Tooltip title={item.id}>
                                                                        <span className="badge text-bg-success mx-2 w-100">
                                                                            <span> {item.version} | </span>
                                                                            {item.flavor?.toLowerCase()?.includes("pagSeguro") && ('PagSeguro')}
                                                                            {item.flavor?.toLowerCase()?.includes("cielo") && ('Cielo')}
                                                                            {item.flavor?.toLowerCase()?.includes("getnet") && ('GetNet')}
                                                                            {item.flavor?.toLowerCase()?.includes("Stone") && ('Stone')}
                                                                            {item.flavor?.toLowerCase()?.includes("safra") && ('Safra')}
                                                                            {item.flavor?.toLowerCase()?.includes("store") && ('Google Play')}
                                                                            {item.flavor?.toLowerCase()?.includes("rede") && ('Rede')}
                                                                            {item.flavor?.toLowerCase()?.includes("site") && 'Site'}
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </NavBar>
        )
    }
}

export { DeveloperMinimumVersion };