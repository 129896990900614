import React from 'react'
import { NavBar } from '../../components/navbar/index.js'
import { Loading, EmptyContent, DateRangePickerDialog } from '../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getCliente, getEstacionamento, getRevenda, isAdministrador } from '../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { cellphoneMask, copyLinkAndShare, dangerConfirm, defaultConfirm, documentMask, generateUUIDV7, goToFirebase, goToNewWindow, isNullOrEmpty, permanenceBetweenDates, queryString, reloadWithAlert, setPathWindow, successConfirm, takeIfIsNotNullOrEmpty, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning, toCurrency, toStringFromDate } from '../../shared/utils.js';
import { goToWindow } from '../../shared/utils.js';
import { removeEventCouponById } from '../../store/collections/eventCouponWorker.js';
import { sendLogByUser } from '../../shared/analytics.js';
import { getAllProductsByIds } from '../../store/collections/productWorker.js';
import { getAllActivePaymentTables } from '../../store/collections/paymentTableWorker.js';
import { addBooking, getAllBookingsByParkId, getBookingById, updateBookingById } from '../../store/collections/bookingWorker.js';
import { AlertDanger, AlertSecondary, AlertSuccess, AlertWarning } from '../../shared/alert-manager.js';
import { getAllBookingsApointmentByIdAndParkId, getAllBookingsApointmentByParkId, getBookingsApointmentById, removeBookingApointmentById } from '../../store/collections/bookingApointmentsWorker.js';
import { Collection } from '../../shared/constants.js';
import { getParkById } from '../../store/collections/parkWorker.js';
import { WhatsAppMessageListBody } from '../developer/messages/index.js';
import { sendMessageByWhatsApp } from '../../managers/whatsApp/whatsapp.manager.js';
import { fetchFunction } from '../../shared/function-request.js';
import { getParameterByParkId } from '../../store/collections/parameterWorker.js';
import NumberFormat from 'react-number-format';
import { getRevendaById } from '../../store/collections/revendaWorker.js';

const initializeState = {
    isAdministrador: isAdministrador(),
    id: queryString("id"),
    startDate: queryString("startDate") ? new Moment(queryString("startDate"), "DD/MM/YYYY") : new Moment(),
    endDate: queryString("endDate") ? new Moment(queryString("endDate"), "DD/MM/YYYY") : new Moment(),
    buttonTitle: "Carregando...",
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    revendaId: getEstacionamento()?.revendaId,
    name: '',
    description: '',
    status: "ativo",
    passagersQuantityEnabled: false,
    minimumTime: 24,
    products: [],
    paymentTables: [],
    auxItems: [],
    totalToday: 0,
    appointment: null,
    isShowingAppointmentModal: false
}

class BookingList extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <NavBar>
                <BookingListBody />
            </NavBar>
        )
    }
}

class BookingListBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = initializeState
    }

    componentDidMount = async () => {
        await this.load(true)
        this.loadAppointmentDetailIfNeed()
    }

    load = async (isLoading) => {
        this.setupPathAndButton()
        this.setState({ isLoading: isLoading })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameter: parameter })
        var items = await getAllBookingsApointmentByParkId({
            estacionamentoId: this.state.estacionamentoId,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        })
        this.setState({ items: items })
        this.setState({ auxItems: items })
        this.setState({ isLoading: false })

        this.setState({ totalToday: items.filter(e => Moment(e.startDate.seconds * 1000).isSame(Moment().startOf('day'), 'day')).length })
        this.setState({ totalTomorrow: items.filter(e => Moment(e.startDate.seconds * 1000).isSame(Moment().startOf('day').add(1, 'day'), 'day')).length })
        this.setState({ totalPaid: items.filter(e => e.status === "paid").length })
    }

    setupPathAndButton = () => {
        const appointmentId = queryString("appointmentId")
        const startDate = this.state.startDate.format("DD/MM/YYYY")
        const endDate = this.state.endDate.format("DD/MM/YYYY")
        if (!isNullOrEmpty(this.state.startDate) && !isNullOrEmpty(this.state.endDate)) {
            if (appointmentId) {
                setPathWindow(`/cadastrar/reserva/?appointmentId=${appointmentId}&startDate=${startDate}&endDate=${endDate}`)
            } else {
                setPathWindow(`/cadastrar/reserva/?startDate=${startDate}&endDate=${endDate}`)
            }
            const today = Moment().format("DD/MM/YYYY")
            if (startDate === today && endDate === today) {
                this.setState({ buttonTitle: "Reservas de hoje" })
            } else if (startDate === endDate) {
                this.setState({ buttonTitle: `Reservas de ${endDate}` })
            } else {
                this.setState({ buttonTitle: `Reservas de ${startDate} até ${endDate}` })
            }
        } else {
            this.setState({ buttonTitle: "Reservas de hoje" })
        }
    }

    loadAppointmentDetailIfNeed = async () => {
        const id = queryString("appointmentId")
        if (!isNullOrEmpty(id)) {
            toastLoading("Aguarde...")
            const appointment = await getBookingsApointmentById({ id: id })
            toastDismissLoading()
            if (appointment) {
                this.setState({ appointment: appointment })
                this.setState({ isShowingAppointmentModal: true })
            } else {
                toastWarning("Agendamento não encontrado.")
            }
        }
    }

    showDetail = (item) => {
        this.setState({ appointment: item })
        this.setState({ isShowingAppointmentModal: true })
    }

    newBooking = async () => {
        toastLoading("Aguarde...")
        this.cleanFields()
        this.loadPaymentTables()
        var items = await getAllBookingsByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (items.length === 1) {
            await this.onEdit(items[0])
        }
        toastDismissLoading()
        this.setState({ isShowingBookingModal: true })
    }

    showWhatsAppModal = async () => {
        toastLoading("Aguarde...")
        var items = await getAllBookingsByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (items.length === 1) {
            toastDismissLoading()
            this.setState({ currentItem: items[0] })
            this.setState({ isShowingWhatsAppModal: true })
        } else {
            toastWarning("É preciso ter uma reserva configurada para enviar o link via o WhatsApp.")
            this.newBooking()
        }
    }

    hideWhatsAppModal = () => {
        this.setState({ isShowingWhatsAppModal: false })
    }

    confirmWhatsAppLink = async () => {
        if (isNullOrEmpty(this.state.whatsAppNumber)) {
            toastWarning("Preencha o número do WhatsApp.")
            return
        }
        var confirm = await successConfirm({ message: `Deseja mesmo enviar o link de cadastro via WhatsApp?` })
        if (confirm) {
            this.setState({ isShowingWhatsAppModal: false })
            toastLoading("Registrando mensagem...")
            const item = this.state.currentItem
            const park = await getParkById({ id: item.estacionamentoId })
            const resale = await getRevendaById({ id: park.revendaId })
            await sendMessageByWhatsApp({
                estacionamentoId: item.estacionamentoId,
                phone: this.state.whatsAppNumber,
                message: `{{PARK_NAME}}\n\nOlá, ficamos felizes em ter você como cliente, faça o cadastro abaixo da sua reserva:\n\n${resale.siteTicket}/reserva/agendar/?id=${item.id}`
            })
            toastDismissLoading()
            toastSuccess("Mensagem registrada para envio via WhatsApp.")
        }

    }

    loadPaymentTables = async (paymentTablesIds) => {
        this.cleanFields()
        const paymentTables = await getAllActivePaymentTables({ estacionamentoId: this.state.estacionamentoId })
        this.setState({
            paymentTables: paymentTables.map(e => {
                return {
                    ...e,
                    isChecked: paymentTablesIds?.includes(e.id)
                }
            })
        })
    }

    goTickets = (item) => {
        goToWindow("/reserva/agendamentos/?id=" + item.id)
    }

    onEdit = async (item) => {
        this.setState({ ...item })
        await this.loadPaymentTables(item.paymentTablesIds)
        this.setState({ id: item.id })
        this.setState({ name: item.name })
        this.setState({ description: item.description })
        this.setState({ isShowingBookingModal: true })
        this.setState({ passagersQuantityEnabled: item.passagersQuantityEnabled })
        this.setState({ minimumTime: item.minimumTime })
    }

    onDuplicate = (item) => {
        item.id = null
        this.onEdit(item)
    }

    save = async () => {
        if (!this.checkFields()) {
            return
        }
        toastLoading("Salvando nova reserva...")
        const data = this.retriveData()
        if (isNullOrEmpty(this.state.id)) {
            await addBooking({ data: data })
        } else {
            await updateBookingById({ id: this.state.id, data: data })
        }
        toastDismissLoading()
        toastSuccess("Reserva salva com sucesso!")
        this.setState({ isShowingBookingModal: false })
        await this.load()
        this.cleanFields()
    }

    cleanFields = () => {
        // this.setState({ ...initializeState })
    }

    retriveData = () => {
        var data = {
            id: this.state.id ?? generateUUIDV7(),
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            revendaId: this.state.revendaId,
            name: this.state.name,
            status: this.state.status,
            description: this.state.description,
            passagersQuantityEnabled: this.state.passagersQuantityEnabled,
            paymentTablesIds: this.state.paymentTables.filter(e => e.isChecked).map(e => e.id),
            section1: this.state.section1,
            section2: this.state.section2,
            minimumTime: parseInt(this.state.minimumTime) ?? 24,
        }
        return data
    }

    checkFields = () => {
        if (isNullOrEmpty(this.state.name)) {
            toastWarning("Prencha o nome da reserva.")
            return false
        }
        if (isNullOrEmpty(this.state.section1)) {
            toastWarning("Titulo sessão principal.")
            return false
        }
        const checked = this.state.paymentTables.filter(e => e.isChecked)
        if (checked.length === 0) {
            toastWarning("Prencha pelo menos uma tabela de preço.")
            return false
        }
        return true
    }

    onRemove = async (item) => {
        var confirm = await dangerConfirm({ message: `Confirma mesmo a remoção deste agendamento?` })
        if (confirm) {
            toastLoading("Removendo agendamento...")
            await removeBookingApointmentById({ id: item.id })
            await this.load()
            toastDismissLoading()
            toastSuccess("Agendamento removido com sucesso.")
        }
    }

    showCancelPaymentModal = async (item) => {
        this.setState({ cancelPaymentState: "fill" })
        this.setState({ currentItem: item })
        this.setState({ isShowingCancelOnlinePayment: true })
    }

    hideCancelPaymentModal = () => {
        this.setState({ isShowingCancelOnlinePayment: false })
    }

    confirmCancelPayment = async () => {
        this.setState({ cancelPaymentState: "loading" })
        const item = this.state.currentItem
        const option = {
            method: "POST"
        }
        const response = await fetchFunction(`/gateway/payment/booking/appointment/${item.id}/refund`, option, this.state.parameter.X_API_KEY)
        if (response.success) {
            await this.load()
            toastSuccess(response.message)
            this.setState({ isShowingCancelOnlinePayment: false })
        } else {
            toastError(response.message)
            this.setState({ cancelPaymentState: "fill" })
        }
    }

    changeCheckedPaymentTable = (item, isChecked) => {
        item.isChecked = isChecked
        this.setState({ paymentTables: this.state.paymentTables })
    }

    showProductsByPaymentTable = async (item) => {
        if (isNullOrEmpty(item.ofertasProdutosIds)) {
            toastWarning("Nenhum produto associado a esta tabela de preço.")
        } else {
            toastLoading("Aguarde...")
            const ids = item.ofertasProdutosIds
            const products = await getAllProductsByIds({ ids: ids })
            toastDismissLoading()
            if (!isNullOrEmpty(products)) {
                this.setState({ products: products })
                this.setState({ isShowingProductsModal: true })
            } else {
                toastWarning("Nenhum produto encontrado.")
            }
        }
    }

    copyChargeLink = (item) => {
        const revenda = getRevenda()
        const url = `${revenda.siteTicket}/reserva/agendamento/?id=${item.id}`
        copyLinkAndShare({
            title: item.clienteNome,
            message: "Reserva",
            url: url,
        })
        const message = `Tocou em copiar link da ${item.nome} reserva.`
        sendLogByUser("Reservas", message)
    }

    sendBillByWhatsApp = async (item) => {
        const customer = item.customer
        var confirm = await successConfirm({ message: `Deseja mesmo enviar uma mensagem com o link do agendamento para o cliente ${customer.name}?` })
        if (confirm) {
            toastLoading("Registrando mensagem...")
            const park = await getParkById({ id: item.estacionamentoId })
            const resale = getRevenda()
            const message = `*${park.nome}*\n\nOlá ${customer.name}, aqui esta o link da sua reserva:\n\n${resale.siteTicket}/reserva/agendamento/?id=${item.id}`
            await sendMessageByWhatsApp({
                estacionamentoId: item.estacionamentoId,
                referenceId: item.id,
                phone: customer.cellphone,
                message: message
            })
            toastDismissLoading()
            toastSuccess("Registrada para envio com sucesso, em breve o cliente receberá a mensagem.")
            sendLogByUser("Detalhes Mensalistas", `Tocou em enviar link via WhatsApp em um agendamento para o cliente ${customer.name} | ${customer.document}`, item)
        }
    }

    showMessagesSent = async (item) => {
        this.setState({ referenceId: item.id })
        this.setState({ showWhatsAppMessagesModal: true })
    }

    hideWhatsAppMessages = () => {
        this.setState({ showWhatsAppMessagesModal: false })
    }

    closeAppointmentModal = () => {
        setPathWindow("/cadastrar/reserva")
        this.setState({ isShowingAppointmentModal: false })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ items: this.state.auxItems })
        } else {
            const result = this.state.auxItems.filter(e => e.customer?.name.toUpperCase().includes(value) ||
                e.customer?.document.toUpperCase().includes(value) ||
                e.customer?.cellphone.toUpperCase().includes(value) ||
                e.vehicles.join(", ").toUpperCase().includes(value) ||
                Moment(e.startDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm").toUpperCase().includes(value) ||
                Moment(e.endDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm").toUpperCase().includes(value))
            this.setState({ items: result })
        }
    }

    showRangeDate = () => {
        this.setState({ isRangeDateOpen: true })
    }

    hideRangeDate = () => {
        this.setState({ isRangeDateOpen: false })
    }

    applyDates = ({ startDate, endDate }) => {
        if (startDate && endDate) {
            this.setState({ startDate: startDate, endDate: endDate }, () => {
                this.load(true)
            })
        } else {
            this.setState({ startDate: startDate, endDate: endDate }, () => {
                this.load(true)
            })
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Agendamentos
                    <div className='btn-group'>
                        <button type="button" onClick={() => { this.showRangeDate() }} className="btn btn-secondary">
                            <i className="fas fa-calendar-alt mx-2" />
                            {this.state.buttonTitle}
                        </button>
                        <button type="button" onClick={() => { this.showWhatsAppModal() }} className="btn btn-success">
                            <i className="fa-brands fa-whatsapp mx-2" />
                            Enviar link via WhatsApp
                        </button>
                        <button type="button" onClick={() => { this.newBooking() }} className="btn btn-primary">
                            <i className="fa-solid fa-gear mx-2" />
                            Configurar
                        </button>
                    </div>
                </div>
                <div className="mb-4">
                    {
                        !this.state.isLoading &&
                        <div className='card-body'>
                            <div className='col-lg-12'>
                                <input type="text" onChange={this.search} placeholder="Faça uma busca por nome, celular, email, vencimento ou plano..." className="form-control" />
                            </div>
                        </div>
                    }
                    {
                        this.state.items?.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Nenhuma reserva cadastrada.' />
                            :
                            !this.state.isLoading ?
                                <div>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        {
                                                            this.state.isAdministrador &&
                                                            <td width={10} />
                                                        }
                                                        <td width={10} />
                                                        <td width={10} />
                                                        <td align='left'><strong>Cliente</strong></td>
                                                        <td align='center'><strong>Celular</strong></td>
                                                        <td align='center'><strong>Veículo</strong></td>
                                                        <td align='center'><strong>Entrada</strong></td>
                                                        <td align='center'><strong>Saída</strong></td>
                                                        <td align='center'><strong>Passageiros</strong></td>
                                                        <td align='right'><strong>Valor</strong></td>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items?.map((item, index) =>
                                                            <tr key={index}>
                                                                {
                                                                    this.state.isAdministrador &&
                                                                    <td className="d-none d-sm-table-cell">
                                                                        <div className='btn-group'>
                                                                            <Tooltip title="Firebase" placement="top">
                                                                                <button type="button" onClick={() => { goToFirebase({ collection: Collection.BOOKINGS_APPOINTMENTS, id: item.id }) }} className="btn btn-warning text-white">
                                                                                    <i className="fas fa-database" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                }
                                                                <td align='left'>
                                                                    <div className='btn-group'>
                                                                        <Tooltip title="Mensagens enviadas" placement="top">
                                                                            <button type="button" onClick={() => { this.showMessagesSent(item) }} className="btn btn-success">
                                                                                <i className="fas fa-comments" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Copiar link" placement="top">
                                                                            <button onClick={(e) => this.copyChargeLink(item)} className="btn btn-success text-white">
                                                                                <i className="fas fa-share-alt" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        {
                                                                            item.status !== "used" && <>
                                                                                {
                                                                                    isNullOrEmpty(item.customer.cellphone) ?
                                                                                        <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                                            <button type="button" onClick={() => { toastWarning("Este cliente não tem número de celular cadastrado para receber a mensagem via WhatsApp.") }} className="btn btn-danger">
                                                                                                <i className="fa-brands fa-whatsapp" />
                                                                                            </button>
                                                                                        </Tooltip> :
                                                                                        <Tooltip title="Enviar no WhatsApp" placement="top">
                                                                                            <button type="button" onClick={() => { this.sendBillByWhatsApp(item) }} className="btn btn-success">
                                                                                                <i className="fa-brands fa-whatsapp" />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td align='left'>
                                                                    <Tooltip title={item.id} placement="top">
                                                                        <span>
                                                                            {
                                                                                item.status == "paid" && <span className="badge text-bg-success w-100">Pago</span>
                                                                            }
                                                                            {
                                                                                item.status == "pending" && <span className="badge text-bg-warning text-white w-100">Pendente</span>
                                                                            }
                                                                            {
                                                                                item.status == "canceled" && <span className="badge text-bg-danger w-100">Cancelado</span>
                                                                            }
                                                                            {
                                                                                item.status == "expired" && <span className="badge text-bg-danger w-100">Expirado</span>
                                                                            }
                                                                            {
                                                                                item.status == "parked" && <span className="badge text-bg-success w-100">Estacionado</span>
                                                                            }
                                                                            {
                                                                                item.status == "used" && <span className="badge text-bg-primary w-100">Usado</span>
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td align='left'>{item.customer?.name}</td>
                                                                <td align='center'>{cellphoneMask(item.customer?.cellphone)}</td>
                                                                <td align='center'>{item.vehicles.join(", ")}</td>
                                                                <td align='center'>{toStringFromDate({ date: item.startDate, format: "DD/MM/YYYY [às] HH:mm" })}</td>
                                                                <td align='center'>{toStringFromDate({ date: item.endDate, format: "DD/MM/YYYY [às] HH:mm" })}</td>
                                                                <td align='center'>{item.passagers}</td>
                                                                <td align='right'>{toCurrency(item.resume?.totalValue)}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        {
                                                                            item.status === "used" &&
                                                                            <Tooltip title="Visualizar ticket" placement="top">
                                                                                <a href={`/buscar/ticket?id=${item.ticket?.id}`} className="btn btn-primary text-white">
                                                                                    <i className="fas fa-ticket-alt" />
                                                                                </a>
                                                                            </Tooltip>
                                                                        }
                                                                        <Tooltip title="Detalhes" placement="top">
                                                                            <button className='btn btn-primary text-white' onClick={(e) => this.showDetail(item)}>
                                                                                <i className="fas fa-eye" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        {
                                                                            item.status !== "paid" &&
                                                                            <Tooltip title="Remover" placement="top">
                                                                                <button className='btn btn-danger text-white' onClick={(e) => this.onRemove(item)}>
                                                                                    <i className="fas fa-trash" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                        {
                                                                            item.status === "paid" && item.pixQRCode &&
                                                                            <Tooltip title="Cancelar pagamento" placement="top">
                                                                                <button onClick={(e) => this.showCancelPaymentModal(item)} className="btn btn-danger">
                                                                                    <i className="fas fa-eraser" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className='row mx-2'>
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-success mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Previsão hoje</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {this.state.totalToday}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-primary mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Previsão amanhã</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {this.state.totalTomorrow}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-success mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Agendamentos</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {this.state.totalPaid}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                    {
                        this.state.isRangeDateOpen &&
                        <DateRangePickerDialog
                            onClose={this.hideRangeDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onConfirm={this.applyDates} />
                    }
                    <Dialog fullWidth maxWidth="xs" onClose={this.hideWhatsAppModal} open={this.state.isShowingWhatsAppModal}>
                        <MuiDialogContent>
                            <div className='mt-2'>
                                <AlertSuccess tag="Link via WhatsApp">
                                    <div className='mt-'>
                                        Iremos enviar o link via WhatsApp para que o cliente faça uma reserva.
                                    </div>
                                </AlertSuccess>
                            </div>
                            <label>Número do WhatsApp</label>
                            <NumberFormat className="form-control form-control-lg" value={this.state.whatsAppNumber} mask='_' onChange={(e) => this.setState({ whatsAppNumber: e.target.value })} format="(##)#####-####" />
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.hideWhatsAppModal} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.confirmWhatsAppLink} className='btn btn-success'>
                                    <i className="fa-solid fa-paper-plane mx-2" />
                                    Enviar
                                </button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>

                    <Dialog fullWidth maxWidth="xs" open={this.state.isShowingCancelOnlinePayment}>
                        {
                            this.state.cancelPaymentState === "loading" && <div className='text-center'>
                                <Loading />
                            </div>
                        }
                        {
                            this.state.cancelPaymentState === "fill" && <>
                                <MuiDialogTitle className='text-center'>
                                    <h5>Deseja mesmo estornar este pagamento?</h5>
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='row'>
                                        <label>Data do pagamento</label><br />
                                        <h5>{toStringFromDate({ date: this.state.currentItem?.payment?.dataPagamento, format: "DD/MM/YYYY [às] HH:mm:ss A" })}</h5>
                                        <label>Valor</label><br />
                                        <h5>{toCurrency(this.state.currentItem?.payment?.total)}</h5>
                                        {
                                            isAdministrador() &&
                                            <AlertDanger tag="Administrativo">
                                                <label>Integradora</label><br />
                                                <h5>{this.state.currentItem?.pixQRCode?.gateway}</h5>
                                            </AlertDanger>
                                        }
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={this.hideCancelPaymentModal} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={this.confirmCancelPayment} className='btn btn-danger'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </>
                        }
                    </Dialog>
                    <Dialog fullWidth maxWidth={"sm"} onClose={this.closeAppointmentModal} open={this.state.isShowingAppointmentModal}>
                        <MuiDialogTitle className='text-center'>
                            Detalhes do agendamento
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <span>
                                {
                                    this.state.appointment?.status == "paid" && <h4><span className="badge text-bg-success">Pago</span></h4>
                                }
                                {
                                    this.state.appointment?.status == "pending" && <h4><span className="badge text-bg-warning text-white">Pendente</span></h4>
                                }
                                {
                                    this.state.appointment?.status == "canceled" && <h4><span className="badge text-bg-danger">Cancelado</span></h4>
                                }
                                {
                                    this.state.appointment?.status == "expired" && <h4><span className="badge text-bg-danger">Expirado</span></h4>
                                }
                                {
                                    this.state.appointment?.status == "used" && <h4> <span className="badge text-bg-primary">Usado</span></h4>
                                }
                            </span>
                            <h5>Cliente</h5>
                            <div className='row'>
                                <label>Nome: {this.state.appointment?.customer?.name}</label>
                                <label>Documento: {documentMask(this.state.appointment?.customer?.document)}</label>
                                <label>Celular: {cellphoneMask(this.state.appointment?.customer?.cellphone)}</label>
                                {
                                    this.state.appointment?.passagers > 0 &&
                                    <label>Passageiros: {this.state.appointment?.passagers}</label>
                                }
                            </div>
                            <div className='mt-3'>
                                <h5>Datas</h5>
                                <div className='row'>
                                    <label>Data de entrada: {toStringFromDate({ date: this.state.appointment?.startDate, format: "DD/MM/YYYY [às] HH:mm" })}</label>
                                    <label>Data de saída: {toStringFromDate({ date: this.state.appointment?.endDate, format: "DD/MM/YYYY [às] HH:mm" })}</label>
                                    <label>Tempo de permanência: {permanenceBetweenDates({ startDate: Moment(this.state.appointment?.startDate.seconds * 1000), endDate: Moment(this.state?.appointment?.endDate.seconds * 1000) })}</label>
                                    <label>Data da compra: {toStringFromDate({ date: this.state.appointment?.createdAt, format: "DD/MM/YYYY [às] HH:mm" })}</label>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <h5>Veículo</h5>
                                <div className='row'>
                                    <label>Placa: {this.state.appointment?.vehicles.join(", ")}</label>
                                </div>
                            </div>
                            {
                                !isNullOrEmpty(this.state.appointment?.resume?.products) &&
                                <div className='mt-3'>
                                    <h5>Produtos</h5>
                                    <div className='row'>

                                        {
                                            this.state.appointment?.resume?.products?.map((product, index) => {
                                                return (
                                                    <label key={`produto_${index}`}>{product.name}: {toCurrency(product.value)}</label>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.state.appointment?.status !== "pending" && <div className='col-lg-12 mt-2'>

                                    {
                                        this.state.appointment?.newNote?.currentStatus === "NOTE_GENERATED" &&
                                        <Tooltip title={this.state.newNote?.id} placement="top">
                                            <AlertSuccess tag="Nota Fiscal">
                                                <div className='mt-2'>
                                                    {
                                                        (this.state.newNote?.pdf_nfse) &&
                                                        <Tooltip title="Visualizar PDF" placement="top">
                                                            <a href={(this.state.appointment?.newNote?.pdf_nfse)} target='_blank' className="btn btn-success">
                                                                <i className="fas fa-file-pdf" />
                                                            </a>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        (this.state.appointment?.newNote?.xml) &&
                                                        <Tooltip title="Visualizar XML" placement="top">
                                                            <a href={(this.state.appointment?.newNote?.xml)} target='_blank' className="btn btn-success mx-2">
                                                                <i className="fas fa-file-code" />
                                                            </a>
                                                        </Tooltip>
                                                    }
                                                    <span className='mx-2'>Existe uma nota fiscal emitida para este agendamento.</span>
                                                </div>
                                            </AlertSuccess>
                                        </Tooltip>
                                    }
                                    {
                                        this.state.appointment?.newNote?.currentStatus === "NOTE_PROCESSING" &&
                                        <AlertWarning tag="Nota Fiscal">
                                            <div className='mt-2'>
                                                <Tooltip title="Visualizar" placement="top">
                                                    <a href={`/nota-fiscal/listar/?id=${this.state.appointment?.newNote?.id}`} target='_blank' className="btn btn-warning text-white me-2">
                                                        <i className="fas fa-search" />
                                                    </a>
                                                </Tooltip>
                                                Existe uma nota fiscal em processamento para este agendamento.
                                            </div>
                                        </AlertWarning>
                                    }
                                    {
                                        this.state.appointment?.newNote?.currentStatus === "NOTE_ERROR" &&
                                        <AlertDanger tag="Nota Fiscal">
                                            <div className='mt-2'>
                                                <Tooltip title="Visualizar" placement="top">
                                                    <a href={`/nota-fiscal/listar/?id=${this.state.appointment?.newNote?.id}`} target='_blank' className="btn btn-warning text-white me-2">
                                                        <i className="fas fa-search" />
                                                    </a>
                                                </Tooltip>
                                                Existe uma nota fiscal com erro para este agendamento.
                                            </div>
                                        </AlertDanger>
                                    }
                                </div>
                            }
                            <div className='mt-3'>
                                <h5>Total</h5>
                                <label>{this.state.appointment?.payment?.formaPagamento?.nome ?? "Não disponível"}</label>
                                <h2 className='text-success'>{toCurrency(this.state.appointment?.resume?.totalValue)}</h2>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                {
                                    this.state.appointment?.ticket?.id &&
                                    <a target='_blank' href={`${getRevenda().siteTicket}/buscar/ticket/?id=${this.state.appointment.ticket.id}`} className='btn btn-primary'>
                                        <i className="fas fa-eye mx-2" />
                                        Visualizar
                                    </a>
                                }
                                <button onClick={() => this.closeAppointmentModal()} className='btn btn-secondary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog fullWidth maxWidth={"sm"} open={this.state.isShowingBookingModal} onClose={(e) => this.setState({ isShowingBookingModal: false })}>
                        <MuiDialogTitle className='text-center'>
                            {isNullOrEmpty(this.state.id) ? "Cadastrar reserva" : "Editar reserva"}
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <label>Status</label>
                                    <select className='form-select' value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                        <option value="ativo">Ativa</option>
                                        <option value="inativo">Inativa</option>
                                    </select>
                                </div>
                                <div className='col-lg-9'>
                                    <label>Nome</label>
                                    <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <label>Observação</label>
                                    <textarea rows={3} onChange={(e) => this.setState({ description: e.target.value })} value={this.state.description} className="form-control" />
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-lg-12 mt-2'>
                                    <label>Titulo sessão principal</label>
                                    <input type="text" placeholder='Selecione o tipo de vaga' onChange={(e) => this.setState({ section1: e.target.value })} value={this.state.section1} className="form-control" />
                                </div>
                                <div className='col-lg-12 mt-2'>
                                    <label>Titulo sessão secundária</label>
                                    <input type="text" placeholder='Personalize sua experiência' onChange={(e) => this.setState({ section2: e.target.value })} value={this.state.section2} className="form-control" />
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    <AlertSecondary tag="Configurações">
                                        <div className="form-check form-switch mt-2">
                                            <input className="form-check-input" id="passagersQuantityEnabled" checked={this.state.passagersQuantityEnabled} onChange={(e) => this.setState({ passagersQuantityEnabled: e.target.checked })} type="checkbox" />
                                            <label className="form-check-label" htmlFor="passagersQuantityEnabled">
                                                Solicitar quantidade de passageiros.
                                            </label>
                                        </div>
                                        <div className='col-lg-6'>
                                            <label>Tempo mínimo de estadia</label>
                                            <select className="form-select" value={this.state.minimumTime} onChange={(e) => this.setState({ minimumTime: e.target.value })}>
                                                <option value='0'>Sem limite minímo</option>
                                                <option value='5'>5 horas</option>
                                                <option value='12'>12 horas</option>
                                                <option value='24'>1 dia</option>
                                                <option value='48'>2 dias</option>
                                                <option value='72'>3 dias</option>
                                                <option value='96'>4 dias</option>
                                            </select>
                                        </div>
                                    </AlertSecondary>
                                </div>
                                <div className='col-lg-12 mt-3'>
                                    {
                                        this.state.paymentTables.length > 0 && <>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td width={10} />
                                                            <td align='left'><strong>Nome</strong></td>
                                                            <td align='center'><strong>Período máximo</strong></td>
                                                            <td align='right'><strong>Valor por período</strong></td>
                                                            <td width={10} />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.paymentTables.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td align='left'>
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" id="exportFieldType" checked={item.isChecked ?? false} onChange={(e) => this.changeCheckedPaymentTable(item, e.target.checked)} type="checkbox" value={item.id} />
                                                                        </div>
                                                                    </td>
                                                                    <td align='left'>{item.nome ?? "Não informado"}</td>
                                                                    <td align='center'>{item.periodoMaximo ?? 0}</td>
                                                                    <td align='right'>{toCurrency(item.valorMaximo ?? 0)}</td>
                                                                    <td>
                                                                        <div className="btn-group">
                                                                            <Tooltip title="Produtos associados" placement="top">
                                                                                <button type="button" onClick={(e) => this.showProductsByPaymentTable(item)} className="btn btn-sm btn-primary">
                                                                                    <i className="fab fa-buffer" />
                                                                                </button>
                                                                            </Tooltip>
                                                                            <Tooltip title="Editar" placement="top">
                                                                                <button type="button" onClick={(e) => goToNewWindow(`/cadastrar/tabela-preco/?id=${item.id}`)} className="btn btn-sm btn-secondary">
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={() => this.setState({ isShowingBookingModal: false })} className='btn btn-secondary'>Fechar</button>
                                {
                                    this.state.id &&
                                    <a target='_blank' href={`${getRevenda().siteTicket}/reserva/agendar?id=${this.state.id}`} className='btn btn-success'>
                                        <i className="fas fa-eye mx-2" />
                                        Visualizar
                                    </a>
                                }
                                <button onClick={() => this.save()} className='btn btn-success'>{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog fullWidth maxWidth="lg" onClose={this.hideWhatsAppMessages} open={this.state.showWhatsAppMessagesModal}>
                        <MuiDialogContent>
                            <WhatsAppMessageListBody referenceId={this.state.referenceId} />
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <button onClick={this.hideWhatsAppMessages} className='btn btn-secondary m-3'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog fullWidth maxWidth={"sm"} open={this.state.isShowingProductsModal} onClose={(e) => this.setState({ isShowingProductsModal: false })}>
                        <MuiDialogTitle className='text-center'>
                            Produtos associados
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td align='left'><strong>Nome</strong></td>
                                        <td align='center'><strong>Preço</strong></td>
                                        <td align='right'><strong>Quantidade</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.products.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.descricao}</td>
                                                <td align='center'>{toCurrency(item.valor)}</td>
                                                <td align='right'>{item.controlarEstoque === "FALSE" ? "N/A" : item.estoqueDisponivel}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </MuiDialogContent>
                    </Dialog>
                </div>
            </div >
        )
    }
}

class BookingAppointmentsList extends React.Component {

    render() {
        return (
            <NavBar>
                <BookingAppointmentsListBody />
            </NavBar>
        )
    }
}

class BookingAppointmentsListBody extends React.Component {

    constructor(props) {
        super(props);
        const id = queryString("id");
        if (isNullOrEmpty(id)) {
            goToWindow("/cadastrar/reserva")
        } else {
            const park = getEstacionamento()
            this.state = {
                isAdministrador: isAdministrador(),
                item: null,
                id: id,
                estacionamentoId: park?.id,
                clienteId: park?.clienteId,
                revendaId: park?.revendaId,
                items: [],
                isLoading: true
            }
        }
    }

    componentDidMount = async () => {
        await this.load(true)
    }

    load = async (isLoading) => {
        this.setState({ isLoading: isLoading })
        const booking = await getBookingById({ id: this.state.id })
        this.setState({ booking: booking })

        var items = await getAllBookingsApointmentByIdAndParkId({
            bookingId: this.state.id,
            estacionamentoId: this.state.estacionamentoId
        })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onRemove = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover o agendamento da reserva?` });
        if (confirm) {
            toastLoading("Removendo...")
            await removeEventCouponById({ id: item.id })
            sendLogByUser("Apontamento", `Removeu o agendamento ${item.id}`, item.id)
            toastDismissLoading()
            reloadWithAlert("Ingresso removido com sucesso.")
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Agendamentos da reserva {takeIfIsNotNullOrEmpty(this.state.booking?.name)}
                </div>
                <div className="mb-4">
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Nenhum agendamento encontrado para esta reserva.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10} />
                                                        <td align='left'><strong>Cliente</strong></td>
                                                        <td align='left'><strong>Documento</strong></td>
                                                        <td align='center'><strong>Data da compra</strong></td>
                                                        <td align='center'><strong>Data estadia</strong></td>
                                                        <td align='center'><strong>Total estadia</strong></td>
                                                        <td align='center'><strong>Veículos</strong></td>
                                                        <td align='right'><strong>Valor</strong></td>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                {
                                                                    this.state.isAdministrador &&
                                                                    <td className="d-none d-sm-table-cell">
                                                                        <div className='btn-group'>
                                                                            <Tooltip title="Firebase" placement="top">
                                                                                <button type="button" onClick={() => { goToFirebase({ collection: Collection.BOOKINGS_APPOINTMENTS, id: item.id }) }} className="btn btn-warning text-white">
                                                                                    <i className="fas fa-database" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </td>
                                                                }
                                                                <td align='left'>
                                                                    <Tooltip title={`${item.id} - ${item.groupId}`} placement="top">
                                                                        <span>
                                                                            {
                                                                                item.status == "paid" && <span className="badge text-bg-success w-100">Pago</span>
                                                                            }
                                                                            {
                                                                                item.status == "pending" && <span className="badge text-bg-warning w-100 text-white">Pendente</span>
                                                                            }
                                                                            {
                                                                                item.status == "expired" && <span className="badge text-bg-warning w-100 text-white">Expirado</span>
                                                                            }
                                                                            {
                                                                                item.status == "canceled" && <span className="badge text-bg-danger w-100 text-white">Cancelado</span>
                                                                            }
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td align='left'>{item.customer.name ?? "Não informado"}</td>
                                                                <td align='left'>{documentMask(item.customer.document)}</td>
                                                                <td align='center'>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                <td align='center'>
                                                                    {
                                                                        Moment(item.startDate.seconds * 1000).isSame(Moment(item.endDate.seconds * 1000), 'day') ?
                                                                            <span>{Moment(item.startDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</span>
                                                                            :
                                                                            <span>{Moment(item.startDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")} - {Moment(item.endDate.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</span>
                                                                    }
                                                                </td>
                                                                <td align='center'>
                                                                    {
                                                                        permanenceBetweenDates({ startDate: Moment(item.startDate.seconds * 1000), endDate: Moment(item.endDate.seconds * 1000) })
                                                                    }
                                                                </td>
                                                                <td align='right'>{item.vehicles}</td>
                                                                <td align='right'>{toCurrency(item.resume.totalValue)}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        {/* {
                                                                            item.status == "paid" &&
                                                                            <Tooltip title="Extornar" placement="top">
                                                                                <button className='btn btn-danger' onClick={(e) => this.onRefound(item)}>
                                                                                    <i class="fa-solid fa-strikethrough" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        } */}
                                                                        {
                                                                            (item.status == "pending" || item.status == "expired" || item.status == "canceled") &&
                                                                            <Tooltip title="Apagar" placement="top">
                                                                                <button className='btn btn-danger' onClick={(e) => this.onRemove(item)}>
                                                                                    <i class="fa-solid fa-trash" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </div >
        )
    }
}

export { BookingList, BookingAppointmentsList }