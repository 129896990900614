import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../config/auth'
import { Tabs, Tab } from 'react-bootstrap';
import { Loading, EmptyContent, HelpButton } from '../../components/common/commons'
import { AlertSuccess, AlertWarning } from '../../shared/alert-manager'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import firebase from '../../config/firebase';
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import NumberFormat from 'react-number-format';
import { sendClickButton, sendLogByUser } from '../../shared/analytics'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import { deleteProduct, getAllCategories, getAllProducts, getAllSubCategories, getProductById } from '../../store/collections/productWorker';
import { SliderPicker } from 'react-color'
import 'firebase/auth';
import 'firebase/storage';
import './index.css'
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { dangerConfirm, goToFirebase, isNullOrEmpty, queryString, reloadWindow, reloadWithAlert, setPathWindow, toastDismissLoading, toastLoading, toastSuccess, toastWarning, toCurrency } from '../../shared/utils'
import Select from 'react-select';
import { getAllUsersActiveByClientId } from '../../store/collections/userWorker';
import { getURLFromURI, uploadProductImage } from '../../store/filesWorker';
import { getAllSectoresByClientId } from '../../store/collections/setoresWorker';
import { addDoc, deleteDoc, updateDoc } from '../../store/collections/collectionBaseWorker';
import { Collection } from '../../shared/constants';
import { DuplicateDataButton } from '../../shared/buttons/duplication.buttons';

const initializeState = {
    tipoProduto: 'SELL',
    id: queryString("id"),
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente()?.id,
    userEmail: getUsuario()?.email,
    key: "valores",
    subkey: "icms",
    habilitarEmissaoNotaNFCE: false,
    habilitarControleAcessoPorUsuario: false,
    habilitarCustomizacaoDoValorDeVenda: false,
    situacao_tributaria_icms: 102,
    situacao_tributaria_ipi: 99,
    situacao_tributaria_pis: 99,
    situacao_tributaria_cofins: 99,
    tributacao_icms: "simples",
    unidade: "UN",
    alq_ipi: 0.0,
    alq_cofins: 0.0,
    alq_issqn: 0.0,
    alq_pis: 0.0,
    exigibilidade_issqn: "",
    incentivo_fiscal_issqn: 2,
    valor: 0.0,
    valorCusto: 0.0,
    margemLucro: 0.0,
    produtoId: '',
    descricao: '',
    isEdicao: false,
    online: 'true',
    hasValorProduto: true,
    hasCustoMaiorQueValor: true,
    subCategorySelected: null,
    categorySelected: null,
    subCategories: [{ name: "Carregando...", id: "" }],
    cadastrarSubCategoriaOpen: false,
    ean: null,
    estoqueDisponivel: 0,
    estoqueMinimo: 0,
    cadastrarCategoriaOpen: false,
    cadastrarModificadorOpen: false,
    valorNoEstoque: 0.0,
    alertaValorNoEstoque: '',
    controlarEstoque: 'FALSE',
    notificarEstoqueMinimo: 'FALSE',
    printInTicket: true,
    printDescriptionInTicket: false,
    categories: [{ name: "Carregando...", id: "" }],
    imageURL: "https://placehold.co/150x150",
    random: Math.random(),
    habilitarDestaque: false,
}

class CriarProduto extends React.Component {

    constructor(props) {
        super(props)

        this.state = initializeState
        this.loadAlertInventory()
        this.loadCategories()
        this.loadSubCategories()
    }

    componentDidMount = async () => {
        const tab = queryString("tab")
        this.changeInfoMenu(tab ?? "valores")
    }

    reset = () => {
        this.setState({ ...initializeState, random: Math.random() })
    }

    loadAlertInventory = async () => {
        const message = queryString("message")
        const type = queryString("type")
        if (type === "minimun-inventory") {
            const id = queryString("id")
            toastWarning(message)
            setPathWindow('/cadastrar/produto');
            const product = await getProductById({ id: id, parkId: this.state.estacionamentoId })
            if (product !== null) {
                this.onEditProduct(product)
            } else {
                toastWarning("Produto não encontrado.")
            }
        }
    }

    loadCategories = async () => {
        const categories = await getAllCategories({ parkId: this.state.estacionamentoId })
        if (categories.length > 0) {
            this.setState({ categories: categories })
        } else {
            this.setState({ categories: [] })
        }
    }

    loadSubCategories = async () => {
        const subCategories = await getAllSubCategories({ parkId: this.state.estacionamentoId })
        if (subCategories.length > 0) {
            this.setState({ subCategories: subCategories })
        } else {
            this.setState({ subCategories: [] })
        }
    }

    addProduto = async () => {
        if (!this.checkFields()) {
            return
        }
        toastLoading("Salvando...")
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            revendaId: getRevendaIdByPark(),
            descricao: this.state.descricao,
            observacao: this.state.observacao,
            tipoProduto: this.state.tipoProduto,
            valor: this.state.valor,
            valorCusto: this.state.valorCusto,
            category: this.state.categorySelected,
            subCategory: this.state.subCategorySelected,
            margemLucro: parseFloat(this.state.margemLucro),
            ean: this.state.ean,
            estoqueDisponivel: parseInt(this.state.estoqueDisponivel),
            estoqueMinimo: parseInt(this.state.estoqueMinimo),
            controlarEstoque: this.state.controlarEstoque ? "TRUE" : "FALSE",
            notificarEstoqueMinimo: this.state.notificarEstoqueMinimo,
            printInTicket: this.state.printInTicket ? "TRUE" : "FALSE",
            printDescriptionInTicket: this.state.printDescriptionInTicket ? "TRUE" : "FALSE",
            createdAt: new Date(),
            createdBy: this.state.userEmail,
            habilitarEmissaoNotaNFCE: this.state.habilitarEmissaoNotaNFCE,
            ncm: this.state.ncm,
            cest: this.state.cest,
            unidade: this.state.unidade,
            origem: this.state.origem,
            tributacao_icms: this.state.tributacao_icms,
            codigo_cfop_icms: this.state.codigo_cfop_icms,
            situacao_tributaria_icms: this.state.situacao_tributaria_icms,
            situacao_tributaria_ipi: this.state.situacao_tributaria_ipi,
            cod_enquadramento_ipi: this.state.cod_enquadramento_ipi,
            codigo_cfop_ipi: this.state.codigo_cfop_ipi,
            alq_ipi: this.state.alq_ipi,
            codigo_cfop_issqn: this.state.codigo_cfop_issqn,
            exigibilidade_issqn: this.state.exigibilidade_issqn,
            item_servico_issqn: this.state.item_servico_issqn,
            incentivo_fiscal_issqn: this.state.incentivo_fiscal_issqn,
            alq_issqn: this.state.alq_issqn,
            situacao_tributaria_pis: this.state.situacao_tributaria_pis,
            alq_pis: this.state.alq_pis,
            situacao_tributaria_cofins: this.state.situacao_tributaria_cofins,
            alq_cofins: this.state.alq_cofins,
            habilitarControleAcessoPorUsuario: this.state.habilitarControleAcessoPorUsuario,
            habilitarCustomizacaoDoValorDeVenda: this.state.habilitarCustomizacaoDoValorDeVenda,
            usersAllowed: this.state.usersAllowed,
            habilitarTotemAutoAtendimento: this.state.habilitarTotemAutoAtendimento,
            habilitarTotemGerarQRCode: this.state.habilitarTotemGerarQRCode,
            habilitarTotemControlarAcesso: this.state.habilitarTotemControlarAcesso,
            habilitarTotemSetores: this.state.habilitarTotemSetores,
            habilitarTotemQueimarRegistro: this.state.habilitarTotemQueimarRegistro,
            habilitarDestaque: this.state.habilitarDestaque
        }
        const doc = await addDoc({ collection: Collection.PRODUCT, data: data })
        await this.uploadProductImageIfNeed({ id: doc.id })
        await saveLastUpdate()
        sendClickButton("Produtos e serviços", "Cadastrar", true, data)
        toastDismissLoading()
        reloadWithAlert('Produto cadastrado com sucesso!')
    }

    updateProduto = async () => {
        if (!this.checkFields()) {
            return
        }
        toastLoading("Salvando...")
        const data = {
            descricao: this.state.descricao,
            observacao: this.state.observacao,
            tipoProduto: this.state.tipoProduto,
            valor: this.state.valor,
            valorCusto: this.state.valorCusto,
            category: this.state.categorySelected,
            subCategory: this.state.subCategorySelected,
            margemLucro: parseFloat(this.state.margemLucro),
            ean: this.state.ean,
            estoqueDisponivel: parseInt(this.state.estoqueDisponivel),
            estoqueMinimo: parseInt(this.state.estoqueMinimo),
            controlarEstoque: this.state.controlarEstoque ? "TRUE" : "FALSE",
            notificarEstoqueMinimo: this.state.notificarEstoqueMinimo,
            printInTicket: this.state.printInTicket ? "TRUE" : "FALSE",
            printDescriptionInTicket: this.state.printDescriptionInTicket ? "TRUE" : "FALSE",
            alteredAt: new Date(),
            createdBy: this.state.userEmail,
            habilitarEmissaoNotaNFCE: this.state.habilitarEmissaoNotaNFCE,
            ncm: this.state.ncm,
            cest: this.state.cest,
            unidade: this.state.unidade,
            origem: this.state.origem,
            tributacao_icms: this.state.tributacao_icms,
            codigo_cfop_icms: this.state.codigo_cfop_icms,
            situacao_tributaria_icms: this.state.situacao_tributaria_icms,
            situacao_tributaria_ipi: this.state.situacao_tributaria_ipi,
            cod_enquadramento_ipi: this.state.cod_enquadramento_ipi,
            codigo_cfop_ipi: this.state.codigo_cfop_ipi,
            alq_ipi: this.state.alq_ipi,
            codigo_cfop_issqn: this.state.codigo_cfop_issqn,
            exigibilidade_issqn: this.state.exigibilidade_issqn,
            item_servico_issqn: this.state.item_servico_issqn,
            incentivo_fiscal_issqn: this.state.incentivo_fiscal_issqn,
            alq_issqn: this.state.alq_issqn,
            situacao_tributaria_pis: this.state.situacao_tributaria_pis,
            alq_pis: this.state.alq_pis,
            situacao_tributaria_cofins: this.state.situacao_tributaria_cofins,
            alq_cofins: this.state.alq_cofins,
            habilitarControleAcessoPorUsuario: this.state.habilitarControleAcessoPorUsuario,
            habilitarCustomizacaoDoValorDeVenda: this.state.habilitarCustomizacaoDoValorDeVenda,
            usersAllowed: this.state.usersAllowed,
            habilitarTotemAutoAtendimento: this.state.habilitarTotemAutoAtendimento,
            habilitarTotemGerarQRCode: this.state.habilitarTotemGerarQRCode,
            habilitarTotemControlarAcesso: this.state.habilitarTotemControlarAcesso,
            habilitarTotemSetores: this.state.habilitarTotemSetores,
            habilitarTotemQueimarRegistro: this.state.habilitarTotemQueimarRegistro,
            habilitarDestaque: this.state.habilitarDestaque
        }
        await firebase.firestore().collection('produtos').doc(this.state.produtoId).update(data)
        await this.uploadProductImageIfNeed({ id: this.state.produtoId })
        await saveLastUpdate()
        sendClickButton("Produtos e serviços", "Atualizar", true, data)
        toastDismissLoading()
        reloadWithAlert('Produto atualizado com sucesso!')
    }

    atualizarValor = (event, maskedvalue, floatvalue) => {
        this.setState({ valor: floatvalue }, () => {
            this.atualizaPorcentagem()
        })
        if (floatvalue === 0) {
            this.setState({ hasValorProduto: false })
        } else {
            this.setState({ hasValorProduto: true })
        }
    }

    atualizarValorCusto = (event, maskedvalue, floatvalue) => {
        this.setState({ valorCusto: floatvalue }, () => {
            this.atualizaPorcentagem()
        })
        if (floatvalue > this.state.valor) {
            this.setState({ hasCustoMaiorQueValor: false })
        } else {
            this.setState({ hasCustoMaiorQueValor: true })
        }
    }

    atualizaPorcentagem = () => {
        const valor = this.state.valor
        const valorCusto = this.state.valorCusto
        if (valor > 0 && valorCusto > 0) {
            var resultado = ((valor - valorCusto) / valor) * 100
            this.setState({ margemLucro: resultado.toFixed(2) })
        } else {
            this.setState({ margemLucro: 0.0 })
        }
        this.calculateInventary()
    }

    changeInventaryAvailable = (estoqueDisponivel) => {
        this.setState({ estoqueDisponivel: estoqueDisponivel }, () => {
            this.calculateInventary()
        })
    }

    calculateInventary = () => {
        var valorCusto = this.state.valorCusto
        var estoqueDisponivel = this.state.estoqueDisponivel
        if (valorCusto === undefined) {
            this.setState({ alertaValorNoEstoque: "Informe o valor de custo" })
        }
        if (estoqueDisponivel === undefined) {
            this.setState({ alertaValorNoEstoque: "Informe o estoque disponível" })
        }
        const total = parseFloat(valorCusto) * parseInt(estoqueDisponivel)
        this.setState({ valorNoEstoque: total < 0 ? 0 : total })
    }

    checkFields = () => {
        if (isNullOrEmpty(this.state.descricao)) {
            toastWarning("Prencha o nome do produto.")
            return false
        }
        if (this.state.valor === 0.0) {
            toastWarning("Prencha o valor do produto.")
            return false
        }
        return true
    }

    onEditProduct = async (produto) => {
        this.setState({ ...produto, produtoId: produto.id }, () => {
            this.atualizaPorcentagem()
        })
        this.setState({ printInTicket: produto.printInTicket === "TRUE" })
        this.setState({ printDescriptionInTicket: produto.printDescriptionInTicket === "TRUE" })
        this.setState({ estoqueDisponivel: produto.estoqueDisponivel ?? 0 })
        this.setState({ habilitarControleAcessoPorUsuario: produto.habilitarControleAcessoPorUsuario ?? false })
        this.setState({ habilitarCustomizacaoDoValorDeVenda: produto.habilitarCustomizacaoDoValorDeVenda ?? false })
        this.setState({ habilitarDestaque: produto.habilitarDestaque ?? false })
        if (produto.habilitarControleAcessoPorUsuario === true) {
            this.loadAllUsersByAllowed()
        }
        this.setState({ usersAllowed: produto.usersAllowed ?? null })
        if (produto.habilitarTotemControlarAcesso) {
            this.loadSectors()
        }
        this.setState({ habilitarTotemSetores: produto.habilitarTotemSetores ?? null })
        this.selectSubCategory(produto.subCategory?.id)
        this.selectCategory(produto.category?.id)

        if (produto.imageURL) {
            const url = await getURLFromURI(produto.imageURL)
            this.setState({ imageURL: url })
        } else {
            this.setState({ imageURL: "https://placehold.co/150x150" })
        }
    }

    onDuplicateProduct = (produto) => {
        this.setState({ ...produto, produtoId: '' })
        this.selectSubCategory(produto.subCategory?.id)
        this.selectCategory(produto.category?.id)
    }

    onModifierButton = (produto) => {
        this.setState({ cadastrarModificadorOpen: true })
        this.setState({ produtoSelecionado: produto })
    }

    closeModifierModal = () => {
        this.setState({ cadastrarModificadorOpen: false })
    }

    selectCategory = (id) => {
        const categorySelected = this.state.categories.filter(e => e.id === id)
        if (categorySelected.length > 0) {
            this.setState({ categorySelected: categorySelected[0] })
        }
    }

    showCadastrarCategoriaModal = () => {
        this.setState({ cadastrarCategoriaOpen: true })
    }

    hideCadastrarCategoriaModal = () => {
        this.setState({ cadastrarCategoriaOpen: false })
        this.loadCategories()
    }

    showCadastrarSubCategoriaModal = () => {
        this.setState({ cadastrarSubCategoriaOpen: true })
    }

    hideCadastrarSubCategoriaModal = () => {
        this.setState({ cadastrarSubCategoriaOpen: false })
        this.loadSubCategories()
    }

    selectSubCategory = (id) => {
        const subCategorySelected = this.state.subCategories.filter(e => e.id === id)
        if (subCategorySelected.length > 0) {
            this.setState({ subCategorySelected: subCategorySelected[0] })
        }
    }

    changeInfoMenu = (key) => {
        const id = this.state.id
        if (!isNullOrEmpty(id)) {
            setPathWindow(`/cadastrar/produto/?id=${id}&tab=${key}`)
        } else {
            setPathWindow(`/cadastrar/produto/?tab=${key}`)
        }
        this.setState({ key: key })
    }

    changeProductType = (e) => {
        const value = e.target.value
        this.setState({ tipoProduto: value })
        this.setState({ habilitarEmissaoNotaNFCE: e.target.value !== "SERVICES" })
    }

    loadAllUsersByAllowed = async () => {
        const users = await getAllUsersActiveByClientId({ clienteId: this.state.clienteId })
        var mapped = users.map(e => { return { value: e.id, label: e.nome } })
        this.setState({ allUsers: mapped })
        this.setState({ randomUserAllowed: Math.random() })
    }

    enableUserAllowed = async (e) => {
        this.setState({ habilitarControleAcessoPorUsuario: e.target.checked })
        if (e.target.checked && !this.state.allUsers) {
            this.loadAllUsersByAllowed()
        }
    }

    enableTotemAccessAllowed = async (e) => {
        this.setState({ habilitarTotemControlarAcesso: e.target.checked })
        if (e.target.checked && !this.state.allSetores) {
            this.loadSectors()
        }
    }

    loadSectors = async () => {
        var allSetores = await getAllSectoresByClientId({ clienteId: this.state.clienteId })
        var mapped = allSetores.map(e => { return { value: e.id, label: e.nome } })
        this.setState({ allSetores: mapped })
    }

    handleUsers = (selected) => {
        this.setState({ usersAllowed: selected })
    }

    handleClick = (e) => {
        this.inputImageElement.click();
    }

    onChangeFile = (e) => {
        const imageURL = URL.createObjectURL(e.target.files[0])
        this.setState({ imageURL: imageURL })
    }

    uploadProductImageIfNeed = async ({ id }) => {
        const file = this.inputImageElement.files[0]
        if (!file) {
            return
        }
        const product = await getProductById({ id: id, parkId: this.state.estacionamentoId })
        const destination = await uploadProductImage(this.state.estacionamentoId, file, id)
        product.imageURL = destination
        await firebase.firestore().collection('produtos').doc(id).update(product)
        this.setState({ imageURL: "https://placehold.co/150x150" })
    }

    handleSetores = (selected) => {
        this.setState({ habilitarTotemSetores: selected })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            {this.state.produtoId !== '' ? 'Atualizar produtos' : 'Cadastrar produtos'}
                            <HelpButton />
                        </div>
                        <div className="card-body">
                            <div className='row mt-1'>
                                <div className='col-lg-6'>
                                    <div className="row">
                                        <AlertSuccess tag="Novidade">
                                            <label>Deixe em destaque os seus produtos para vendas mais rápidas.</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" id="habilitarDestaque" checked={this.state.habilitarDestaque} onChange={(e) => this.setState({ habilitarDestaque: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarDestaque">
                                                    Deixar produto em destaque
                                                </label>
                                            </div>
                                        </AlertSuccess>
                                        <div className='row'>
                                            <div className='col-lg-3' role='button' onClick={this.handleClick}>
                                                <img alt='Imagem do produto' style={{ "object-fit": "cover" }} className="img-thumbnail my-2" src={this.state.imageURL} />
                                                <input type='file' onChange={this.onChangeFile} accept="image/png, image/jpeg" hidden={true} ref={input => this.inputImageElement = input} />
                                            </div>
                                            <div className='col-lg-9'>
                                                <div className='row'>
                                                    <div className="col-lg-6">
                                                        <label>Ean</label>
                                                        <Tooltip title="Código utilizado para busca interna" placement="top">
                                                            <input type="text" onChange={(e) => this.setState({ ean: e.target.value })} value={this.state.ean && this.state.ean} className="form-control" required />
                                                        </Tooltip>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Tipo de Produto</label>
                                                        <select className='form-select' value={this.state.tipoProduto && this.state.tipoProduto} onChange={(e) => this.changeProductType(e)}>
                                                            <option value='SERVICES'>Serviço</option>
                                                            <option value='SELL'>Venda</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-6 mt-2">
                                                        <label>Nome do produto</label>
                                                    </div>
                                                    <div className="col-lg-6 d-flex mt-2">
                                                        <div className="form-check form-switch ms-auto">
                                                            <Tooltip title="Imprimir nome no ticket" placement="top">
                                                                <input className="form-check-input" id="printInTicket" checked={this.state.printInTicket} onChange={(e) => this.setState({ printInTicket: e.target.checked })} type="checkbox" />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <input type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao && this.state.descricao} className="form-control" required />
                                                    </div>
                                                    <div className="col-lg-6 mt-2">
                                                        <label>Descrição</label>
                                                    </div>
                                                    {
                                                        this.state.printInTicket &&
                                                        <div className="col-lg-6 d-flex mt-2">
                                                            <div className="form-check form-switch ms-auto">
                                                                <Tooltip title="Imprimir descrição no ticket" placement="top">
                                                                    <input className="form-check-input" id="printDescriptionInTicket" checked={this.state.printDescriptionInTicket} onChange={(e) => this.setState({ printDescriptionInTicket: e.target.checked })} type="checkbox" />
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-lg-12">
                                                        <textarea rows={3} onChange={(e) => this.setState({ observacao: e.target.value })} value={this.state.observacao && this.state.observacao} className="form-control" required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.changeInfoMenu(k)}>
                                        <Tab eventKey="valores" title="Valores">
                                            <div className='row mt-3'>
                                                <div className='row'>
                                                    <div className="col-lg-4">
                                                        <label>Valor de venda (*)</label>
                                                        <CurrencyInput value={this.state.valor && this.state.valor} prefix="R$" className='form-control' onChangeEvent={this.atualizarValor} />
                                                        {
                                                            this.state.hasValorProduto === false && <small><strong className='text-danger'>Digite o valor do produto</strong></small>
                                                        }
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Valor de custo</label>
                                                        <CurrencyInput value={this.state.valorCusto && this.state.valorCusto} prefix="R$" className='form-control' onChangeEvent={this.atualizarValorCusto} />
                                                        {
                                                            this.state.hasCustoMaiorQueValor === false && <small><strong className='text-danger'>O custo deve ser menor que o valor do produto</strong></small>
                                                        }
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Margem de lucro</label>
                                                        <h3>
                                                            {this.state.margemLucro}%
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mt-2">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" id="habilitarCustomizacaoDoValorDeVenda" checked={this.state.habilitarCustomizacaoDoValorDeVenda} onChange={(e) => this.setState({ habilitarCustomizacaoDoValorDeVenda: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarCustomizacaoDoValorDeVenda">
                                                            Permitir customizar o valor na venda
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="categorias" title="Categorias">
                                            <div className='row mt-3'>
                                                <div className="col-lg-6">
                                                    <label>Categoria</label>
                                                    {
                                                        this.state.categories.length === 0 &&
                                                        <div className='my-3'>
                                                            <button type="button" onClick={this.showCadastrarCategoriaModal} className="btn btn-secondary w-100">
                                                                Adicionar nova
                                                                <i className="fas fa-plus mx-3" />
                                                            </button>
                                                        </div>
                                                    }{
                                                        this.state.categories.length !== 0 && <>
                                                            <select className='form-select' value={this.state.categorySelected?.id && this.state.categorySelected?.id} onChange={(e) => this.selectCategory(e.target.value)}>
                                                                <option value=''>Selecionar</option>
                                                                {
                                                                    this.state.categories.map(item => <option value={item.id}>{item.name}</option>)
                                                                }
                                                            </select>
                                                            <div className='my-3'>
                                                                <button type="button" onClick={this.showCadastrarCategoriaModal} className="btn btn-secondary w-100">
                                                                    Adicionar nova
                                                                    <i className="fas fa-plus mx-3" />
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Sub-categoria</label>
                                                    {
                                                        this.state.subCategories.length === 0 &&
                                                        <div className='my-3'>
                                                            <button type="button" onClick={this.showCadastrarSubCategoriaModal} className="btn btn-secondary w-100">
                                                                Adicionar nova
                                                                <i className="fas fa-plus mx-2" />
                                                            </button>
                                                        </div>
                                                    }{
                                                        this.state.subCategories.length !== 0 && <>
                                                            <select className='form-select' value={this.state.subCategorySelected?.id && this.state.subCategorySelected?.id} onChange={(e) => this.selectSubCategory(e.target.value)}>
                                                                <option value=''>Selecionar</option>
                                                                {
                                                                    this.state.subCategories.map(item => <option value={item.id}>{item.name}</option>)
                                                                }
                                                            </select>
                                                            <div className='my-3'>
                                                                <button type="button" onClick={this.showCadastrarSubCategoriaModal} className="btn btn-secondary w-100">
                                                                    Adicionar
                                                                    <i className="fas fa-plus mx-2" />
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="estoque" title="Estoque">
                                            <div className='col-lg-12 mt-2'>
                                                <div className='row'>
                                                    <div className="col-lg-12">
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="controlarEstoque" checked={this.state.controlarEstoque} onChange={(e) => this.setState({ controlarEstoque: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="controlarEstoque">
                                                                Controlar estoque
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.controlarEstoque &&
                                                        <>
                                                            <div className="col-lg-3">
                                                                <label>Estoque mínimo</label>
                                                                <input type="number" onChange={(e) => this.setState({ estoqueMinimo: e.target.value })} value={this.state.estoqueMinimo} className="form-control" required />
                                                            </div>
                                                            {
                                                                this.state.produtoId === '' ?
                                                                    <div className="col-lg-3">
                                                                        <label>Estoque disponível</label>
                                                                        <input type="number" onChange={(e) => this.changeInventaryAvailable(e.target.value)} value={this.state.estoqueDisponivel} className="form-control" required />
                                                                    </div> :
                                                                    <div className="col-lg-3 mt-4">
                                                                        <Tooltip title="Toque para editar o estoque disponível deste produto." placement="top">
                                                                            <button type="button" onClick={(e) => this.onModifierButton(this.state)} className="btn btn-box w-100">
                                                                                {this.state.estoqueDisponivel} itens
                                                                                <i className="fas fa-box mx-2" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                            }
                                                            <div className="col-lg-3">
                                                                <label>Notificar</label>
                                                                <Tooltip placement='top' title="Iremos te avisar por e-mail quando este produto chegar no estoque minímo.">
                                                                    <select className='form-select' value={this.state.notificarEstoqueMinimo && this.state.notificarEstoqueMinimo} onChange={(e) => this.setState({ notificarEstoqueMinimo: e.target.value })}>
                                                                        <option value='TRUE'>Sim</option>
                                                                        <option value='FALSE'>Não</option>
                                                                    </select>
                                                                </Tooltip>
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Valor no estoque</label>
                                                                {
                                                                    this.state.alertaValorNoEstoque !== "" ? <h3>
                                                                        {this.state.alertaValorNoEstoque}
                                                                    </h3> :
                                                                        <h3>
                                                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.valorNoEstoque} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                        </h3>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="nf" title="Nota Fiscal (NFC-e)">
                                            <div className="col-lg-12">
                                                <div className="form-check form-switch mt-3">
                                                    <input className="form-check-input" id="habilitarEmissaoNotaNFCE" checked={this.state.habilitarEmissaoNotaNFCE} onChange={(e) => this.setState({ habilitarEmissaoNotaNFCE: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarEmissaoNotaNFCE">
                                                        Habilitar dados para emissão de nota de consumo.
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                this.state.habilitarEmissaoNotaNFCE && <>
                                                    <div className='col-lg-12 mt-3'>
                                                        <div className='row'>
                                                            <div className="col-lg-3">
                                                                <label>NCM (*)</label>
                                                                <input type="text" onChange={(e) => this.setState({ ncm: e.target.value })} value={this.state.ncm && this.state.ncm} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>CEST</label>
                                                                <input type="text" onChange={(e) => this.setState({ cest: e.target.value })} value={this.state.cest && this.state.cest} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Un. Tributável</label>
                                                                <select className='form-select' value={this.state.unidade && this.state.unidade} onChange={(e) => this.setState({ unidade: e.target.value })}>
                                                                    <option value='UN'>UN - Unidade</option>
                                                                    <option value='KG'>KG - Kilograma</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>Origem do produto (*)</label>
                                                                <select className='form-select' value={this.state.origem && this.state.origem} onChange={(e) => this.setState({ origem: e.target.value })}>
                                                                    <option value=''>Selecionar</option>
                                                                    <option value='0'>0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8</option>
                                                                    <option value='1'>1 - Estrangeira - Importação direta, exceto a indicada no código 6</option>
                                                                    <option value='2'>2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7</option>
                                                                    <option value='3'>3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%</option>
                                                                    <option value='4'>4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes</option>
                                                                    <option value='5'>5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%</option>
                                                                    <option value='6'>6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX e gás natural</option>
                                                                    <option value='7'>7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural</option>
                                                                    <option value='8'>8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Tabs className='profile-dropdown-toggle mt-3' defaultActiveKey={1} activeKey={this.state.subkey} onSelect={k => this.setState({ subkey: k })}>
                                                        <Tab eventKey="icms" title="ICMS">
                                                            <div className='row mt-3'>
                                                                <div className="col-lg-3">
                                                                    <label>Tributação</label>
                                                                    <select className='form-select' value={this.state.tributacao_icms && this.state.tributacao_icms} onChange={(e) => this.setState({ tributacao_icms: e.target.value })}>
                                                                        <option value='simples'>Simples</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <label>CFOP (*)</label>
                                                                    <NumberFormat className="form-control" value={this.state.codigo_cfop_icms} mask='_' onChange={(e) => this.setState({ codigo_cfop_icms: e.target.value })} format="#.###" />
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>Situação tributária (*)</label>
                                                                    <select className='form-select' value={this.state.situacao_tributaria_icms && this.state.situacao_tributaria_icms} onChange={(e) => this.setState({ situacao_tributaria_icms: e.target.value })}>
                                                                        <option value='101'>101 - Tributada com permissão de crédito</option>
                                                                        <option value='102'>102 - Tributada sem permissão de crédito</option>
                                                                        <option value='103'>103 - Isenção do ICMS para faixa de receita bruta</option>
                                                                        <option value='201'>201 - Tributada com permissão de crédito e com cobrança do ICMS por substituição tributária</option>
                                                                        <option value='202'>202 - Tributada sem permissão de crédito e com cobrança do ICMS por substituição tributária</option>
                                                                        <option value='203'>203 - Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por substituição tributária</option>
                                                                        <option value='300'>300 - Imune</option>
                                                                        <option value='400'>400 - Não tributada</option>
                                                                        <option value='500'>500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação</option>
                                                                        <option value='900'>900 - Outros</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="pis" title="PIS">
                                                            <div className='row mt-3'>
                                                                <div className="col-lg-6">
                                                                    <label>Situação tributária (*)</label>
                                                                    <select className='form-select' value={this.state.situacao_tributaria_pis && this.state.situacao_tributaria_pis} onChange={(e) => this.setState({ situacao_tributaria_pis: e.target.value })}>
                                                                        <option value='01'>01 - Operação Tributável - Base de Cálculo = Valor da Operação Alíquota Normal (Cumulativo/Não cumulativo)</option>
                                                                        <option value='02'>02 - Operação Tributável - Base de Cálculo = Valor da Operação (Alíquota diferenciada)</option>
                                                                        <option value='03'>03 - Operação Tributável - Base de Cálculo = Quantidade Vendida X Alíquota por Unidade de Produto</option>
                                                                        <option value='04'>04 - Operação Tributável - Tributação Monofásica - (Alíquota Zero)</option>
                                                                        <option value='05'>05 - Operação Tributável - (Substituição Tributária)</option>
                                                                        <option value='06'>06 - Operação Tributável - (Alíquota Zero)</option>
                                                                        <option value='07'>07 - Operação Isenta de Contribuição</option>
                                                                        <option value='08'>08 - Operação sem Incidência da Contribuição</option>
                                                                        <option value='09'>09 - Operação com Suspensão da Contribuição</option>
                                                                        <option value='49'>49 - Outras Operações de Saída</option>
                                                                        <option value='50'>50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                        <option value='51'>51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno</option>
                                                                        <option value='52'>52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação</option>
                                                                        <option value='53'>53 - Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                        <option value='54'>54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='55'>55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='56'>56 - Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                        <option value='60'>60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                        <option value='61'>61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno</option>
                                                                        <option value='62'>62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação</option>
                                                                        <option value='63'>63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                        <option value='64'>64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='65'>65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='66'>66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                        <option value='67'>67 - Crédito Presumido - Outras Operações</option>
                                                                        <option value='70'>70 - Operação de Aquisição sem Direito a Crédito</option>
                                                                        <option value='71'>71 - Operação de Aquisição com Isenção</option>
                                                                        <option value='72'>72 - Operação de Aquisição com Suspensão</option>
                                                                        <option value='73'>73 - Operação de Aquisição a Alíquota Zero</option>
                                                                        <option value='74'>74 - Operação de Aquisição sem Incidência da Contribuição</option>
                                                                        <option value='75'>75 - Operação de Aquisição por Substituição Tributária</option>
                                                                        <option value='98'>98 - Outras Operações de Entrada</option>
                                                                        <option value='99'>99 - Outras Operações</option>
                                                                    </select>
                                                                </div>
                                                                <Tooltip title="Informe em porcentagem separado por ponto ( 0.00 )" placement="top">
                                                                    <div className="col-lg-3">
                                                                        <label>% - Alíquota PIS (*)</label>
                                                                        <input type="text" onChange={(e) => this.setState({ alq_pis: e.target.value })} value={this.state.alq_pis && this.state.alq_pis} className="form-control" required />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="cofins" title="COFINS">
                                                            <div className='row mt-3'>
                                                                <div className="col-lg-6">
                                                                    <label>Situação tributária (*)</label>
                                                                    <select className='form-select' value={this.state.situacao_tributaria_cofins && this.state.situacao_tributaria_cofins} onChange={(e) => this.setState({ situacao_tributaria_cofins: e.target.value })}>
                                                                        <option value='01'>01 - Operação Tributável - Base de Cálculo = Valor da Operação Alíquota Normal (Cumulativo/Não cumulativo)</option>
                                                                        <option value='02'>02 - Operação Tributável - Base de Cálculo = Valor da Operação (Alíquota diferenciada)</option>
                                                                        <option value='03'>03 - Operação Tributável - Base de Cálculo = Quantidade Vendida X Alíquota por Unidade de Produto</option>
                                                                        <option value='04'>04 - Operação Tributável - Tributação Monofásica - (Alíquota Zero)</option>
                                                                        <option value='05'>05 - Operação Tributável - (Substituição Tributária)</option>
                                                                        <option value='06'>06 - Operação Tributável - (Alíquota Zero)</option>
                                                                        <option value='07'>07 - Operação Isenta de Contribuição</option>
                                                                        <option value='08'>08 - Operação sem Incidência da Contribuição</option>
                                                                        <option value='09'>09 - Operação com Suspensão da Contribuição</option>
                                                                        <option value='49'>49 - Outras Operações de Saída</option>
                                                                        <option value='50'>50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                        <option value='51'>51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno</option>
                                                                        <option value='52'>52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação</option>
                                                                        <option value='53'>53 - Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                        <option value='54'>54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='55'>55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='56'>56 - Operação com Direito a Crédito - Vinculado a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                        <option value='60'>60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno</option>
                                                                        <option value='61'>61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno</option>
                                                                        <option value='62'>62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação</option>
                                                                        <option value='63'>63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno</option>
                                                                        <option value='64'>64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='65'>65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação</option>
                                                                        <option value='66'>66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação</option>
                                                                        <option value='67'>67 - Crédito Presumido - Outras Operações</option>
                                                                        <option value='70'>70 - Operação de Aquisição sem Direito a Crédito</option>
                                                                        <option value='71'>71 - Operação de Aquisição com Isenção</option>
                                                                        <option value='72'>72 - Operação de Aquisição com Suspensão</option>
                                                                        <option value='73'>73 - Operação de Aquisição a Alíquota Zero</option>
                                                                        <option value='74'>74 - Operação de Aquisição sem Incidência da Contribuição</option>
                                                                        <option value='75'>75 - Operação de Aquisição por Substituição Tributária</option>
                                                                        <option value='98'>98 - Outras Operações de Entrada</option>
                                                                        <option value='99'>99 - Outras Operações</option>
                                                                    </select>
                                                                </div>
                                                                <Tooltip title="Informe em porcentagem separado por ponto ( 0.00 )" placement="top">
                                                                    <div className="col-lg-6">
                                                                        <label>% - Alíquota do COFINS (*)</label>
                                                                        <input type="text" onChange={(e) => this.setState({ alq_cofins: e.target.value })} value={this.state.alq_cofins && this.state.alq_cofins} className="form-control" required />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="ipi" title="IPI">
                                                            <div className='row mt-3'>
                                                                <div className="col-lg-6">
                                                                    <label>Situação tributária (*)</label>
                                                                    <select className='form-select' value={this.state.situacao_tributaria_ipi && this.state.situacao_tributaria_ipi} onChange={(e) => this.setState({ situacao_tributaria_ipi: e.target.value })}>
                                                                        <option value='00'>00- Entrada com recuperação de crédito</option>
                                                                        <option value='01'>01- Entrada tributada com alíquota zero</option>
                                                                        <option value='02'>02- Entrada isenta</option>
                                                                        <option value='03'>03- Entrada não-tributada</option>
                                                                        <option value='04'>04- Entrada imune</option>
                                                                        <option value='05'>05- Entrada com suspensão</option>
                                                                        <option value='49'>49- Outras entradas</option>
                                                                        <option value='50'>50- Saída tributada</option>
                                                                        <option value='51'>51- Saída tributada com alíquota zero</option>
                                                                        <option value='52'>52- Saída isenta</option>
                                                                        <option value='53'>53- Saída não-tributada</option>
                                                                        <option value='54'>54- Saída imune</option>
                                                                        <option value='55'>55- Saída com suspensão</option>
                                                                        <option value='99'>99- Outras saídas</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <label>Enquadramento (*)</label>
                                                                    <input type="text" maxLength={3} onChange={(e) => this.setState({ cod_enquadramento_ipi: e.target.value })} value={this.state.cod_enquadramento_ipi && this.state.cod_enquadramento_ipi} className="form-control" required />
                                                                </div>
                                                                <Tooltip title="Informe em porcentagem separado por ponto ( 0.00 )" placement="top">
                                                                    <div className="col-lg-3">
                                                                        <label>% - Alíquota do IPI (*)</label>
                                                                        <input type="text" onChange={(e) => this.setState({ alq_ipi: e.target.value })} value={this.state.alq_ipi && this.state.alq_ipi} className="form-control" required />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="issqn" title="ISSQN">
                                                            <div className='row mt-3'>
                                                                <div className="col-lg-3">
                                                                    <label>CFOP (*)</label>
                                                                    <NumberFormat className="form-control" value={this.state.codigo_cfop_issqn} mask='_' onChange={(e) => this.setState({ codigo_cfop_issqn: e.target.value })} format="#.###" />
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <label>Exigibilidade do ISS (*)</label>
                                                                    <select className='form-select' value={this.state.exigibilidade_issqn && this.state.exigibilidade_issqn} onChange={(e) => this.setState({ exigibilidade_issqn: e.target.value })}>
                                                                        <option value=''>Selecionar</option>
                                                                        <option value='1'>1 - Exigível</option>
                                                                        <option value='2'>2 - Não incidência</option>
                                                                        <option value='3'>3 - Isenção</option>
                                                                        <option value='4'>4 - Exportação</option>
                                                                        <option value='5'>5 - Imunidade</option>
                                                                        <option value='6' disabled>6 - Exigibilidade Suspensa por Decisão Judicial</option>
                                                                        <option value='7' disabled>7 - Exigibilidade Suspensa por Processo Administrativo</option>
                                                                    </select>
                                                                </div>
                                                                <Tooltip title="Item da lista de serviços em que se classifica o serviço no padrão ABRASF" placement="top">
                                                                    <div className="col-lg-3">
                                                                        <label>Nº do Item (*)</label>
                                                                        <NumberFormat className="form-control" value={this.state.item_servico_issqn} mask='_' onChange={(e) => this.setState({ item_servico_issqn: e.target.value })} format="##.##" />
                                                                    </div>
                                                                </Tooltip>
                                                                <div className="col-lg-3">
                                                                    <label>Incentivo Fiscal (*)</label>
                                                                    <select className='form-select' value={this.state.incentivo_fiscal_issqn && this.state.incentivo_fiscal_issqn} onChange={(e) => this.setState({ incentivo_fiscal_issqn: e.target.value })}>
                                                                        <option value='2'>Não</option>
                                                                        <option value='1'>Sim</option>
                                                                    </select>
                                                                </div>
                                                                <Tooltip title="Informe em porcentagem separado por ponto ( 0.00 )" placement="top">
                                                                    <div className="col-lg-3">
                                                                        <label>% - Alíquota ISSQN (*)</label>
                                                                        <input type="text" onChange={(e) => this.setState({ alq_issqn: e.target.value })} value={this.state.alq_issqn && this.state.alq_issqn} className="form-control" required />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </>
                                            }
                                        </Tab>
                                        <Tab eventKey="acessos" title="Acessos">
                                            <div className='col-lg-12 mt-3'>
                                                <AlertWarning message="Defina quais usuários terão permissão para lançar o produto.">
                                                    <div className="form-check form-switch mt-2">
                                                        <input className="form-check-input" id="habilitarControleAcessoPorUsuario" checked={this.state.habilitarControleAcessoPorUsuario} onChange={(e) => this.enableUserAllowed(e)} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarControleAcessoPorUsuario">
                                                            Habilitar controle de acesso por usuário.
                                                        </label>
                                                    </div>
                                                </AlertWarning>
                                                {this.state.habilitarControleAcessoPorUsuario &&
                                                    <div className='mt-2'>
                                                        <h5>Usuários</h5>
                                                        <div className="col-lg-12">
                                                            <Select
                                                                key={this.state.randomUserAllowed}
                                                                isMulti
                                                                name="parks"
                                                                defaultValue={this.state.usersAllowed}
                                                                options={this.state.allUsers}
                                                                onChange={this.handleUsers}
                                                                className="basic-multi-select"
                                                                classNamePrefix="Selecionar os usuários" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Tab>
                                        <Tab eventKey="totem" title="Totem">
                                            <div className='col-lg-12 mt-3'>
                                                <AlertWarning message="Venda seus produtos ou serviços em um totem de auto atendimento.">
                                                    <div className="form-check form-switch mt-2">
                                                        <input className="form-check-input" id="habilitarTotemAutoAtendimento" checked={this.state.habilitarTotemAutoAtendimento} onChange={(e) => this.setState({ habilitarTotemAutoAtendimento: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarTotemAutoAtendimento">
                                                            Habilitar venda por totem de auto atendimento.
                                                        </label>
                                                    </div>
                                                </AlertWarning>
                                                {
                                                    this.state.habilitarTotemAutoAtendimento && <>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" id="habilitarTotemGerarQRCode" checked={this.state.habilitarTotemGerarQRCode} onChange={(e) => this.setState({ habilitarTotemGerarQRCode: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarTotemGerarQRCode">
                                                                Gerar QRCode separado para este produto.
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" id="habilitarTotemControlarAcesso" checked={this.state.habilitarTotemControlarAcesso} onChange={(e) => this.enableTotemAccessAllowed(e)} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="habilitarTotemControlarAcesso">
                                                                Controlar acesso
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.habilitarTotemControlarAcesso && <>
                                                                <div className='mt-2'>
                                                                    <h6>Locais permitidos.</h6>
                                                                    <div className="col-lg-12">
                                                                        <Select
                                                                            key={this.state.random}
                                                                            isMulti
                                                                            name="setores"
                                                                            defaultValue={this.state.habilitarTotemSetores}
                                                                            options={this.state.allSetores}
                                                                            onChange={this.handleSetores}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="Selecionar os setores"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.habilitarTotemGerarQRCode &&
                                                                    <div className="form-check form-switch mt-2">
                                                                        <input className="form-check-input" id="habilitarTotemQueimarRegistro" checked={this.state.habilitarTotemQueimarRegistro} onChange={(e) => this.setState({ habilitarTotemQueimarRegistro: e.target.checked })} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarTotemQueimarRegistro">
                                                                            Queimar QRCode ao ser lido para acessar um local.
                                                                        </label>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group">
                                {
                                    this.state.produtoId !== '' &&
                                    <button type="button" onClick={(e) => { reloadWindow() }} className="btn btn-secondary text-white">Cancelar</button>
                                }
                                <button type="button" onClick={this.state.produtoId !== '' ? this.updateProduto : this.addProduto} className="btn btn-primary">{this.state.produtoId !== '' ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </div>
                    </div >
                    <ListagemProdutos key={this.state.random} ShowRemoveButton='true' ShowDuplicateButton='true' estacionamentoId={this.state.estacionamentoId} ShowEditButton='true' onEditProduct={this.onEditProduct} onDuplicateProduct={this.onDuplicateProduct} onModifierButton={this.onModifierButton} />
                    <Dialog maxWidth={"sm"} onClose={this.hideCadastrarCategoriaModal} open={this.state.cadastrarCategoriaOpen}>
                        <MuiDialogContent>
                            <CreateOrEditCategories name="categoria" collection="produtos-categories" />
                        </MuiDialogContent>
                    </Dialog>
                    <Dialog maxWidth={"sm"} onClose={this.hideCadastrarSubCategoriaModal} open={this.state.cadastrarSubCategoriaOpen}>
                        <MuiDialogContent>
                            <CreateOrEditCategories name="sub categoria" collection="produtos-sub-categories" />
                        </MuiDialogContent>
                    </Dialog>
                    <Dialog maxWidth={"md"} onClose={this.closeModifierModal} open={this.state.cadastrarModificadorOpen}>
                        <MuiDialogContent>
                            <ModifierInventory
                                key={this.state.produtoSelecionado?.id}
                                produto={this.state.produtoSelecionado} />
                        </MuiDialogContent>
                    </Dialog>
                </NavBar >
            </>
        )
    }
}

class ListagemProdutos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: queryString("id"),
            produtos: [],
            isLoading: true,
            isAdministrador: isAdministrador()
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true });
        const products = await getAllProducts({ parkId: this.props.estacionamentoId })
        this.setState({ produtos: products.sort((a, b) => a.descricao.localeCompare(b.descricao)) })
        var totalDisponivel = products.map(e => e.estoqueDisponivel ?? 0).reduce((a, b) => a + b, 0)
        var valorCusto = products.map(e => e.valorCusto ?? 0.0).reduce((a, b) => a + b, 0.0)
        this.setState({ totalEmEstoque: valorCusto * totalDisponivel })
        this.setState({ totalEstoqueDisponivel: totalDisponivel })
        this.setState({ isLoading: false });
        if (!isNullOrEmpty(this.state.id)) {
            this.onEditProduct(products.find(e => e.id === this.state.id))
        }
    }

    onRemoveProduct = async (produto) => {
        var confirm = await dangerConfirm({ message: 'Deseja mesmo remover o produto ' + produto.descricao + '?' });
        if (confirm) {
            await deleteProduct({ id: produto.id })
            sendLogByUser("Produtos e serviços", `apagar o produto ${produto.descricao}`, true, produto)
            this.load()
            await saveLastUpdate()
        }
    }

    onEditProduct = (produto) => {
        if (produto) {
            this.props.onEditProduct(produto)
        }
    }

    onDuplicateProduct = (produto) => {
        sendLogByUser("Produtos e serviços", `tocar no botão de duplicar o produto ${produto.descricao}`, true, produto)
        this.props.onDuplicateProduct(produto)
    }

    onModifierButton = (produto) => {
        this.props.onModifierButton(produto)
    }

    onCheckProduct = (isChecked, produto) => {
        this.props.onCheckProduct(isChecked, produto)
    }

    render() {
        return (
            <div className='card my-3'>
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todos os produtos
                </div>
                {
                    this.state.produtos.length === 0 && !this.state.isLoading ?
                        <EmptyContent text='Nenhum produto cadastrado' />
                        :
                        !this.state.isLoading ?
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.isAdministrador &&
                                                    <td width={10}></td>
                                                }
                                                <td scope="col" width={50} className="d-none d-sm-table-cell"><strong>Código</strong></td>
                                                <td scope="col"><strong>Nome</strong></td>
                                                <td scope="col" align='center' className="d-none d-sm-table-cell"><strong>Categoria</strong></td>
                                                <td scope="col" align='center'><strong>Estoque</strong></td>
                                                <td scope="col" align='center' className="d-none d-sm-table-cell"><strong>Estoque mínimo</strong></td>
                                                <td scope="col" align='center' className="d-none d-sm-table-cell"><strong>Total em estoque</strong></td>
                                                <td scope="col" align='center'><strong>Valor</strong></td>
                                                <td scope="col" width={10}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.produtos.map(produto =>
                                                    <tr key={produto.id}>
                                                        {
                                                            this.state.isAdministrador &&
                                                            <td className="d-none d-sm-table-cell">
                                                                <div className='btn-group'>
                                                                    <Tooltip title="Firebase" placement="top">
                                                                        <button type="button" onClick={() => { goToFirebase({ collection: Collection.PRODUCT, id: produto.id }) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-database" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <DuplicateDataButton
                                                                        item={produto}
                                                                        collection={Collection.PRODUCT}
                                                                        message="Deseja mesmo duplicar este produto entre os estabelecimentos abaixo?" />
                                                                </div>
                                                            </td>
                                                        }
                                                        <td className="d-none d-sm-table-cell">
                                                            {
                                                                this.state.isAdministrador ?
                                                                    <Tooltip title={produto.id} placement="top">
                                                                        <span>{produto.ean ?? "N/A"}</span>
                                                                    </Tooltip> :
                                                                    <span>{produto.ean ?? "N/A"}</span>
                                                            }
                                                        </td>
                                                        <td>{produto.descricao}</td>
                                                        {
                                                            produto.category?.name === undefined ?
                                                                <td className="d-none d-sm-table-cell" align='center'>N/A</td> :
                                                                <td className="d-none d-sm-table-cell" align='center'>
                                                                    <span className='mx-2' style={{ backgroundColor: produto.category?.color, color: produto.category?.color, borderRadius: 3 }}>
                                                                        ----
                                                                    </span>
                                                                    <span align='center'>{produto.category?.name}</span>
                                                                </td>
                                                        }
                                                        {
                                                            (produto.controlarEstoque === undefined || produto.controlarEstoque === 'FALSE') &&
                                                            <>
                                                                <td align='center'>N/A</td>
                                                                <td className="d-none d-sm-table-cell" align='center' >N/A</td>
                                                                <td className="d-none d-sm-table-cell" align='center' >N/A</td>
                                                            </>
                                                        }
                                                        {
                                                            produto.controlarEstoque === 'TRUE' &&
                                                            <>
                                                                <td align='center'>
                                                                    {produto.estoqueDisponivel}
                                                                </td>
                                                                <td align='center' className="d-none d-sm-table-cell">
                                                                    {
                                                                        (produto.estoqueMinimo === undefined && produto.estoqueDisponivel === undefined) ? <span>N/A</span> :
                                                                            <h6><span className={(produto.estoqueMinimo ?? 0) < (produto.estoqueDisponivel ?? 0) ? "badge text-bg-success" : "badge text-bg-warning text-white"}>{produto.estoqueMinimo ?? "N/A"}</span></h6>
                                                                    }
                                                                </td>
                                                                <td align='center' className="d-none d-sm-table-cell">
                                                                    {
                                                                        !produto.valorCusto ? <span> N/A</span> :
                                                                            <span>{toCurrency(parseFloat(produto.valorCusto) * parseInt(produto.estoqueDisponivel))}</span>
                                                                    }
                                                                </td>
                                                            </>
                                                        }
                                                        <td align='center'>{toCurrency(produto.valor)}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {this.props.onModifierButton &&
                                                                    <Tooltip title="Editar estoque" placement="top">
                                                                        <button type="button" onClick={(e) => this.onModifierButton(produto)} className="btn btn-box">
                                                                            <i className="fas fa-box" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    this.props.ShowDuplicateButton &&
                                                                    <Tooltip title="Duplicar produto" placement="top">
                                                                        <button type="button" onClick={() => { this.onDuplicateProduct(produto) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-copy" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {this.props.ShowEditButton === 'true' &&
                                                                    <Tooltip title="Editar produto" placement="top">
                                                                        <button type="button" onClick={(e) => this.onEditProduct(produto)} className="btn btn-secondary">
                                                                            <i className="fas fa-edit" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {this.props.ShowRemoveButton === 'true' &&
                                                                    <Tooltip title="Apagar produto" placement="top">
                                                                        <button type="button" onClick={(e) => this.onRemoveProduct(produto)} className="btn btn-danger">
                                                                            <i className="fas fa-trash" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {this.props.ShowCheckBox === 'true' &&
                                                                    <td>
                                                                        <input className="form-check-input" onClick={(e) => this.onCheckProduct(e.target.checked, produto)} type="checkbox" />
                                                                    </td>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='row mx-1'>
                                        {/* <div className='col-lg-3'>
                                            <ProductsTotalsInventory produtos={this.state.produtos} />
                                        </div> */}
                                        <div className='col-lg-3'>
                                            <ProductsTotalsMinInventory produtos={this.state.produtos} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <Loading />
                }
            </div >
        );
    }
}

class ProductsTotalsInventory extends React.Component {

    constructor(props) {
        super(props)
        var totalDisponivel = props.produtos.filter(e => e.controlarEstoque === "TRUE").map(e => e.estoqueDisponivel ?? 0).reduce((a, b) => a + b, 0)
        var valorCusto = props.produtos.filter(e => e.controlarEstoque === "TRUE").map(e => e.valorCusto ?? 0.0).reduce((a, b) => a + b, 0.0)
        const totalEmEstoque = valorCusto * totalDisponivel
        this.state = {
            total: totalEmEstoque,
            quantity: totalDisponivel
        }
    }

    render() {
        return (
            <>
                {
                    this.state.total !== 0 ?
                        <div className="card text-light bg-success mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Valor total no estoque</h5>
                                <h3 className="card-text text-center text-white">
                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                </h3>
                                <div className="text-center">
                                    <small className="card-text text-white">{this.state.quantity} Produtos no estoque</small>
                                </div>
                            </div>
                        </div> :
                        <div className="card text-light bg-success mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Sem controle de estoque</h5>
                                <div className="text-center">
                                    <small className="card-text text-white">Não foram encontrados produtos que estejam com controle de estoque.</small>
                                </div>
                            </div>
                        </div>
                }
            </>)
    }
}

class ProductsTotalsMinInventory extends React.Component {

    constructor(props) {
        super(props)
        var todosSemControle = props.produtos.filter(e => e.controlarEstoque === "TRUE")
        var todosOsProdutos = props.produtos.filter(e => e.controlarEstoque === "TRUE").filter(e => (e.estoqueDisponivel ?? 0) < (e.estoqueMinimo ?? 0))
        this.state = {
            quantity: todosOsProdutos.length,
            todosSemControle: todosSemControle.length === 0
        }
    }

    render() {
        return (
            <>
                {
                    this.state.todosSemControle === false ?
                        <div className="card text-light bg-warning mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Alertas no estoque</h5>
                                <h3 className="card-text text-center text-white">
                                    {this.state.quantity}
                                </h3>
                                <div className="text-center">
                                    <small className="card-text text-white">Produtos com estoque abaixo do mínimo</small>
                                </div>
                            </div>
                        </div> :
                        <div className="card text-light bg-warning mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Sem controle de estoque</h5>
                                <div className="text-center">
                                    <small className="card-text text-white">Não foram encontrados produtos que estejam com controle de estoque.</small>
                                </div>
                            </div>
                        </div>
                }
            </>)
    }
}

class ModifierInventory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            produto: props.produto,
            modificadorHistorico: false,
            modificadorTipo: '',
            modificadorDescricao: "",
            modificadorEstoque: 0,
            estoqueDisponivel: 0,
            isHiddenHistory: true,
            isLoading: false,
            items: []
        }
        this.loadResultado()
    }

    showHistory = () => {
        this.loadItems()
        this.setState({ isHiddenHistory: false })
        sendLogByUser("Produtos e serviços", "tocar no botão de ver histórico de estoque")
    }

    loadItems = async () => {
        this.setState({ isLoading: true });
        const results = await firebase.firestore().collection('produtos-estoque-historico').where('estacionamentoId', '==', this.state.estacionamentoId).where('produtoId', '==', this.props.produto.id).get()
        this.setState({ isLoading: false });
        var items = []
        results.docs.forEach(doc => {
            items.push({
                ...doc.data(),
                id: doc.id
            })
        })
        items = items.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
        this.setState({ items: items })
    }

    saveModifier = async () => {
        if (this.state.modificadorTipo === '') {
            toastWarning("Informe o motivo.")
            return
        }
        toastLoading("Atualizando estoque...")
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId,
            produtoId: this.props.produto.id,
            revendaId: getRevendaIdByPark(),
            produtoNome: this.props.produto.nome,
            modificadorTipo: this.state.modificadorTipo,
            estoqueAnterior: this.props.produto.estoqueDisponivel,
            type: "MANUAL",
            estoqueDisponivel: parseInt(this.state.estoqueDisponivel),
            estoqueFinal: parseInt(this.state.resultadoSoma)
        }
        await addDoc({ collection: Collection.PRODUCT_INVENTORY_HISTORY, data: data })
        const product = await getProductById({ id: this.state.produto.id })
        product.estoqueDisponivel = parseInt(this.state.resultadoSoma)
        product.notified = false
        product.controlarEstoque = "TRUE"
        await updateDoc({ collection: Collection.PRODUCT, id: product.id, data: product })
        sendClickButton("Produtos e serviços", "Editar estoque", true, product)
        reloadWithAlert('Estoque editado com sucesso')
    }

    changeModificadorEstoque = (e) => {
        this.setState({ modificadorTipo: e.target.value }, () => {
            this.loadResultado()
        })
    }

    changeEstoqueDisponivel = (e) => {
        this.setState({ estoqueDisponivel: e.target.value }, () => {
            this.loadResultado()
        })
    }

    loadResultado = () => {
        const estoqueDisponivel = parseInt(this.state.estoqueDisponivel === '' ? 0 : this.state.estoqueDisponivel)
        const disponivelProduto = parseInt(this.props.produto.estoqueDisponivel ?? 0)
        const totalSomar = disponivelProduto + estoqueDisponivel
        var totalSubtrair = disponivelProduto - estoqueDisponivel
        if (totalSubtrair < 0) {
            totalSubtrair = 0
        }
        switch (this.state.modificadorTipo) {
            case "SOMAR":
                this.setState({ resultadoSoma: parseInt(totalSomar) })
                this.setState({ modificadorDescricao: `${disponivelProduto} + ${estoqueDisponivel} = ${totalSomar}` });
                break;
            case "SUBTRAIR":
                this.setState({ resultadoSoma: parseInt(totalSubtrair) })
                this.setState({ modificadorDescricao: `${disponivelProduto} - ${estoqueDisponivel} = ${totalSubtrair}` });
                break;
            case "CORRIGIR":
                this.setState({ resultadoSoma: parseInt(estoqueDisponivel) })
                this.setState({ modificadorDescricao: `${disponivelProduto} = ${estoqueDisponivel}` });
                break;
        }
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className="col-lg-12">
                        <h5 className='text-center'>Editar estoque</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className='row'>
                            <div className="col-lg-6">
                                <label>Motivo</label>
                                <select className='form-select' value={this.state.modificadorTipo} onChange={(e) => this.changeModificadorEstoque(e)}>
                                    <option value="">Selecionar</option>
                                    <option value="SOMAR">Somar ao estoque</option>
                                    <option value="SUBTRAIR">Subtrair do estoque</option>
                                    <option value="CORRIGIR">Corrigir o estoque</option>
                                </select>
                            </div>
                            <div className="col-lg-6">
                                <label>Estoque</label>
                                <input type="number" onChange={(e) => this.changeEstoqueDisponivel(e)} value={this.state.estoqueDisponivel} className="form-control" />
                            </div>
                            {
                                this.state.modificadorDescricao !== "" &&
                                <div className="col-lg-12 my-3">
                                    <div className="alert alert-secondary">
                                        {this.state.modificadorDescricao}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-12 my-3">
                        <div className="btn-group">
                            {
                                this.state.isHiddenHistory &&
                                <button onClick={this.showHistory} className='btn btn-secondary btn-block'>Ver histórico</button>
                            }
                            <button onClick={this.saveModifier} className='btn btn-primary'>Confirmar</button>
                        </div>
                    </div>
                </div>
                {

                    !this.state.isHiddenHistory && <>
                        <div className="col-12">
                            {
                                this.state.items.length === 0 && this.state.isLoading === false ?
                                    <EmptyContent text="Nenhum histórico de lançamento de estoque para este produto" />
                                    :
                                    this.state.isLoading === false ?
                                        <>
                                            <h5 className="text-center">Todos os lançamentos</h5>
                                            <div className='row'>
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <td scope="col"><strong>Responsável</strong></td>
                                                                <td scope="col"><strong>Estoque antigo</strong></td>
                                                                <td align='center' scope="col"><strong>Ação</strong></td>
                                                                <td align='center' scope="col"><strong>Data</strong></td>
                                                                <td scope="col" align='center'><strong>Estoque final</strong></td>
                                                                <td width={50}></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.items.map(item =>
                                                                    <tr key={item.id}>
                                                                        <td>{item.createdBy.nome}</td>
                                                                        <td align='center'>{item.estoqueAnterior ?? "N/A"}</td>
                                                                        {item.modificadorTipo === "SOMAR" &&
                                                                            <td align='center'>
                                                                                <h6><span className="badge text-bg-success w-100 text-white">{item.estoqueDisponivel} itens somados ao estoque</span></h6>
                                                                            </td>
                                                                        }
                                                                        {item.modificadorTipo === "SUBTRAIR" &&
                                                                            <td align='center'>
                                                                                <h6><span className="badge text-bg-warning w-100 text-white">{item.estoqueDisponivel} itens subtraidos do estoque</span></h6>
                                                                            </td>
                                                                        }
                                                                        {item.modificadorTipo === "CORRIGIR" &&
                                                                            <td align='center'>
                                                                                <h6><span className="badge text-bg-secondary w-100 text-white">{item.estoqueDisponivel} itens definido como estoque</span></h6>
                                                                            </td>
                                                                        }
                                                                        <td>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                        <td align='center'>{item.estoqueFinal}</td>
                                                                        <td>
                                                                            {
                                                                                item.reference !== undefined &&
                                                                                <Tooltip title={item.log} placement="top">
                                                                                    <a href={'/buscar/ticket?id=' + item.reference} target="_blank" type="button" className="btn btn-warning text-white">
                                                                                        <i className="fas fa-ticket-alt"></i>
                                                                                    </a>
                                                                                </Tooltip>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <Loading />
                            }
                        </div>
                    </>
                }
            </>)
    }
}

class CreateOrEditCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collection: props.collection,
            name: props.name,
            categoryName: "",
            categorieBackground: "#fff",
            estacionamentoId: getEstacionamento()?.id,
            items: [],
            isHidden: true,
            isLoading: true
        }
    }

    showCategories = () => {
        this.loadItems()
        this.setState({ isHidden: false })
    }

    loadItems = async () => {
        this.setState({ isLoading: true });
        if (this.state.estacionamentoId === undefined) {
            return
        }
        const results = await firebase.firestore().collection(this.state.collection).where('estacionamentoId', '==', this.state.estacionamentoId).get()
        this.setState({ isLoading: false });
        var items = []
        results.docs.forEach(doc => {
            items.push({
                ...doc.data(),
                id: doc.id
            })
        })
        this.setState({ items: items })
    }

    changeCategorieBackgroundCompletion = (color) => {
        this.setState({ categorieBackground: color.hex });
    }

    confirm = async () => {
        if (this.state.categoryName === "" || this.state.estacionamentoId === undefined) {
            return
        }
        const results = await firebase.firestore().collection(this.state.collection).where('estacionamentoId', '==', this.state.estacionamentoId).where('name', '==', this.state.categoryName).get()
        if (results.docs.length === 0) {
            const data = {
                estacionamentoId: this.state.estacionamentoId,
                clienteId: getCliente().id,
                revendaId: getRevendaIdByPark(),
                name: this.state.categoryName,
                color: this.state.categorieBackground,
                createdAt: new Date(),
                createdBy: getUsuario().email
            }
            await addDoc({ collection: this.state.collection, data: data })
            toastSuccess(`${this.state.name} cadastrada com sucesso!`);
            this.setState({ categoryName: "" })
            await saveLastUpdate()
            if (this.state.isHidden === false) {
                this.loadItems()
            }
        } else {
            toastWarning(`Já existe uma ${this.state.name} cadastrada com este nome`);
        }
    }

    onRemove = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover a ${this.state.name} ${item.name}?` });
        if (confirm) {
            await deleteDoc({ collection: this.state.collection, id: item.id })
            this.loadItems()
            await saveLastUpdate()
        }
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className="col-lg-12">
                        <h5 className='text-center'>Cadastrar {this.state.name}s</h5>
                    </div>
                    <div className="col-lg-12">
                        <div className='row'>
                            <div className="col-lg-6">
                                <label>Nome da {this.state.name}</label>
                                <input type="text" onChange={(e) => this.setState({ categoryName: e.target.value })} value={this.state.categoryName && this.state.categoryName} className="form-control" required />
                            </div>
                            <div className="col-lg-6">
                                <label>Escolha uma cor</label>
                                <SliderPicker color={this.state.categorieBackground}
                                    onChangeComplete={this.changeCategorieBackgroundCompletion} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 my-2">
                        <div className="btn-group">
                            <button onClick={this.confirm} className='btn btn-primary'>Confirmar</button>
                            {
                                this.state.isHidden &&
                                <button onClick={this.showCategories} className='btn btn-secondary btn-block'>Listar {this.state.name}s</button>
                            }
                        </div>
                    </div>
                </div>
                {

                    !this.state.isHidden && <>
                        <div className="col-12">
                            {
                                this.state.items.length === 0 && this.state.isLoading === false ?
                                    <EmptyContent text={`Nenhuma ${this.state.name} cadastrada`} />
                                    :
                                    this.state.isLoading === false ?
                                        <>
                                            <h5 className="text-center">Todas as {this.state.name}</h5>
                                            <div className='row'>
                                                <div className="table-responsive">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <td scope="col"><strong>Nome</strong></td>
                                                                <td scope="col"><strong></strong></td>
                                                                <td scope="col"></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.items.map(item =>
                                                                    <tr key={item.id}>
                                                                        <td>{item.name}</td>
                                                                        <td>
                                                                            <h3 style={{ backgroundColor: item.color, color: item.color, borderRadius: 3 }}>-</h3>
                                                                        </td>
                                                                        <td width='10'>
                                                                            <button type="button" onClick={(e) => this.onRemove(item)} className="btn btn-danger">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <Loading />
                            }
                        </div>
                    </>
                }
            </>)
    }
}

export { CriarProduto, ListagemProdutos };