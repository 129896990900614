import React from 'react'
import { NavBar } from '../../components/navbar'
import { Loading } from '../../components/common/commons'
import 'firebase/auth';
import 'firebase/storage';
import { getEstacionamento } from '../../config/auth';
import Moment from 'moment';
import { getAllCashiersByAnalytics } from '../../store/collections/cashierWorker';
import { getAllParks } from '../../store/collections/parkWorker';

class AnalyticsCaixas extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <AnalyticsCaixasBody props={this.props} />
                </NavBar>
            </>)
    }
}

class AnalyticsCaixasBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            limit: 50,
            items: [],
            isLoading: true,
            parks: []
        }
        this.load()
        this.loadParks()
    }

    loadParks = async () => {
        const parks = await getAllParks()
        this.setState({ parks: parks })
    }

    load = async () => {
        const items = await getAllCashiersByAnalytics({ limit: this.state.limit })
        this.setState({ items: items })
        this.setState({ cashierOkay: items.filter(e => e.statusFechamento === "Bateu" || e.statusFechamento === "Cego").length })
        this.setState({ cashierLess: items.filter(e => e.statusFechamento === "Faltou").length })
        this.setState({ cashierMore: items.filter(e => e.statusFechamento === "Sobrou").length })
        this.setState({ isLoading: false })
    }

    handlerLimit = (e) => {
        this.setState({ limit: parseInt(e.target.value) }, () => {
            this.load()
        })
    }

    statusCashier = (item) => {
        if (item.statusFechamento === "Bateu" || item.statusFechamento === "Cego") {
            return "btn btn-success"
        }
        if (item.statusFechamento === "Sobrou") {
            return "btn btn-warning text-white"
        }
        if (item.statusFechamento === "Faltou") {
            return "btn btn-danger"
        }
        return "btn btn-secondary"
    }

    statusCashierBadge = (item) => {
        if (item.statusFechamento === "Bateu" || item.statusFechamento === "Cego") {
            return "badge text-bg-success text-white w-100"
        }
        if (item.statusFechamento === "Sobrou") {
            return "badge text-bg-warning text-white w-100"
        }
        if (item.statusFechamento === "Faltou") {
            return "badge text-bg-danger text-white w-100"
        }
        return "badge text-bg-success text-white w-100"
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Últimos caixas
                        <div className='col-lg-2'>
                            <select className='form-select form-select-sm' value={this.state.monthiesAgo} onChange={this.handlerLimit}>
                                <option value="50">Últimos 50</option>
                                <option value="100">Últimos 100</option>
                                <option value="150">Últimos 150</option>
                                <option value="250">Últimos 250</option>
                                <option value="350">Últimos 350</option>
                                <option value="400">Últimos 400</option>
                                <option value="500">Últimos 500</option>
                                <option value="600">Últimos 600</option>
                                <option value="700">Últimos 700</option>
                                <option value="800">Últimos 800</option>
                                <option value="900">Últimos 900</option>
                            </select>
                        </div>
                    </div>
                    <div className='card-body'>
                        {
                            this.state.isLoading ? <Loading /> :
                                <div className="row">
                                    <div className='col-lg-3'>
                                        <div className="card text-light bg-success mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title text-center text-white">Bateu</h5>
                                                <h3 className="card-text text-center text-white">
                                                    {this.state.cashierOkay}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="card text-light bg-danger mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title text-center text-white">Faltou</h5>
                                                <h3 className="card-text text-center text-white">
                                                    {this.state.cashierLess}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="card text-light bg-warning mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title text-center text-white">Sobrou</h5>
                                                <h3 className="card-text text-center text-white">
                                                    {this.state.cashierMore}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='table-responsive'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width={10}></th>
                                                    <th scope="col">Estabelecimento</th>
                                                    <th scope="col">Abertura</th>
                                                    <th scope="col">Fechamento</th>
                                                    <th scope="col">Observação</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map(item =>
                                                        <tr>
                                                            <td width='10'>
                                                                <a href={'/caixas/' + item.id} target='_blank'>
                                                                    <button type="button" className={this.statusCashier(item)}>
                                                                        <i className="fas fa-eye" />
                                                                    </button>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {this.state.parks.filter(e => e.id === item.estacionamentoId)[0]?.nome}
                                                            </td>
                                                            {
                                                                <td>{item.openAt ? Moment(item.openAt.toDate()).format("DD/MM/YYYY [às] HH:mm") : "-"}</td>
                                                            }
                                                            {
                                                                <td>{item.closeAt ? Moment(item.closeAt.toDate()).format("DD/MM/YYYY [às] HH:mm") : "-"}</td>
                                                            }
                                                            <td>
                                                                {item.comentarioFinal ? item.comentarioFinal : "-"}
                                                            </td>
                                                            <td width={10}><span className={this.statusCashierBadge(item)}>{item.statusFechamento}</span></td>
                                                            <td width='10'>
                                                                <a href={'/revenda/cliente/' + item.clienteId} target='_blank'>
                                                                    <button type="button" className="btn btn-primary">
                                                                        <i className="fas fa-eye" />
                                                                    </button>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>)
    }
}

export { AnalyticsCaixas };