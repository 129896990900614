import { pdf } from '@react-pdf/renderer';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import moment from 'moment';
import toast from 'react-hot-toast';
import { v4 } from 'uuid';
import React from "react";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const queryString = (key) => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    return params.get(key)
}

export const secondsToDescription = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var hDisplay = h > 0 ? h + (h === 1 ? " hora e " : " horas e ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minuto" : " minutos") : "";
    if (h === 24 && m === 0) {
        return "24 horas"
    }
    if (m === 0) {
        if (h === 0) {
            return "Menos de 1 minuto"
        }
        return (h === 1 ? h + " hora" : h + " horas")
    }
    const title = hDisplay + mDisplay
    if (title === "") {
        return "Sem dado"
    }
    return title;
}

export const minutesToHoursDescription = (d) => {
    const hours = Number(d) / 60
    if (hours === 0) {
        return "Menos de uma hora"
    }
    if (hours === 1) {
        return `${hours} hora`
    }
    return `${hours} horas`
}

export const permanenceBetweenDates = ({ startDate, endDate }) => {
    const minutesPermanence = minutesFromNow(startDate, endDate)
    return secondsToDescription(minutesPermanence * 60)
}

export const ticketPermanenceFromNowDescription = (ticket) => {
    const minutesPermanence = minutesFromNow(ticket.createAt, ticket.closedAt)
    return secondsToDescription(minutesPermanence * 60)
}

export const ticketPermanenceFromNowDescriptionEdited = (ticket, deliveryDate) => {
    const minutesPermanence = minutesFromNow(ticket.createAt, deliveryDate)
    return secondsToDescription(minutesPermanence * 60)
}

export const cashierPermanenceFromNowDescription = (cashier) => {
    const minutesPermanence = minutesFromNow(cashier.openAt, moment())
    return secondsToDescription(minutesPermanence * 60)
}

export const isProd = () => {
    return process.env.REACT_APP_ENV !== "dev"
}

export const isSelfParking = () => {
    return process.env.REACT_APP_REVENDA_ID === "yDDlIMYGASii6Scv1Un8" || process.env.REACT_APP_REVENDA_ID === "4nuxAiiMqLu2A1N8VwVP"
}

export const getResellerIdByEnv = () => {
    return process.env.REACT_APP_REVENDA_ID
}

export const secondsDDHHMM = (from, to) => {
    var result = Number(to - from);
    var d = Math.floor(result / 86400);
    if (d > 1) {
        return d > 0 ? d + (d === 1 ? " dia" : " dias") : "";
    }
    var h = Math.floor(result / 3600);
    var m = Math.floor(result % 3600 / 60);
    var dDisplay = d > 0 ? d + (d === 1 ? " dia e " : " dias e ") : "";
    var hDisplay = h > 0 && h < 24 ? h + (h === 1 ? " hora e " : " horas e ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minuto e " : " minutos") : "";
    return dDisplay + hDisplay + mDisplay
}

export const minutesFromNow = (start, end) => {
    var now = moment(start.toDate())
    var end = moment(end?.toDate() ?? new Date())
    return moment.duration(end.diff(now)).asMinutes() ?? 0
}

export const hoursToMin = (hours) => {
    if (hours) {
        if (hours.indexOf(":") !== -1) {
            const first = Math.floor(parseFloat(hours.split(":")[0] * 60))
            const seconds = parseFloat(hours.split(":")[1])
            return (first + seconds)
        }
        return Math.floor(hours / 60)
    }
    return null
}

export const exportAndDownloadPDF = async (doc, filename) => {
    const blob = await pdf(doc).toBlob()
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const exportAndPrintPDF = async (doc) => {
    const blob = await pdf(doc).toBlob()
    var url = URL.createObjectURL(blob)
    const iframe = document.createElement('iframe')
    document.body.appendChild(iframe)

    iframe.style.display = 'none'
    iframe.src = url;
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print()
        }, 1);
    };
}

export const exportAndDownloadTxt = async (json, filename) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json, null, 2));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
}

export const copyLinkAndShare = (data) => {
    try {
        navigator.clipboard.writeText(data.url);
        toastSuccess("Link copiado com sucesso.")
    } catch (error) {
        if (navigator.share) {
            navigator.share({
                title: data.title ?? "",
                text: data.message ?? "",
                url: data.url,
            }).then(() =>
                toastSuccess("Link copiado com sucesso."))
                .catch((error) => console.log('Error sharing', error));
        } else {
            navigator.clipboard.writeText(data.url);
            toastSuccess("Link copiado com sucesso.")
        }
    }
}

export const exportAndDownloadXLS = (data, filename, delimiter) => {
    const csv = convertToCSV(data, delimiter)
    const blob = new Blob([csv], { type: 'text/xls;charset=UTF-8' })
    var url = URL.createObjectURL(blob);
    var link = document.createElement("a");
    const fileWithExtension = `${filename}.csv`
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileWithExtension);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileWithExtension);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export const convertToCSV = (data, delimiter) => {
    const replacer = (key, value) => value === null ? '' : removeSpecialCharacters(value)
    const header = Object.keys(data[0])
    const csv = [
        header.join(delimiter ?? ','),
        ...data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(delimiter ?? ','))
    ].join('\r\n')
    return csv
}

export const isValidUrl = (str) => {
    const pattern = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );
    return pattern.test(str ?? "");
}

export const isValidatePlate = (str) => {
    const plate = str.toUpperCase().replace('-', '')
    const numbers = plate.substring(3, 7)
    const letters = plate.substring(0, 3)
    if (plate.length === 7 && letters.match(/[a-zA-Z]/)) {
        // Placa Antiga: FGR4747
        if (!isNaN(numbers)) {
            return true
        }
        // Placa Nova: AAA2A22
        const lastLetter = plate.substring(4, 5)
        if (lastLetter.match(/[a-zA-Z]/)) {
            const firstNumber = plate.substring(3, 4)
            const lastNumbers = plate.substring(5, 7)
            if (!isNaN(firstNumber) && !isNaN(lastNumbers)) {
                return true
            }
        }
    }
    return false
}

export const onlyNumbers = (str) => {
    var res = str.replace(/\D/g, '');
    return String(res)
}

export const isValidDocument = (str) => {
    const document = String(str)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
    if (document.length === 11) {
        return cpf.isValid(document)
    } else {
        return cnpj.isValid(document)
    }
}

export const isValidCNPJ = (str) => {
    const document = String(str)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
    return cnpj.isValid(document)
}

export const isValidCPF = (str) => {
    const document = String(str)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
    return cpf.isValid(document)
}

export const documentMask = (value) => {
    if (isNullOrEmpty(value)) {
        return ""
    }
    const document = String(value)?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "").replaceAll("_", "")
    if (document.length === 11) {
        return document
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1-$2')
    } else {
        return document
            .replace(/\D+/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
    }
}

export const toStringFromSeconds = ({ seconds, format }) => {
    if (seconds && format) {
        return moment(seconds * 1000).format(format)
    }
    return "-"
}

export const toStringFromDate = ({ date, format }) => {
    if (!isNullOrEmpty(date) && !isNullOrEmpty(format)) {
        return moment(date.seconds * 1000).format(format)
    }
    return "-"
}

export const cellphoneMask = (value) => {
    if (isNullOrEmpty(value)) {
        return ""
    }
    const cellphone = normalizedField(String(value))
    if (cellphone.length === 11) {
        return cellphone.replace(/\D+/g, '')
            .replace(/(\d{2})(\d)/, '($1)$2')
            .replace(/(\d{5})(\d)/, '$1-$2')
    } else {
        return cellphone
            .replace(/\D+/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
    }
}

export const isNullOrEmpty = (value) => {
    return isNull(value) || value === "" || value === "null" || value === "undefined" || value.length == 0
}

export const takeIfIsNotNullOrEmpty = (value) => {
    return isNullOrEmpty(value) ? undefined : value
}

export const isEmpty = (value) => {
    return value === ""
}

export const isNull = (value) => {
    return value === undefined || value === null || value === "undefined"
}

export const isNotNull = (value) => {
    return !isNull(value)
}

export const isValidEmail = (email) => {
    return String(email).toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
}

export const generateUUIDV7 = () => {
    return v4();
}

export const randomNumber = () => {
    return Math.floor(Math.random() * 99999999)
}

export const generateXAPIKey = () => {
    const value = String(Math.floor(Math.random() * 9999999999)).padEnd(10, "0")
    return value
}

export const searchIpInfo = async () => {
    return new Promise(function (resolve, reject) {
        fetch("https://ipapi.co/json/")
            .then(response => response.json())
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                resolve({
                    ip: null,
                })
            })
    })
}

export const toastWarning = (message) => {
    toast(message, {
        style: {
            padding: '16px',
        },
        duration: 1000,
        icon: '⚠️',
    })
}

export const toastLoading = (message) => {
    toast.loading(message ?? "Carregando...", {
        id: "loading",
        style: {
            padding: '16px',
        },
    })
}

export const toastDismissLoading = () => {
    toast.dismiss("loading")
}

export const toastSuccess = (message) => {
    toast.success(message, {
        style: {
            padding: '16px',
        },
        duration: 5000,
    })
}

export const toastError = (message) => {
    toast.error(message, {
        duration: 5000,
    })
}

export const defaultConfirm = ({ title, message }) => {
    return alertDefault({ title, message, classButton: "btn btn-primary" })
}

export const successConfirm = ({ title, message }) => {
    return alertDefault({ title, message, classButton: "btn btn-success" })
}

export const dangerConfirm = ({ title, message }) => {
    return alertDefault({ title, message, classButton: "btn btn-danger" })
}

export const warningConfirm = ({ title, message }) => {
    return alertDefault({ title: title ?? "Informação", message, classButton: "btn btn-warning text-white" })
}

const alertDefault = ({ title, message, classButton }) => {
    return new Promise((resolve) => {
        const toastId = generateUUIDV7();
        const handleClose = (result) => {
            toast.dismiss(toastId);
            resolve(result);
        };
        toast.custom((t) => (
            <Dialog maxWidth="xs" open={t.visible} onClose={() => handleClose(false)}>
                <h3 className="text-center mt-3">
                    {title ?? "Atenção"}
                </h3>
                <MuiDialogContent>
                    <div className='text-center'>{message?.split("\n").map(e => <div>{e}</div>)}</div>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className="btn-group m-3">
                        <button onClick={() => handleClose(false)} className="btn btn-secondary">
                            Cancelar
                        </button>
                        <button onClick={() => handleClose(true)} className={classButton}>
                            Confirmar
                        </button>
                    </div>
                </MuiDialogActions>
            </Dialog>
        ), {
            duration: 50000,
            id: toastId,
            visible: true,
        });
    });
};

export const reloadWithAlert = (message, time) => {
    toastSuccess(message)
    setTimeout(function () {
        window.location.reload()
    }, time ?? 1000)
}

export const reloadWithAlertError = (message, time) => {
    toastSuccess(message)
    setTimeout(function () {
        window.location.reload()
    }, time ?? 1000)
}

export const reloadWindow = () => {
    window.location.reload()
}

export const setPathWindow = (path) => {
    window.history.pushState('', '', path)
}

export const goToWindowWithAlert = (path, message, time) => {
    toastSuccess(message)
    setTimeout(function () {
        goToWindow(path)
    }, time ?? 1000)
}

export const goToWindow = (path) => {
    window.location.href = path
}

export const goToFirebase = ({ collection, id }) => {
    window.open(`https://console.firebase.google.com/project/selfparking-prod-26925/firestore/databases/-default-/data/~2F${collection}~2F${id}`, '_blank')
}

export const goToNewWindow = (path) => {
    window.open(path, '_blank')
}

export const fileToBase64 = ({ file }) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.toString().substr(reader.result.toString().indexOf(',') + 1));
    reader.onerror = reject;
});

export const toCurrency = (value) => {
    var monetary = value
    if (isNaN(value)) {
        monetary = 0
    }
    return (parseFloat(monetary ?? 0) ?? "0").toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace(/\s/g, '');
}

export const normalizedField = (data) => {
    return String(data ?? "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "").replaceAll("[", "").replaceAll("{", "").replaceAll("]", "").replaceAll("}", "")
}

export const capitalizeField = (data) => {
    const value = String(data ?? "")
    if (!isNullOrEmpty(value)) {
        return value.slice(0, 1).toUpperCase() + value.slice(1)
    }
    return data
}

export const valueByMaximunChars = (value, maximun) => {
    const data = String(value ?? "")
    if (data.length > (maximun ?? 100)) {
        return `${data.substring(0, maximun ?? 100)}`
    }
    return data
}

export const removeSpecialCharacters = (data) => {
    return String(data ?? "").replace(/[\u0300-\u036f]/g, "")
}

export const scrolltoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const timestampToSeconds = (value) => {
    if (value?.seconds) {
        return value.seconds
    }
    const date = new Date(value);
    const seconds = Math.floor(date.getTime() / 1000);
    return seconds;
}

export const toDateFormat = (value) => {
    var seconds = 0
    if (value?.seconds) {
        seconds = value.seconds
    } else {
        seconds = timestampToSeconds(value)
    }
    return `${new Date(seconds * 1000).toLocaleDateString('pt-BR')} às ${new Date(seconds * 1000).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`
}

export const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms))
}

export const isJSON = (str) => {
    try {
        var o = JSON.parse(str);
        if (o && typeof o === "object") {
            return true;
        }
    }
    catch (e) {
        return false;
    }
    return false;
}

export const chunkItemsByQuantity = ({ items, quantity }) => {
    var aux = []
    const chunkSize = quantity ?? 10;
    for (let i = 0; i < items.length; i += chunkSize) {
        const chunk = items.slice(i, i + chunkSize);
        aux.push(chunk)
    }
    return aux
}

export const verifyTicketType = (ticket) => {
    switch (true) {
        case !isNullOrEmpty(ticket.monthly):
            return { tipo: "Mensalista", tipoResume: "M" }
        case !isNullOrEmpty(ticket.accredited):
            return { tipo: "Credenciado", tipoResume: "C" }
        case ticket.type === "SALES":
            return { tipo: "Venda Avulsa", tipoResume: "V" }
        default:
            return { tipo: "Avulso", tipoResume: "A" }
    }
}