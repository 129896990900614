import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getPerfil, getUsuario, isAdministrador } from '../../config/auth'
import { Tabs, Tab } from 'react-bootstrap';
import { DatePickerButton, DatePickerDialog, EmptyContent, Loading } from '../../components/common/commons'
import { TicketAnalyticsDialog, TicketSimplyList } from '../buscas/ticketSearch'
import { MensalistasSimplyList } from '../mensalista/clientes/index'
import { CashierTotals, CloseCashierModal } from '../cashier/components'
import Moment from 'moment';
import { getCashiersWithRevenuesById } from '../../store/collections/cashier'
import 'firebase/auth';
import 'firebase/storage';
import Tooltip from '@mui/material/Tooltip';
import { goToWindow, isNullOrEmpty, queryString, reloadWithAlert, secondsDDHHMM, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastWarning, toCurrency } from '../../shared/utils';
import { sendLogByUser, sendScreenView } from '../../shared/analytics';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { getParkById } from '../../store/collections/parkWorker';
import { AlertInfo, AlertSecondary, AlertSuccess } from '../../shared/alert-manager';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getCashierBySequence, updateCashierById } from '../../store/collections/cashierWorker';
import PickersModalDialog from '@mui/lab/internal/pickers/PickersModalDialog';

class DetailCashier extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            key: "tickets",
            clienteId: getCliente()?.id,
            estacionamentoId: getEstacionamento()?.id,
            random: Math.random(),
            caixa: null,
            caixas: [],
            tickets: [],
            monthlies: [],
            products: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    load = async () => {
        this.setState({ loading: true })
        if (this.state.clienteId !== null) {
            this.setState({ isLoading: true });
            this.setState({ notFound: false });
            const resultado = await getCashiersWithRevenuesById({
                clienteId: this.state.clienteId,
                caixaId: this.props.match.params.id
            })
            if (resultado !== null) {
                const caixa = resultado.caixas[0]
                if (caixa.clienteId !== this.state.clienteId) {
                    if (isAdministrador()) {
                        goToWindow(`/revenda/cliente/${caixa.clienteId}?e=${caixa.estacionamentoId}`)
                    } else {
                        goToWindow("")
                    }
                } else {
                    this.setState({ resultado: resultado })
                    this.setState({ caixa: caixa })
                    this.setState({ tickets: caixa.tickets })
                    this.setState({ monthlies: resultado.mensalistas ?? [] })
                    this.setState({ random: Math.random() })
                    this.setState({ loading: false })
                    const park = await getParkById({ id: caixa.estacionamentoId })
                    this.setState({ park: park })
                }
            } else {
                toastWarning('Caixa não encontrado')
                window.location.href = '/'
            }
            this.setState({ resultado: resultado }, () => {
                this.setState({ random: Math.random() })
                this.setState({ isLoading: false });
            })
            const from = queryString("from")
            if (from === "email") {
                sendScreenView("Relatório", "Detalhes caixa | Por e-mail")
            } else {
                sendScreenView("Relatório", "Detalhes caixa")
            }
        }
    }

    showAnalyticsModal = () => {
        this.setState({ isShowingAnalyticsModal: true })
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ isShowingAnalyticsModal: false })
    }

    messageTimeOpenCashier = () => {
        if (this.state.caixa.openAt) {
            return <span>Este caixa ficou aberto por <strong>{secondsDDHHMM(this.state.caixa.openAt.seconds, this.state.caixa.closeAt.seconds)}</strong></span>
        }
        return ""
    }

    closeNewCashier = () => {
        const user = getUsuario()
        const profile = getPerfil()
        if (user.administrativo === true || profile?.habilitarFecharCaixa === true) {
            this.setState({ cashierIdToClose: this.state.caixa.id })
            this.setState({ showModalToCloseCashier: true })
        } else {
            toastWarning("Você não tem permissão para fechar um caixa.")
        }
    }

    showCashierSequence = () => {
        this.setState({ newSequence: parseInt(this.state.caixa.sequence).toString() })
        this.setState({ showModalToChangeCashier: true })
    }

    goToPreviews = async () => {
        const newSequence = parseInt(this.state.caixa.sequence) - 1
        this.goToCashierBySequence(newSequence, "previous")
    }

    goToNext = () => {
        const newSequence = parseInt(this.state.caixa.sequence) + 1
        this.goToCashierBySequence(newSequence, "next")
    }

    goToCashierBySequence = async (sequence, direction) => {
        toastLoading("Buscando caixa ...")
        const cashier = await getCashierBySequence({ estacionamentoId: this.state.caixa.estacionamentoId, sequence: sequence })
        toastDismissLoading()
        if (cashier) {
            goToWindow(`/caixas/${cashier.id}`)
        } else {
            if (direction === "previous") {
                toastWarning("Caixa anterior não encontrada.")
            } else {
                toastWarning("Este é o último caixa aberto.")
            }
        }
    }

    confirmCashierDetail = async () => {
        var newSequence = this.state.newSequence
        this.setState({ modalToChangeSequenceLoading: true })
        newSequence = newSequence.replace(/[^0-9]/g, '').padStart(7, '0')
        var cashier = await getCashierBySequence({ estacionamentoId: this.state.caixa.estacionamentoId, sequence: newSequence })
        if (cashier?.id !== this.state.caixa.id) {
            this.setState({ modalToChangeSequenceLoading: false })
            toastWarning("Já existe uma caixa com a sequência informada")
        } else {
            cashier = this.state.caixa
            if (cashier.sequence !== newSequence) {
                sendLogByUser("Caixa", `alterou o número sequêncial do caixa de ${this.state.caixa.sequence} para ${newSequence}`)
                cashier.sequence = newSequence
            }
            if (this.state.newOpenAt) {
                sendLogByUser("Caixa", `alterou a data de abertura do caixa de ${Moment(this.state.caixa.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")} para ${Moment(this.state.newOpenAt).format("DD/MM/YYYY [às] HH:mm")}`)
                cashier.openAt = this.state.newOpenAt.toDate()
            }
            if (this.state.newCloseAt) {
                sendLogByUser("Caixa", `alterou a data de abertura do caixa de ${Moment(this.state.caixa.closeAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")} para ${Moment(this.state.newCloseAt).format("DD/MM/YYYY [às] HH:mm")}`)
                cashier.closeAt = this.state.newCloseAt.toDate()
            }
            await updateCashierById({ id: this.state.caixa.id, data: cashier })
            this.setState({ showModalToChangeCashier: false })
            reloadWithAlert("Caixa salvo com sucesso")
        }
    }

    render() {
        return (
            <NavBar>
                {
                    this.state.loading === true &&
                    <div className="card mb-4">
                        <EmptyContent text="Carregando informações..." />
                    </div>
                }
                <div className="row">
                    {
                        this.state.loading === false && <>
                            {/* <div className='row'>
                                {
                                    this.state.resultado &&
                                    <PDFViewer style={{ height: "1000px" }}>
                                        <CashierReportDocument
                                            resultado={this.state.resultado}
                                            impressaoTabelasUtilizadasCaixas={this.state.impressaoTabelasUtilizadasCaixas}
                                            impressaoCaixaTotais={this.state.impressaoCaixaTotais}
                                            impressaoConveniosCaixas={this.state.impressaoConveniosCaixas}
                                            impressaoListagemPagamentosPorCaixas={this.state.impressaoListagemPagamentosPorCaixas}
                                            impressaoListagemMensalistasCaixas={this.state.impressaoListagemMensalistasCaixas}
                                            impressaoListagemTicketsCaixas={this.state.impressaoListagemTicketsCaixas}
                                            impressaoListagemProdutosCaixas={this.state.impressaoListagemProdutosCaixas}
                                            impressaoMovimentacoesCaixas={this.state.impressaoMovimentacoesCaixas}
                                            impressaoRetiradasCaixas={this.state.impressaoRetiradasCaixas}
                                            impressaoCancelamentosCaixas={this.state.impressaoCancelamentosCaixas}
                                        />
                                    </PDFViewer>
                                }
                            </div> */}
                            <div className="col-lg-7">
                                <CashierTotals key={this.state.random}
                                    resultado={this.state.resultado} />
                                {this.state.caixa !== null &&
                                    <div className="row">
                                        <div className='col-lg-12'>
                                            <div className="card mb-4">
                                                <div className="card-header d-flex justify-content-between align-items-center">
                                                    {
                                                        this.state.caixa.sequence ?
                                                            <>
                                                                {
                                                                    isAdministrador() ?
                                                                        <h6 className="m-0 font-weight-bold">
                                                                            <div className='btn-group'>
                                                                                <Tooltip title="Editar caixa" placement='top'>
                                                                                    <button type="button" onClick={this.showCashierSequence} className="btn btn-danger btn-sm">
                                                                                        <i className="fas fa-edit" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip title="Caixa anterior" placement='top'>
                                                                                    <button type="button" onClick={this.goToPreviews} className="btn btn-secondary btn-sm">
                                                                                        <i className="fas fa-arrow-left" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip title="Próximo caixa" placement='top'>

                                                                                    <button type="button" onClick={this.goToNext} className="btn btn-secondary btn-sm">
                                                                                        <i className="fas fa-arrow-right" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <span className='mx-2'>{this.state.caixa.sequence} - Detalhes de operação do caixa</span>
                                                                        </h6> :
                                                                        <h6 className="m-0 font-weight-bold">
                                                                            <div className='btn-group'>
                                                                                <Tooltip title="Caixa anterior" placement='top'>
                                                                                    <button type="button" onClick={this.goToPreviews} className="btn btn-secondary btn-sm">
                                                                                        <i className="fas fa-arrow-left" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip title="Próximo caixa" placement='top'>

                                                                                    <button type="button" onClick={this.goToNext} className="btn btn-secondary btn-sm">
                                                                                        <i className="fas fa-arrow-right" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                            {this.state.caixa.sequence} - Detalhes de operação do caixa
                                                                        </h6>
                                                                }
                                                            </>
                                                            : <h6 className="m-0 font-weight-bold">Detalhes de operação do caixa</h6>
                                                    }
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <h5>Troco inicial</h5>
                                                            <h6>{toCurrency(this.state.caixa.valorInicial ?? 0)}</h6>
                                                        </div>
                                                        {
                                                            this.state.caixa.status === "fechado" && <>
                                                                <div className='col-lg-12'>
                                                                    <h5>Troco final</h5>
                                                                    <h6>{toCurrency(this.state.caixa.valorTrocoFinal ?? 0)}</h6>
                                                                </div>
                                                                {
                                                                    !isNullOrEmpty(this.state.caixa.comentarioFinal) &&
                                                                    <div className='col-lg-12'>
                                                                        <h5>Observação no fechamento</h5>
                                                                        <h6>{this.state.caixa.comentarioFinal}</h6>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            this.state.park &&
                                                            <div className='col-lg-12'>
                                                                <AlertSecondary tag="Estabelecimento">
                                                                    <span>{takeIfIsNotNullOrEmpty(this.state.park.nomeInterno) ?? this.state.park.nome}</span> <br />
                                                                    <span>Documento: {takeIfIsNotNullOrEmpty(this.state.park.documento) ?? "Não informado"}</span> <br />
                                                                </AlertSecondary>
                                                            </div>
                                                        }
                                                        <div className='col-lg-12'>
                                                            {
                                                                this.state.caixa.statusFechamento !== undefined && this.state.caixa.statusFechamento === "Bateu" &&
                                                                <div className="alert alert-success">
                                                                    <span>{this.messageTimeOpenCashier()} e segundo o perador que fez o fechamento o caixa <strong>bateu</strong></span>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.caixa.statusFechamento !== undefined && this.state.caixa.statusFechamento === "Sobrou" &&
                                                                <div className="alert alert-warning">
                                                                    <span>{this.messageTimeOpenCashier()} e segundo o perador que fez o fechamento o caixa <strong>teve sobra</strong></span>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.caixa.statusFechamento !== undefined && this.state.caixa.statusFechamento === "Faltou" &&
                                                                <div className="alert alert-danger">
                                                                    <span>{this.messageTimeOpenCashier()} e segundo o perador que fez o fechamento o caixa <strong>não bateu e faltou</strong></span>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.caixa.statusFechamento !== undefined && this.state.caixa.statusFechamento === "Cego" &&
                                                                <div className="alert alert-danger">
                                                                    <span>{this.messageTimeOpenCashier()} o caixa foi fechado com perfil de acesso <strong>sem visão de faturamento</strong></span>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.caixa.statusFechamento === "" &&
                                                                <div className="alert alert-secondary">
                                                                    <span>{this.messageTimeOpenCashier()}</span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            <AlertSuccess tag="Aberto">
                                                                <strong>Operador: {this.state.caixa.openBy.nome}</strong><br />
                                                                <strong>Login: {this.state.caixa.openBy.email}</strong><br />
                                                                {
                                                                    this.state.caixa.openAt &&
                                                                    <strong>
                                                                        Data: {Moment(this.state.caixa.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                                    </strong>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(this.state.caixa.openOrigin) &&
                                                                    <><br /> <strong>Aberto em: {this.state.caixa.openOrigin}</strong><br /></>
                                                                }
                                                                {
                                                                    !isNullOrEmpty(this.state.caixa.openUserAllowed?.nome) &&
                                                                    <>
                                                                        <br /> <strong className='text-danger'>Autorizado por: {this.state.caixa.openUserAllowed?.nome}</strong>
                                                                    </>
                                                                }
                                                            </AlertSuccess>
                                                        </div>
                                                        <div className='col-lg-12'>
                                                            {
                                                                this.state.caixa?.closeBy ?
                                                                    <AlertSuccess tag="Fechado">
                                                                        <strong>Operador: {this.state.caixa.closeBy.nome}</strong><br />
                                                                        <strong>Login: {this.state.caixa.closeBy.email}</strong><br />
                                                                        {
                                                                            this.state.caixa.closeAt &&
                                                                            <strong>
                                                                                Data: {Moment(this.state.caixa.closeAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                                            </strong>
                                                                        }
                                                                        {
                                                                            !isNullOrEmpty(this.state.caixa.closeOrigin) &&
                                                                            <><br /> <strong>Fechado em: {this.state.caixa.closeOrigin}</strong><br /></>
                                                                        }
                                                                        {
                                                                            !isNullOrEmpty(this.state.caixa.closeUserAllowed?.nome) &&
                                                                            <>
                                                                                <br /> <strong className='text-danger'>Autorizado por: {this.state.caixa.closeUserAllowed?.nome}</strong>
                                                                            </>
                                                                        }
                                                                    </AlertSuccess> :
                                                                    <AlertInfo tag="Fechamento">
                                                                        Caso queira, você pode fechar o caixa por aqui.<br />
                                                                        <div className="btn-group mt-1">
                                                                            <button onClick={this.closeNewCashier} className='text-white btn btn-sm btn-info'>Toque para fechar o caixa</button>
                                                                        </div>
                                                                    </AlertInfo>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-5">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        Movimentações
                                    </div>
                                    <div className="card-body">
                                        <div className='col-lg-12'>
                                            <div className='alert alert-success'>
                                                Aproveite e faça uma analise dos dados dos veículos lançados. <p />
                                                <button type="button" onClick={this.showAnalyticsModal} className="btn btn-success">
                                                    <QueryStatsIcon />
                                                    <span className='mx-2'>Analisar dados</span>
                                                </button>
                                            </div>
                                        </div>
                                        <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                                            <Tab eventKey="tickets" title="Tickets">
                                                {
                                                    this.state.tickets.length === 0 ? <EmptyContent text="Não existem lançamentos de veículos para este caixa" /> :
                                                        <div className='col-lg-12'>
                                                            <TicketSimplyList key={this.state.random} tickets={this.state.tickets} caixaId={this.props.match.params.id} />
                                                        </div>
                                                }
                                            </Tab>
                                            <Tab eventKey="mensalistas" title="Mensalistas">
                                                {
                                                    this.state.monthlies.length === 0 ? <EmptyContent text="Não existem pagamentos de mensalistas para este caixa" /> :
                                                        <>
                                                            <MensalistasSimplyList key={this.state.random} monthlies={this.state.monthlies} />
                                                        </>
                                                }
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.isShowingAnalyticsModal && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                            }
                            {
                                this.state.showModalToCloseCashier === true && <CloseCashierModal onClose={e => this.setState({ showModalToCloseCashier: false })} id={this.state.cashierIdToClose} showModal={this.state.showModalToCloseCashier} />
                            }
                        </>
                    }
                </div>
                <Dialog maxWidth="xs" onClose={(e) => this.setState({ showModalToChangeCashier: false })} open={this.state.showModalToChangeCashier}>
                    {
                        this.state.modalToChangeSequenceLoading ? <Loading /> : <>
                            <MuiDialogTitle className='text-center'>
                                Editar caixa
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='col-lg-12'>
                                    <DatePickerButton
                                        text="Data de abertura"
                                        className="btn btn-primary w-100"
                                        date={Moment(this.state.caixa?.openAt?.seconds * 1000).toDate()}
                                        onConfirm={(date) => this.setState({ newOpenAt: date })} />
                                </div>
                                {
                                    this.state.caixa?.status === "fechado" &&
                                    <div className='col-lg-12 mt-2'>
                                        <DatePickerButton
                                            text="Data de fechamento"
                                            className="btn btn-primary w-100"
                                            date={Moment(this.state.caixa?.closeAt?.seconds * 1000).toDate()}
                                            onConfirm={(date) => this.setState({ newCloseAt: date })} />
                                    </div>
                                }
                                <div className='col-lg-12 mt-2'>
                                    <label>Sequência</label>
                                    <input type="text" maxLength={7} placeholder='0000000' onChange={(e) => this.setState({ newSequence: e.target.value })} value={this.state.newSequence} className="form-control" />
                                    <small className='form-text text-muted mt-3'>
                                        A sequência deve ser um número de no máximo 7 dígitos <br />
                                        Exemplo: 1234567
                                    </small>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3'>
                                    <div className='btn-group'>
                                        <button onClick={(e) => this.setState({ showModalToChangeCashier: false })} className='btn btn-secondary'>Cancelar</button>
                                        <button onClick={(e) => this.confirmCashierDetail()} className='btn btn-success'>Confirmar</button>
                                    </div>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
            </NavBar>
        )
    }
}

export { DetailCashier };