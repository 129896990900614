import React from 'react'
import { NavBar } from '../../../components/navbar/index.js'
import { Loading, EmptyContent } from '../../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getEstacionamento, getUsuarioCompact } from '../../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { addEquipment, deleteEquipment, getAllEquipmentsByParkId, updateEquipmentById } from '../../../store/collections/equipmentWorker.js';
import { getRemoteConfigByKey } from '../../../store/collections/remoteConfigWorker.js';
import { TicketListModal } from '../../ticket/index.js';
import { getAllCamerasLPRByParkId } from '../../../store/collections/cameraLPRWorker.js';
import { CancelaCameraLPRPreview } from '../lpr/index.js';
import { AlertDanger, AlertInfo, AlertSecondary, AlertWarning } from '../../../shared/alert-manager.js';
import { updateTicket } from '../../../store/collections/ticketWorker.js';
import { dangerConfirm, generateUUIDV7, isNull, isNullOrEmpty, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning } from '../../../shared/utils.js';
import { sendLogByUser } from '../../../shared/analytics.js';

class CancelaListarEquipamentos extends React.Component {

    render() {
        return (
            <NavBar>
                <CancelaListarEquipamentosBody />
            </NavBar>
        )
    }
}

class CancelaListarEquipamentosBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            allEmergencyModel: false,
            items: [],
            camerasLPR: [],
            isLoading: true,
            lprIdentifier: "",
            lprIdentifierOut: "",
            lprIdentifierType: "IN",
            refresh: "300000",
            delay: "7"
        }
    }

    componentDidMount = async () => {
        await this.load(true)
        await this.loadCommands()
    }

    load = async (isLoading) => {
        this.setState({ isLoading: isLoading })
        var items = await getAllEquipmentsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allEmergencyModel: items.filter(e => e.emergency === true).length === items.length })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    loadCommands = async () => {
        const item = await getRemoteConfigByKey({ key: "GATE_TOTEMS_COMMANDS" })
        if (item) {
            this.setState({ commands: JSON.parse(item.value) })
        }
    }

    showEditEquipmentNameModal = async (item) => {
        this.setState({ equipmentSelected: item })
        this.setState({ ...item })

        this.setState({ printTicketForMonthlyOrAccredited: item.printTicketForMonthlyOrAccredited ?? true })
        this.setState({ printTicketForSeparate: item.printTicketForSeparate ?? true })
        this.setState({ printTicketForSeparateWithTag: item.printTicketForSeparateWithTag ?? true })
        this.setState({ accessSeparateTicket: item.accessSeparateTicket ?? false })
        this.setState({ accessAccreditedTicket: item.accessAccreditedTicket ?? false })
        this.setState({ accessMonthlyTicket: item.accessMonthlyTicket ?? false })
        this.setState({ supportDisableReaders: item.supportDisableReaders ?? false })
        this.setState({ supportAllowOfflineExit: item.supportAllowOfflineExit ?? true })
        this.setState({ refresh: item.refresh ?? "300000" })
        this.setState({ lprIdentifier: item.lprIdentifier })
        this.setState({ lprIdentifierOut: item.lprIdentifierOut })
        this.setState({ isShowingEquipmentSettings: true })
        this.setState({ delay: item.delay ?? "7" })
    }

    saveEquipmentSettings = async () => {
        if (isNullOrEmpty(this.state.equipmentId)) {
            toastWarning("É preciso informar o número do terminal.")
            return
        }
        if (isNullOrEmpty(this.state.type)) {
            toastWarning("Informe o tipo do totem.")
            return
        }
        const totem = this.state.items.find(e => e.equipmentId === this.state.equipmentId)
        if (totem && !this.state.equipmentSelected) {
            toastWarning("Já existe um totem com este número de terminal.")
            return
        }
        toastLoading("Salvando...")
        const data = {
            equipmentId: this.state.equipmentId,
            type: this.state.type,
            name: this.state.name,
            delay: this.state.delay,
            printTicketForMonthlyOrAccredited: this.state.printTicketForMonthlyOrAccredited,
            printTicketForSeparate: this.state.printTicketForSeparate,
            printTicketForSeparateWithTag: this.state.printTicketForSeparateWithTag,
            accessSeparateTicket: this.state.accessSeparateTicket,
            accessAccreditedTicket: this.state.accessAccreditedTicket,
            accessMonthlyTicket: this.state.accessMonthlyTicket,
            refresh: parseInt(this.state.refresh ?? "300000"),
            supportDisableReaders: this.state.supportDisableReaders,
            supportAllowOfflineExit: this.state.supportAllowOfflineExit,
            command: "reload",
            blocked: this.state.equipmentSelected?.blocked ?? false,
            emergency: this.state.equipmentSelected?.emergency ?? false,
        }
        this.setState({ isShowingEquipmentSettings: false })
        var equipment = this.state.equipmentSelected
        if (equipment) {
            await updateEquipmentById({ id: equipment.id, data: data })
            toastDismissLoading()
            toastSuccess("Configurações salvas com sucesso.")
            await this.load()
        } else {
            await addEquipment({ data: data })
            toastDismissLoading()
            toastSuccess("Novo equipamento adicionado com sucesso.")
            await this.load()
        }
    }

    showSettingsLPRModal = async (item, type) => {
        toastLoading("Aguarde...")
        this.setState({ equipmentSelected: item })
        if (type == "IN") {
            this.setState({ lprIdentifier: item.lprIdentifier })
        } else {
            this.setState({ lprIdentifier: item.lprIdentifierOut })
        }
        this.setState({ lprIdentifierType: type })
        const items = await getAllCamerasLPRByParkId({ estacionamentoId: this.state.estacionamentoId, type: type })
        this.setState({ camerasLPR: items })
        this.setState({ isShowingLPRModal: true })
        toastDismissLoading()
    }

    confirmCameraLPR = async () => {
        toastLoading("Salvando...")
        var equipment = this.state.equipmentSelected
        if (this.state.lprIdentifierType == "IN") {
            equipment.lprIdentifier = this.state.lprIdentifier
        } else {
            equipment.lprIdentifierOut = this.state.lprIdentifier
        }
        await updateEquipmentById({ id: equipment.id, data: equipment })
        this.setState({ isShowingLPRModal: false })
        toastDismissLoading()
        toastSuccess("Configurações salvas com sucesso.")
        await this.load()
    }

    showCommandEquipmentModal = (item) => {
        this.setState({ equipmentSelected: item })
        this.setState({ isShowingCommandModal: true })
    }

    cancelCommand = async () => {
        var equipment = this.state.equipmentSelected
        equipment.command = null
        equipment.commandDescription = null
        await updateEquipmentById({ id: equipment.id, data: equipment })
        this.setState({ isShowingCommandModal: false })
        sendLogByUser("Totem", `Comando cancelado manualmente.`, null)
        toastSuccess("Comando cancelado com sucesso.")
        await this.load()
    }

    verifyCommand = async (item) => {
        if (item.commandParams === "ticket") {
            this.setState({ commandSelected: item })
            this.setState({ isShowingCommandModal: false })
            this.setState({ showListTicketsModal: true })
        } else {
            this.confirmCommandEquipment(item)
        }
    }

    confirmCommandEquipment = async (item) => {
        toastLoading("Enviando comando...")
        var equipment = this.state.equipmentSelected
        equipment.command = item.command
        equipment.commandDescription = item.commandDescription
        if (item.commandValue) {
            equipment.commandValue = item.commandValue
        }
        await updateEquipmentById({ id: equipment.id, data: equipment })
        this.setState({ isShowingCommandModal: false })
        toastDismissLoading()
        toastSuccess("Comando enviado com sucesso.")
        await this.load()
    }

    onSelectedTicket = async (ticket) => {
        var command = this.state.commandSelected
        command.commandValue = ticket.ticket
        this.confirmCommandEquipment(command)
        var user = getUsuarioCompact()
        const message = `Abriu manualmente o ticket: ${ticket.ticket} com placa: ${takeIfIsNotNullOrEmpty(ticket.placa) ?? "Não informada"} .`
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: {
                id: user.id,
                nome: user.nome,
                email: user.email
            }
        })
        await updateTicket(ticket.id, ticket)
        sendLogByUser("Totem", message, ticket)
        this.onCloseList()
    }

    onCloseList = () => {
        this.setState({ showListTicketsModal: false })
    }

    removeEquipment = async (item) => {
        var confirm = await dangerConfirm({ message: `Confirma mesmo a remoção deste equipamento?` });
        if (confirm) {
            await deleteEquipment({ id: item.id })
            toastSuccess("Equipamento removido com sucesso.")
            await this.load()
        }
    }

    changeEquipmentEmergencyMode = async (item, emergency) => {
        item.emergency = emergency
        await updateEquipmentById({ id: item.id, data: item })
        await this.load()
    }

    changeEquipmentBlockGate = async (item, blocked) => {
        item.blocked = blocked
        await updateEquipmentById({ id: item.id, data: item })
        toastSuccess("Modo de bloqueio alterado com sucesso.")
        await this.load()
    }

    changeEquipmentsEmergencyMode = async (emergency) => {
        for (const item of this.state.items) {
            item.emergency = emergency
            await updateEquipmentById({ id: item.id, data: item })
            sendLogByUser("Totem", `Modo de emergência ${emergency ? "ativado" : "desativado"} no equipamento: ${item.name} - ${item.equipmentId}.`, null)
            toastSuccess("Modo de emergência alterado com sucesso.")
        }
        await this.load()
    }

    registerNewTotem = () => {
        this.setState({ printTicketForMonthlyOrAccredited: false })
        this.setState({ printTicketForSeparate: false })
        this.setState({ printTicketForSeparateWithTag: false })
        this.setState({ accessSeparateTicket: false })
        this.setState({ accessAccreditedTicket: false })
        this.setState({ accessMonthlyTicket: false })
        this.setState({ supportDisableReaders: false })
        this.setState({ supportAllowOfflineExit: false })
        this.setState({ refresh: "300000" }) // 5 minutos
        this.setState({ delay: "7" })
        this.setState({ isShowingEquipmentSettings: true })
        this.setState({ equipmentSelected: null })
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Totens
                    {
                        !this.state.isLoading &&
                        <div className='btn-group'>
                            <button type="button" onClick={() => { this.registerNewTotem() }} className="btn btn-sm btn-primary">
                                <i className="fas fa-add mx-2" />
                                Cadastrar
                            </button>
                            {
                                this.state.items.length > 0 && <>
                                    {
                                        this.state.allEmergencyModel ?
                                            <Tooltip title="Desativar modo de emergência" placement="top">
                                                <button type="button" onClick={() => { this.changeEquipmentsEmergencyMode(false) }} className="btn btn-sm btn-danger">
                                                    <i className="fas fa-fire-extinguisher mx-2" />
                                                    Emergência
                                                </button>
                                            </Tooltip> :
                                            <Tooltip title="Ativar modo de emergência" placement="top">
                                                <button type="button" onClick={() => { this.changeEquipmentsEmergencyMode(true) }} className="btn btn-sm btn-success">
                                                    <i className="fas fa-fire-extinguisher mx-2" />
                                                    Emergência
                                                </button>
                                            </Tooltip>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
                <div className="mb-4">
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Nenhum totem configurado.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td align='center' width='10' />
                                                        <td align='left'><strong>Nome</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Identificador</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Impressora</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Memória</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Última atualização</strong></td>
                                                        <td />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='center'>
                                                                    <Tooltip title={item.id} placement="top">
                                                                        {
                                                                            item.memory?.status === "on" ?
                                                                                <span className="badge text-bg-success w-100">Ligado - {item.type}</span> :
                                                                                <span className="badge text-bg-danger w-100">Desligado - {item.type}</span>
                                                                        }
                                                                    </Tooltip>
                                                                </td>
                                                                <td align='left'>{item.name ?? "Não informado"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">{item.equipmentId ?? "Não informado"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">{item.printer?.status ?? "Sem status"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">{item.energy?.status ?? "Sem status"}</td>
                                                                <td align='center' className="d-none d-sm-table-cell">
                                                                    {
                                                                        item.updatedAt && <span>
                                                                            {Moment(item.updatedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                                        </span>
                                                                    }
                                                                </td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        <Tooltip title="Configurações" placement="top">
                                                                            <button className='btn btn-secondary' onClick={(e) => this.showEditEquipmentNameModal(item)}>
                                                                                <i className="fas fa-gear" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        {
                                                                            (item.type === "IN" || item.type === "OUT") &&
                                                                            <Tooltip title={item.lprIdentifier ? "Editar câmera LPR" : "Configurar câmera LPR"} placement="top">
                                                                                <button className={`btn ${item.lprIdentifier ? "btn-success" : "btn-secondary"}`} onClick={(e) => this.showSettingsLPRModal(item, "IN")}>
                                                                                    <i className="fas fa-camera" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                        {
                                                                            item.type === "AUTO" &&
                                                                            <>
                                                                                <Tooltip title={item.lprIdentifier ? "Editar entrada LPR" : "Configurar entrada LPR"} placement="top">
                                                                                    <button className={`btn ${item.lprIdentifier ? "btn-success" : "btn-secondary"}`} onClick={(e) => this.showSettingsLPRModal(item, "IN")}>
                                                                                        <i className="fas fa-camera" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip title={item.lprIdentifier ? "Editar saída LPR" : "Configurar saída LPR"} placement="top">
                                                                                    <button className={`btn ${item.lprIdentifierOut ? "btn-success" : "btn-secondary"}`} onClick={(e) => this.showSettingsLPRModal(item, "OUT")}>
                                                                                        <i className="fas fa-camera" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                        }
                                                                        {
                                                                            item.emergency === true ?
                                                                                <Tooltip title="Desativar modo de emergência" placement="top">
                                                                                    <button className='btn btn-danger' onClick={(e) => this.changeEquipmentEmergencyMode(item, false)}>
                                                                                        <i className="fas fa-fire-extinguisher" />
                                                                                    </button>
                                                                                </Tooltip> :
                                                                                <>
                                                                                    <Tooltip title="Ativar modo de emergência" placement="top">
                                                                                        <button className='btn btn-success' onClick={(e) => this.changeEquipmentEmergencyMode(item, true)}>
                                                                                            <i className="fas fa-fire-extinguisher" />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </>
                                                                        }
                                                                        {
                                                                            item.blocked === false ?
                                                                                <Tooltip title="Bloquear entrada" placement="top">
                                                                                    <button className='btn btn-success' onClick={(e) => this.changeEquipmentBlockGate(item, true)}>
                                                                                        <i className="fas fa-door-open" />
                                                                                    </button>
                                                                                </Tooltip> :
                                                                                <Tooltip title="Desbloquear entrada" placement="top">
                                                                                    <button className='btn btn-warning text-white' onClick={(e) => this.changeEquipmentBlockGate(item, false)}>
                                                                                        <i className="fas fa-door-closed" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                        }
                                                                        <Tooltip title="Executar comando" placement="top">
                                                                            <button className='btn btn-dark' onClick={(e) => this.showCommandEquipmentModal(item)}>
                                                                                <i className="fas fa-terminal" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Remover equipamento" placement="top">
                                                                            <button className='btn btn-danger' onClick={(e) => this.removeEquipment(item)}>
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                                :
                                <Loading />
                    }
                    {
                        this.state.showListTicketsModal && <TicketListModal onClose={this.onCloseList} status={["Pago e Estacionado", "Estacionado", "Pago e Aguardando", "Cancelado"]} onSelected={this.onSelectedTicket} />
                    }
                    <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingLPRModal} onClose={(e) => this.setState({ isShowingLPRModal: false })}>
                        {
                            this.state.camerasLPR.length > 0 ? <>
                                <MuiDialogTitle className='text-center'>
                                    {this.state.lprIdentifier ? "Editar câmera LPR" : "Configurar câmera LPR"}
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <select className='form-select' value={this.state.lprIdentifier} onChange={(e) => this.setState({ lprIdentifier: e.target.value })}>
                                        <option value={""}>Selecionar</option>
                                        {
                                            this.state.camerasLPR?.map(item => { return <option value={item.identifier}>{item.name}</option> })
                                        }
                                    </select>
                                    {
                                        this.state.lprIdentifier && this.state.lprIdentifier !== "" &&
                                        <div className='mt-3'>
                                            <CancelaCameraLPRPreview
                                                identifier={this.state.lprIdentifier} />
                                            <div className="col-lg-12 mt-3">
                                                <AlertSecondary tag="LPR">
                                                    <div className='my-2'>
                                                        <select className='form-select' value={this.state.delay} onChange={(e) => this.setState({ delay: e.target.value })}>
                                                            <option value={"0"}>1 Checagem</option>
                                                            <option value={"3"}>3 Checagens</option>
                                                            <option value={"5"}>5 Checagens</option>
                                                            <option value={"7"}>7 Checagens</option>
                                                            <option value={"10"}>10 Checagens</option>
                                                            <option value={"15"}>15 Checagens</option>
                                                        </select>
                                                    </div>
                                                    <span>A cada 3 segundos é feito uma checagem para buscar a foto do veículo na camera LPR.</span>
                                                </AlertSecondary>
                                            </div>
                                        </div>
                                    }
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={() => this.confirmCameraLPR()} className='btn btn-success'>Salvar</button>
                                    </div>
                                </MuiDialogActions>
                            </> :
                                <MuiDialogContent>
                                    <div className='alert alert-warning'>
                                        <span className="badge text-bg-warning text-white">Atenção</span><br />
                                        <span>Este estabelecimento não tem nenhuma câmera LPR cadastrada.</span><br />
                                        <a href='/equipamentos/cameras-lpr' className='btn btn-warning text-white mt-2'>Cadastrar câmera</a>
                                    </div>
                                </MuiDialogContent>
                        }
                    </Dialog>
                    <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingEquipmentSettings} onClose={(e) => this.setState({ isShowingEquipmentSettings: false })}>
                        <MuiDialogTitle className='text-center'>
                            Configurar totem
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className="col-lg-12">
                                <label>Nome</label>
                                <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                            </div>
                            <div className='row'>
                                <div className="col-lg-6">
                                    <label>Identificador</label>
                                    <input type="text" onChange={(e) => this.setState({ equipmentId: e.target.value })} value={this.state.equipmentId} className="form-control" required regex="[0-9]{1,}" />
                                </div>
                                <div className="col-lg-6">
                                    <label>Tipo</label>
                                    <select className='form-select' value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                                        <option value="">Selecionar</option>
                                        <option value="IN">Entrada</option>
                                        <option value="OUT">Saída</option>
                                        <option value="AUTO">Entrada e saída</option>
                                    </select>
                                </div>
                            </div>
                            {
                                this.state.type === 'AUTO' &&
                                <div className="col-lg-12 mt-3">
                                    <AlertWarning message="Usado para totens que permitem entrada e saída de veículos na mesma via." />
                                </div>
                            }
                            <div className="col-lg-12 mt-3">
                                <AlertSecondary tag="Regras de impressão">
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="printTicketForMonthly" checked={this.state.printTicketForMonthlyOrAccredited} onChange={(e) => this.setState({ printTicketForMonthlyOrAccredited: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="printTicketForMonthly">
                                            Imprimir automaticamente ticket para mensalistas ou credenciados.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="printTicketForSeparate" checked={this.state.printTicketForSeparate} onChange={(e) => this.setState({ printTicketForSeparate: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="printTicketForSeparate">
                                            Imprimir automaticamente ticket para veículos avulsos.
                                        </label>
                                    </div>
                                    {/* <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="printTicketForSeparateWithTag" checked={this.state.printTicketForSeparateWithTag} onChange={(e) => this.setState({ printTicketForSeparateWithTag: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="printTicketForSeparateWithTag">
                                                            Imprimir ticket para veículos com tag de pagamento
                                                        </label>
                                                    </div> */}
                                </AlertSecondary>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <AlertSecondary tag="Regras de acesso">
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="accessSeparateTicket" checked={this.state.accessSeparateTicket} onChange={(e) => this.setState({ accessSeparateTicket: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="accessSeparateTicket">
                                            Não permitir entrar veículo avulso.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="accessAccreditedTicket" checked={this.state.accessAccreditedTicket} onChange={(e) => this.setState({ accessAccreditedTicket: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="accessAccreditedTicket">
                                            Não permitir entrar credênciados.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="accessMonthlyTicket" checked={this.state.accessMonthlyTicket} onChange={(e) => this.setState({ accessMonthlyTicket: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="accessMonthlyTicket">
                                            Não permitir entrar mensalistas.
                                        </label>
                                    </div>
                                    {
                                        this.state.accessSeparateTicket == true && this.state.accessAccreditedTicket == true && this.state.accessMonthlyTicket == true &&
                                        <AlertDanger>
                                            Este totem não irá permitir acesso de NENHUM veículo.
                                        </AlertDanger>
                                    }
                                </AlertSecondary>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <AlertDanger tag="Configurações administrativas">
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="supportDisableReaders" checked={this.state.supportDisableReaders} onChange={(e) => this.setState({ supportDisableReaders: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="supportDisableReaders">
                                            Desabilitar acesso via botão ou cartão de acesso.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="supportAllowOfflineExit" checked={this.state.supportAllowOfflineExit} onChange={(e) => this.setState({ supportAllowOfflineExit: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="supportAllowOfflineExit">
                                            Bloquear saída se estiver offline.
                                        </label>
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Tempo de atualização</label>
                                        <select className='form-select' value={this.state.refresh} onChange={(e) => this.setState({ refresh: e.target.value })}>
                                            {
                                                (!isNullOrEmpty(this.state.lprIdentifier) || !isNullOrEmpty(this.state.lprIdentifierOut)) && <>
                                                    <option value="5000">5 segundos</option>
                                                    <option value="10000">10 segundos</option>
                                                    <option value="20000">20 segundos</option>
                                                    <option value="30000">30 segundos</option>
                                                    <option value="40000">40 segundos</option>
                                                    <option value="50000">50 segundos</option>
                                                    <option value="60000">1 minuto</option>
                                                </>
                                            }
                                            <option value="180000">3 minutos</option>
                                            <option value="300000">5 minutos</option>
                                            <option value="600000">10 minutos</option>
                                        </select>
                                    </div>
                                </AlertDanger>
                            </div>
                            {
                                !isNullOrEmpty(this.state.equipmentSelected?.version) &&
                                <div className="col-lg-12 text-center">
                                    <small>Versão: {this.state.equipmentSelected?.version}</small>
                                </div>
                            }
                            {
                                !isNullOrEmpty(this.state.equipmentSelected?.rfidType) ?
                                    <div className="col-lg-12 text-center">
                                        {
                                            this.state.equipmentSelected?.rfidType === 1 &&
                                            <small>aba</small>
                                        }
                                        {
                                            this.state.equipmentSelected?.rfidType === 2 &&
                                            <small>Wiegand Facility Code Desabilitado</small>
                                        }
                                        {
                                            this.state.equipmentSelected?.rfidType === 3 &&
                                            <small>Wiegand Facility Code Habilitado</small>
                                        }
                                    </div> :
                                    <small className='text-center'>Indefinido - ( Necessário efetuar pelo menos uma leitura no equipamento )</small>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={(e) => this.setState({ isShowingEquipmentSettings: false })} className='btn btn-secondary'>Fechar</button>
                                <button onClick={() => this.saveEquipmentSettings()} className='btn btn-success'>Salvar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog maxWidth={"xs"} open={this.state.isShowingCommandModal} onClose={(e) => this.setState({ isShowingCommandModal: false })}>
                        <MuiDialogTitle className='text-center'>
                            Executar comando no terminal
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div>
                                {
                                    this.state.equipmentSelected?.command &&
                                    <AlertWarning message={`Existe um comando pendente para este equipamento você pode aguardar que seja executado ou executar outro comando.`}>
                                        <button onClick={() => this.cancelCommand()} className='btn btn-warning text-white'>Cancelar comando</button>
                                    </AlertWarning>
                                }
                            </div>
                            <div className='btn-group-vertical col-lg-12'>
                                {
                                    this.state.commands?.map(item => (
                                        <button onClick={() => this.verifyCommand(item)} className='btn btn-secondary w-100'>{item.commandDescription}</button>
                                    ))
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={(e) => this.setState({ isShowingCommandModal: false })} className='btn btn-secondary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </div>
            </div >
        )
    }
}
export { CancelaListarEquipamentos }