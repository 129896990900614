import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento } from '../../config/auth'
import { getAllSelles, getAllSellesByCashierByIds } from '../../store/collections/report-salles'
import { queryString, setPathWindow } from '../../shared/utils'
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import './produtos.css'
import Chart from "react-apexcharts";
import { EmptyContent, FilterComponent } from '../../components/common/commons'
import { sendScreenView } from '../../shared/analytics';
import Tooltip from '@mui/material/Tooltip';
import { AlertInfo } from '../../shared/alert-manager';

class ReportProdutos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: queryString("e") ?? getEstacionamento()?.id,
            estacionamentosIds: [],
            days: 1,
            valor: 0.0,
            state: 'empty',
            valorCusto: 0.0,
            lucroTotal: 0.0,
            ticketMedio: 0,
            vendas: 0,
            items: 0,
            produtosSemValorDeCusto: false,
            produtosVendidos: [],
            formasPagamento: [],
            vendasPorUsuarios: [],
            vendasDoPeriodo: [],
            caixas: [],
            mostrarGraficosVendasPorPagamento: true,
            mostrarGraficosProdutosMaisVendidos: true,
            mostrarGraficosVendasPorUsuario: true,
            allTicketsWithSpun: []
        }
    }

    componentDidMount = () => {
        const startDate = new Moment(queryString("startDate"))
        const endDate = new Moment(queryString("endDate"))
        const estacionamentosIds = queryString("estacionamentosIds")
        if (estacionamentosIds) {
            this.setState({ estacionamentosIds: estacionamentosIds.split(",") })
        }
        const ids = queryString("ids")
        if (ids) {
            this.setState({ ids: ids.split(",") }, () => {
                this.find()
            })
        } else if (startDate.isValid() && endDate.isValid()) {
            this.setState({ startDate: startDate.toDate() })
            this.setState({ endDate: endDate.toDate() }, () => {
                this.find()
            })
        } else {
            sendScreenView("Relatório", "Vendas")
        }
    }

    find = async () => {
        const startDateURL = Moment(this.state.startDate).format("MM/DD/YYYY")
        const endDateURL = Moment(this.state.endDateURL).format("MM/DD/YYYY")
        this.setState({ state: 'loading' });
        var resultado = null
        if (this.state.ids) {
            resultado = await getAllSellesByCashierByIds({
                estacionamentosIds: this.state.estacionamentosIds,
                ids: this.state.ids
            })
        } else if (startDateURL && endDateURL) {
            setPathWindow('/relatorios/vendas?es=' + this.state.estacionamentosIds.join(",") + "&startDate=" + startDateURL + "&endDate=" + endDateURL);
            resultado = await getAllSelles({
                estacionamentosIds: this.state.estacionamentosIds,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            })
        }
        this.setState({ state: resultado.state });
        if (resultado.state === 'found') {
            this.setState({ valor: resultado.valor })
            this.setState({ valorCusto: resultado.valorCusto })
            this.setState({ lucroTotal: resultado.lucroTotal })
            this.setState({ ticketMedio: resultado.ticketMedio })
            this.setState({ porcentagem: resultado.porcentagem })
            this.setState({ vendas: resultado.vendas })
            this.setState({ items: resultado.items })
            this.setState({ caixas: resultado.caixas })
            this.setState({ formasPagamento: resultado.formasPagamentos })
            this.setState({ vendasPorUsuarios: resultado.vendasPorUsuarios })

            this.setState({ allTicketsWithSpun: resultado.allTicketsWithSpun })

            this.setState({ seriesVendasDoPeriodo: resultado.seriesVendasDoPeriodo })
            this.setState({ optionsVendasDoPeriodo: resultado.optionsVendasDoPeriodo })

            this.setState({ produtosVendidos: resultado.produtosMaisVendidos.produtosVendidos.sort((a, b) => b.quantidade - a.quantidade) })
            this.setState({ seriesProdutosMaisVendidos: resultado.seriesProdutosMaisVendidos })
            this.setState({ optionsProdutosMaisVendidos: resultado.optionsProdutosMaisVendidos })

            this.setState({ seriesFormasPagamento: resultado.seriesFormasPagamento })
            this.setState({ optionsFormasPagamento: resultado.optionsFormasPagamento })

            this.setState({ seriesVendasPorUsuarios: resultado.seriesVendasPorUsuarios })
            this.setState({ optionsVendasPorUsuarios: resultado.optionsVendasPorUsuarios })

            this.setState({ random: Math.random() })
        }
    }

    onApplyFilter = (item) => {
        console.log(item);
        this.setState({ startDate: item.startDate })
        this.setState({ endDate: item.endDate })
        this.setState({ estacionamentosIds: item.estacionamentosIds }, () => {
            this.find()
        })
    }

    render() {
        return (
            <>
                <NavBar>
                    <FilterComponent onApplyFilter={this.onApplyFilter} />
                    {
                        this.state.state === "loading" &&
                        <div className="card mb-4">
                            <div className="card-body py-3">
                                <EmptyContent text="Carregando informações..." />
                            </div>
                        </div>
                    }
                    {
                        this.state.state === "not-found" &&
                        <div className="card mb-4">
                            <div className="card-body py-3">
                                <EmptyContent text="Nenhuma venda de produto ou serviço foi localizada com este filtro." />
                            </div>
                        </div>
                    }
                    {this.state.state === "found" &&
                        <div className="row">
                            {
                                this.state.produtosSemValorDeCusto &&
                                <div className="col-12 col-sm-6 col-lg-12">
                                    <div className='alert alert-danger'>
                                        <strong>Atenção: </strong>Existem produtos sem custo nos dados analisados. <p />É necessário cadastrar o valor de custo em todos os produtos. <br />Produtos sem valor de custo tem impacto direto na precisão do <strong>Lucro total</strong> e <strong>Porcentagem de lucro</strong> no relatório.
                                    </div>
                                </div>
                            }
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="card text-white bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Total de vendas</h5>
                                        <h4 className="card-text text-center text-white">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        </h4>
                                        <div className='text-center'>
                                            <small className="card-text text-white">Ticket médio:
                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.ticketMedio} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="card text-white bg-success mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Faturamento total</h5>
                                        <h4 className="card-text text-center text-white">
                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.lucroTotal} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                        </h4>
                                        <div className='text-center'>
                                            <small className="card-text text-white">Porcentagem de lucro: {this.state.porcentagem?.toFixed(2)}%</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="card text-white bg-info mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Vendas</h5>
                                        <h4 className="card-text text-center text-white">{this.state.vendas}</h4>
                                        <div className='text-center'>
                                            <small className="card-text text-white">Quantidade de vendas</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3">
                                <div className="card text-white bg-primary mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Itens</h5>
                                        <h4 className="card-text text-center text-white">{this.state.items}</h4>
                                        <div className='text-center'>
                                            <small className="card-text text-white">Quantidade de itens vendidos</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-12">
                                <div className="card mb-3">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        Vendas no período
                                    </div>
                                    <div className="card-body">
                                        {
                                            this.state.optionsVendasDoPeriodo !== undefined && <>
                                                <Chart options={this.state.optionsVendasDoPeriodo}
                                                    series={this.state.seriesVendasDoPeriodo}
                                                    type="bar"
                                                    height={250} />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-sm-12 col-lg-6">
                                <div className="card mb-3">
                                    <div className="card-header">
                                        {
                                            this.state.mostrarGraficosVendasPorPagamento === false ?
                                                <button type="button" onClick={(e) => this.setState({ mostrarGraficosVendasPorPagamento: true })} className="btn btn-success">
                                                    <i className="fas fa-chart-pie" />
                                                </button>
                                                :
                                                <button type="button" onClick={(e) => this.setState({ mostrarGraficosVendasPorPagamento: false })} className="btn btn-success">
                                                    <i className="fas fa-table" />
                                                </button>
                                        }
                                        <span className="m-2 font-weight-bold"><strong>Vendas por forma de pagamento</strong></span>
                                    </div>
                                    {
                                        this.state.mostrarGraficosVendasPorPagamento === true && this.state?.optionsFormasPagamento !== undefined ?
                                            <Chart
                                                type="pie"
                                                options={this.state.optionsFormasPagamento}
                                                height={250}
                                                series={this.state.seriesFormasPagamento} /> :
                                            <div className='row'>
                                                <div className='table-responsive'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <td width="10" align="center"><strong>Quantidade</strong></td>
                                                                <td><strong>Forma de pagamento</strong></td>
                                                                <td align="right"><strong>Total</strong></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.formasPagamento.map((formas, index) =>
                                                                    <tr key={index}>
                                                                        <td align="center">{formas.quantidade}</td>
                                                                        <td>{formas.nome}</td>
                                                                        <td align="right" className='text-success'>
                                                                            <strong><NumberFormat value={formas.total} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></strong>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            {
                                this.state.vendasPorUsuarios.length > 0 &&
                                <div className="col-12 col-md-5 col-sm-12 col-lg-6">
                                    <div className="card mb-3">
                                        <div className="card-header">
                                            {
                                                this.state.mostrarGraficosVendasPorUsuario === false ?
                                                    <button type="button" onClick={(e) => this.setState({ mostrarGraficosVendasPorUsuario: true })} className="btn btn-success">
                                                        <i className="fas fa-chart-pie" />
                                                    </button>
                                                    :
                                                    <button type="button" onClick={(e) => this.setState({ mostrarGraficosVendasPorUsuario: false })} className="btn btn-success">
                                                        <i className="fas fa-table" />
                                                    </button>
                                            }
                                            <span className="m-2 font-weight-bold"><strong>Vendas por operador</strong></span>
                                        </div>
                                        {
                                            this.state.mostrarGraficosVendasPorUsuario === true && this.state?.optionsVendasPorUsuarios ?
                                                <Chart
                                                    type="pie"
                                                    options={this.state.optionsVendasPorUsuarios}
                                                    height={250}
                                                    series={this.state.seriesVendasPorUsuarios} /> :
                                                <div className='row'>
                                                    <div className='table-responsive'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <td><strong>Operador</strong></td>
                                                                    <td align="right"><strong>Total</strong></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.vendasPorUsuarios.map((item, index) =>
                                                                        <tr key={index}>
                                                                            <td>{item.user.nome}</td>
                                                                            <td align="right" className='text-success'>
                                                                                <strong><NumberFormat value={item.valor} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></strong>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                </div>
                            }
                            <div className="col-12 col-md-5 col-sm-12 col-lg-6">
                                <div className="row">
                                    <div className='col-lg-12'>
                                        <div className="card mb-3">
                                            <div className="card-header">
                                                <span className="m-2 font-weight-bold"><strong>Caixas analisados</strong></span>
                                            </div>
                                            <div className='row'>
                                                <div className='table-responsive'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <td></td>
                                                                <td width="70" align="center"><strong>Caixa</strong></td>
                                                                <td><strong>Aberto</strong></td>
                                                                <td><strong>Fechado</strong></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.caixas.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <td width='10'>
                                                                            <a href={'/caixas/' + item.id} target="_blank">
                                                                                <button type="button" className="btn btn-secondary">
                                                                                    <i className="fas fa-eye" />
                                                                                </button>
                                                                            </a>
                                                                        </td>
                                                                        {
                                                                            (item.sequence !== undefined && item.sequence !== null) ? <td align="center">{item.sequence}</td> : <td align="center"> - </td>
                                                                        }
                                                                        {
                                                                            <td>{Moment(item.openAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                        }
                                                                        {
                                                                            item.closeAt ?
                                                                                <td>{Moment(item.closeAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</td> :
                                                                                <td>Aberto</td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className='col-lg-12'>
                                                <div className='mx-3'>
                                                    <AlertInfo message="O Faturamento total do caixa é a soma dos veículos estacionados + pagamento de mensalistas + os produtos vendidos." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.allTicketsWithSpun.length > 0 &&
                                        <div className='col-lg-12'>
                                            <div className="card mb-3">
                                                <div className="card-header">
                                                    <span className="m-2 font-weight-bold"><strong>Pagamentos pendentes</strong></span>
                                                </div>
                                                <div className='row'>
                                                    <div className='table-responsive'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <td width={10}></td>
                                                                    <td><strong>Ticket</strong></td>
                                                                    <td><strong>Data</strong></td>
                                                                    <td align="right">
                                                                        <strong>Total</strong>
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.allTicketsWithSpun?.map((item, index) =>
                                                                        <tr key={index}>
                                                                            <td width='10'>
                                                                                <a href={'/buscar/ticket?id=' + item.id} target="_blank">
                                                                                    <button type="button" className="btn btn-secondary">
                                                                                        <i className="fas fa-eye" />
                                                                                    </button>
                                                                                </a>
                                                                            </td>
                                                                            <td>{item.ticket}</td>
                                                                            <td>{Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                            <td className='text-warning' align="right">
                                                                                <Tooltip title="Total produtos e serviços" placement="top">
                                                                                    <strong><NumberFormat value={item.paymentsLogged.map(e => (e.resumo?.valorProdutos ?? 0) + (e.resumo?.valorServicos ?? 0)).reduce((a, b) => a + b, 0)} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></strong>
                                                                                </Tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <div className='alert alert-warning mx-3'>
                                                        <strong>Atenção: </strong>Os tickets acima não entram no calculo de faturamento até que realmente sejam pagos na mensalidade do cliente.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-7 col-sm-12 col-lg-6">
                                <div className="card mb-3">
                                    <div className="card-header">
                                        {
                                            this.state.mostrarGraficosProdutosMaisVendidos === false ?
                                                <button type="button" onClick={(e) => this.setState({ mostrarGraficosProdutosMaisVendidos: true })} className="btn btn-success">
                                                    <i className="fas fa-chart-pie" />
                                                </button>
                                                :
                                                <button type="button" onClick={(e) => this.setState({ mostrarGraficosProdutosMaisVendidos: false })} className="btn btn-success">
                                                    <i className="fas fa-table" />
                                                </button>
                                        }
                                        <span className="m-2 font-weight-bold"><strong>Produtos mais vendidos</strong></span>
                                    </div>
                                    {
                                        this.state.mostrarGraficosProdutosMaisVendidos === true && this.state.optionsProdutosMaisVendidos !== undefined ?
                                            <Chart
                                                type="bar"
                                                options={this.state.optionsProdutosMaisVendidos}
                                                series={this.state.seriesProdutosMaisVendidos} /> :
                                            <div className='row'>
                                                <div className='table-responsive'>
                                                    <table className="table table-striped ">
                                                        <thead>
                                                            <tr>
                                                                <td align='center'><strong>Quantidade</strong></td>
                                                                <td><strong>Nome</strong></td>
                                                                <td></td>
                                                                <td align='right' width={100}><strong>Valor</strong></td>
                                                                <td align='right' width={100}><strong>Custo</strong></td>
                                                                <td align='right' width={100}><strong>Lucro</strong></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.produtosVendidos.map((produto, index) =>
                                                                    <tr key={index}>
                                                                        <td align='center'>{produto.quantidade}</td>
                                                                        <td>{produto.nome}</td>
                                                                        <td>
                                                                            {
                                                                                produto.category?.name !== undefined &&
                                                                                <strong style={{ padding: 3, backgroundColor: produto.category?.color, color: produto.category?.color === "#fff" ? "#000" : "#ffffff", borderRadius: 5 }}>
                                                                                    {produto.category?.name}
                                                                                </strong>
                                                                            }
                                                                            {
                                                                                produto.subCategory?.name !== undefined &&
                                                                                <strong className='mx-2' style={{ padding: 3, backgroundColor: produto.subCategory?.color, color: "#ffffff", color: produto.subCategory?.color === "#fff" ? "#000" : "#ffffff", borderRadius: 5 }}>
                                                                                    {produto.subCategory?.name}
                                                                                </strong>
                                                                            }
                                                                        </td>
                                                                        <td align='right'>
                                                                            <NumberFormat value={produto.valor} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                        </td>
                                                                        {
                                                                            produto.valorCusto === null ?
                                                                                <td align='right'><span className="badge text-bg-secondary">Não disponível</span></td> :
                                                                                <td align='right'>
                                                                                    <NumberFormat value={produto.valorCusto} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                </td>
                                                                        }
                                                                        {
                                                                            produto.margemLucro === null ?
                                                                                <td align='right'><span className="badge text-bg-secondary">Não disponível</span></td> :
                                                                                <td align='right' className='text-success'>
                                                                                    <strong><NumberFormat value={produto.margemLucro} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></strong>
                                                                                </td>
                                                                        }
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </NavBar>
            </>)
    }
}

export default ReportProdutos