import React from 'react'
import { NavBar } from '../../../components/navbar'
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { Loading, EmptyContent } from '../../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { isNullOrEmpty, successConfirm, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning, valueByMaximunChars } from '../../../shared/utils';
import { getRevenda, isAdministrador } from '../../../config/auth';
import { docsToItems } from '../../../store/transform.docs';
import { getAllAnyParameterByClienteId, getParameterByParkId } from '../../../store/collections/parameterWorker';
import { getParkById } from '../../../store/collections/parkWorker';
import { addQueue } from '../../../store/collections/queueWorker';
import { AlertSuccess } from '../../../shared/alert-manager';
import { fetchFunction } from '../../../shared/function-request';
import { getClientById } from '../../../store/collections/clienteWorker';

class WhatsAppMessageList extends React.Component {

    render() {
        return (
            <NavBar>
                <WhatsAppMessageListBody />
            </NavBar>
        )
    }
}

class WhatsAppMessageListBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clienteId: props.clienteId,
            revendaId: getRevenda()?.id,
            referenceId: props.referenceId,
            currentStatus: "",
            isLoading: true,
            items: [],
            isDetailModalShowing: false,
            limit: 30
        }
    }

    componentDidMount = async () => {
        await this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        var query = firebase.firestore().collection('messages')
        if (!isNullOrEmpty(this.state.clienteId)) {
            query = query.where("clienteId", "==", this.state.clienteId)
        }
        if (!isNullOrEmpty(this.state.currentStatus)) {
            query = query.where("currentStatus", "==", this.state.currentStatus)
        }
        if (!isNullOrEmpty(this.state.referenceId)) {
            query = query.where("referenceId", "==", this.state.referenceId)
        }
        try {
            const result = await query.orderBy("createdAt", "desc").limit(parseInt(this.state.limit)).get()
            const items = docsToItems(result)
            this.setState({ items: items.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()) })
            this.setState({ isLoading: false })
        } catch (e) {
            console.log(e)
        }
    }

    renderStatus(status) {
        return (
            <>
                {status === 'AWAIT' && <h5><span className="badge text-bg-info btn-block text-white w-100">Na fila</span></h5>}
                {status === 'SENT' && <h5><span className="badge text-bg-warning btn-block text-white w-100">Enviada</span></h5>}
                {status === 'RECEIVED' && <h5><span className="badge text-bg-primary btn-block text-white w-100">Recebida</span></h5>}
                {status === 'READ' && <h5><span className="badge text-bg-success btn-block text-white w-100">Lida</span></h5>}
            </>
        )
    }

    changeCurrentStatus = (e) => {
        this.setState({ currentStatus: e.target.value }, async () => {
            await this.load()
        })
    }

    changeLimit = (e) => {
        this.setState({ limit: e.target.value }, async () => {
            await this.load()
        })
    }

    showDetail = (item) => {
        this.setState({ currentItem: item })
        this.setState({ isDetailModalShowing: true })
    }

    showMessageModal = async (e) => {
        this.setState({ isMessageModalShowing: true })
    }

    hideWhatsAppModal = () => {
        this.setState({ isMessageModalShowing: false })
    }

    confirmWhatsAppLink = async () => {
        if (isNullOrEmpty(this.state.message)) {
            toastWarning("Preencha o campo mensagem")
            return
        }
        var confirm = await successConfirm({ message: `Deseja mesmo enviar esta mensagem para todos os clientes pagantes?` })
        if (!confirm) {
            return
        }
        this.hideWhatsAppModal()
        toastLoading("Buscando assinantes...")
        const option = {
            method: "POST",
            body: {
                date: Moment().format("MM/yyyy")
            }
        }
        const result = await fetchFunction("/newSubscription/revenda/exist-by-date", option)
        if (result.success) {
            const cobrancas = result.data.sort((a, b) => a.changeDate - b.changeDate)
            for (const cobranca of cobrancas) {
                const cliente = await getClientById({ id: cobranca.clienteId })
                toastLoading(`Registrando mensagem ${cliente.nome}...`)
                const parameter = await getAllAnyParameterByClienteId({ clienteId: cliente.id })
                const body = {
                    estacionamentoId: parameter.estacionamentoId,
                    clienteId: cliente.id,
                    revendaId: cliente.revendaId,
                    phone: cliente.celular,
                    message: this.state.message,
                    template: "NO_TEMPLATE",
                    X_API_KEY: parameter.X_API_KEY
                }
                const data = {
                    revendaId: cliente.revendaId,
                    clienteId: cliente.id,
                    estacionamentoId: parameter.id,
                    origin: "WhatsApp",
                    body: body,
                    type: "WHATSAPP_MESSAGE_WITH_TEMPLATE",
                }
                await addQueue({ data: data })
            }
            toastDismissLoading()
            toastSuccess("Mensagem registrada na fila de envio via WhatsApp.")
        } else {
            toastWarning(result.message)
        }
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Mensagens enviadas
                        <div className='btn-group'>
                            {/* <Tooltip title="Enviar mensagem" placement="top">
                                <button className='btn btn-success btn-sm' onClick={this.showMessageModal}>
                                    <i className="fa-brands fa-whatsapp mx-2" />
                                </button>
                            </Tooltip> */}
                            <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                <option value={30}>30 registros</option>
                                <option value={50}>50 registros</option>
                                <option value={100}>100 registros</option>
                                <option value={200}>200 registros</option>
                                <option value={1000}>1000 registros</option>
                            </select>
                            <select className='form-select form-select-sm' value={this.state.currentStatus} onChange={this.changeCurrentStatus}>
                                <option value=''>Todas</option>
                                <option value='AWAIT'>Na fila</option>
                                <option value='SENT'>Enviada</option>
                                <option value='RECEIVED'>Recebida</option>
                                <option value='READ'>Lida</option>
                            </select>
                        </div>
                    </div>
                    {
                        this.state.items.length === 0 && this.state.isLoading === false ?
                            <EmptyContent text='Nenhuma mensagem com este filtro' />
                            :
                            this.state.isLoading === false ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td className="d-none d-sm-table-cell" width={10}></td>
                                                {
                                                    this.state.clienteId && <td><strong>Estabelecimento</strong></td>
                                                }
                                                <td className="d-none d-sm-table-cell"><strong>Celular</strong></td>
                                                <td className="d-none d-sm-table-cell"><strong>Mensagem</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Data</strong></td>
                                                <td width="10" align='center'><strong>Status</strong></td>
                                                <td width="10"></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            {
                                                                item.isManual === true ?
                                                                    <Tooltip title={`Mensagem enviada manualmente - ${item.id}`} placement='top'>
                                                                        <span className="badge text-bg-warning text-white w-100">Manual</span>
                                                                    </Tooltip> :
                                                                    <Tooltip title={`Mensagem enviada de forma automática - ${item.id}`} placement='top'>
                                                                        <span className="badge text-bg-success w-100">Automática</span>
                                                                    </Tooltip>
                                                            }
                                                        </td>
                                                        {
                                                            this.state.clienteId && <td>
                                                                <Tooltip title={item.id} placement='top'>
                                                                    <span>{item.estacionamentoName ?? "-"}</span>
                                                                </Tooltip>
                                                            </td>
                                                        }
                                                        {
                                                            isAdministrador() ?
                                                                <td className="d-none d-sm-table-cell">
                                                                    {item.phone}
                                                                </td> :
                                                                <td className="d-none d-sm-table-cell">
                                                                    <Tooltip title="Por motivos de segurança o número é ofuscado." placement='top'>
                                                                        <span>{valueByMaximunChars(item.phone, 9) ?? "-"}</span>
                                                                    </Tooltip>
                                                                </td>
                                                        }
                                                        <td className="d-none d-sm-table-cell">{valueByMaximunChars(item.message, 70) ?? "-"}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                        <td align='center'>
                                                            {this.renderStatus(item.currentStatus)}
                                                        </td>
                                                        <td align='right'>
                                                            <div className="btn-group">
                                                                <Tooltip title="Visualizar histórico" placement='top'>
                                                                    <button onClick={(e) => this.showDetail(item)} className='btn btn-secondary'>
                                                                        <i className="fas fa-bars" />
                                                                    </button>
                                                                </Tooltip>
                                                                {
                                                                    isAdministrador() &&
                                                                    <Tooltip title="Visualizar" placement='top'>
                                                                        <a href={`/revenda/cliente/${item.clienteId}?e=${item.estacionamentoId}`} className='btn btn-primary' target='_blank'>
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <Loading />
                    }
                </div>
                <Dialog fullWidth maxWidth="sm" onClose={this.hideWhatsAppModal} open={this.state.isMessageModalShowing}>
                    <MuiDialogContent>
                        <div className='mt-2'>
                            <AlertSuccess tag="Mensagem via WhatsApp">
                                <div className='mt-'>
                                    Iremos enviar uma mensagem para todos os clientes pagantes da revenda selecionada.
                                </div>
                            </AlertSuccess>
                        </div>
                        <label>Mensagem</label>
                        <textarea className='form-control' onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} rows={8} />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={this.hideWhatsAppModal} className='btn btn-secondary'>Fechar</button>
                            <button onClick={this.confirmWhatsAppLink} className='btn btn-success'>
                                <i className="fa-solid fa-paper-plane mx-2" />
                                Enviar
                            </button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog maxWidth="sm" fullWidth onClose={(e) => this.setState({ isDetailModalShowing: false })} open={this.state.isDetailModalShowing}>
                    <MuiDialogTitle className='text-center'>
                        Detalhes da mensagem
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <strong>Celular</strong>
                        {
                            isAdministrador() ?
                                <h6>{this.state.currentItem?.phone}</h6> :
                                <h6>
                                    <Tooltip title="Por motivos de segurança o número é ofuscado." placement='top'>
                                        <span>{valueByMaximunChars(this.state.currentItem?.phone, 9) ?? "-"}</span>
                                    </Tooltip>
                                </h6>
                        }
                        <strong>Mensagem</strong>
                        <textarea disabled className='form-control' rows={8}>{this.state.currentItem?.message}</textarea>
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td><strong>Data</strong></td>
                                        <td width={10} align='center'><strong>Status</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.currentItem?.status.map((item, index) =>
                                            <tr key={index}>
                                                <td>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                <td align='center'>
                                                    {this.renderStatus(item.status)}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='m-3'>
                            <button onClick={(e) => this.setState({ isDetailModalShowing: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

export { WhatsAppMessageList, WhatsAppMessageListBody }